import { ITrend } from '@sdk/contracts';
import styled from 'styled-components';

export const TextWrapper = styled.span`
  color: ${({ theme }) => theme.colors.black9};
  font-family: ${({ theme }) => theme.fontFamily.openSans};
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
`;

export const RatingWrapper = styled.span<{ trend?: ITrend }>`
  color: ${({ theme, trend }) =>
    trend === 'up'
      ? theme.colors.green6
      : trend === 'down'
      ? theme.colors.red6
      : theme.colors.black9};
  text-align: right;
  font-family: ${({ theme }) => theme.fontFamily.openSans};
  font-size: 14px;
  font-weight: 700;
  line-height: 22px;
`;

export const DotWrapper = styled.div<{ color?: string }>`
  width: 10px;
  height: 10px;
  background: ${({ color }) => color};
  border-radius: 100px;
`;

import { EFeatures } from '@constants';
import { groupBy, map, uniq } from 'lodash';
import { useMemo } from 'react';

import { useMySubscriptions, useProfile } from '@/store';

export const useTagFeatures = () => {
  const { selectedSubscription, isLoading: isLoadingSubscriptions } = useMySubscriptions();
  const { profile: { tags = [] } = {}, isLoading: isLoadingProfile } = useProfile();

  const availableFeatures = useMemo(() => {
    const groupedTags = [...tags, ...(selectedSubscription?.course.tags || [])];
    const groupedData = groupBy(groupedTags, '_id');
    const allFeatures = map(groupedData, group => group[0]);
    const features = allFeatures.map(feature => feature.features).flat();
    return uniq(features);
  }, [tags, selectedSubscription?.course.tags]);

  const canRender = (feature?: keyof typeof EFeatures) => {
    if (!feature) return true;
    return availableFeatures.includes(feature as EFeatures);
  };

  return {
    isLoading: isLoadingSubscriptions || isLoadingProfile,
    availableFeatures,
    canRender
  };
};

import { dateProvider } from '@utils';
import { TFunction } from 'i18next';

export const getCreatedAtDateString = (date: Date, t: TFunction<'StudentCourseTranslations'>) => {
  const now = dateProvider.now();
  const hoursDiff = dateProvider.diff(date, now, 'hours');
  const daysDiff = dateProvider.diff(date, now, 'days');
  const monthsDiff = dateProvider.diff(date, now, 'months');
  const yearsDiff = dateProvider.diff(date, now, 'years');

  if (hoursDiff < 24) return t('TODAY');
  if (daysDiff < 7) return t('DAYS_AGO', { count: daysDiff });
  if (daysDiff < 30) return t('WEEKS_AGO', { count: Math.round(daysDiff / 7) });
  if (monthsDiff < 12) return t('MONTHS_AGO', { count: monthsDiff > 0 ? monthsDiff : 1 });
  return t('YEARS_AGO', { count: yearsDiff });
};

import { ITicket } from '@sdk/contracts';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import {
  AvatarThreadWrapper,
  ItemWrapper,
  SeeMoreAnswersButton,
  SeeMoreAnswersWrapper,
  ThreadWrapper,
  Wrapper
} from './TicketItem.styles';

import { Icon } from '@/components/Icon';
import { MessageContent } from '@/components/TicketList/MessageContent';
import { TicketAnswer } from '@/components/TicketList/TicketAnswer';
import { UserInfo } from '@/components/TicketList/UserInfo';
import { useMutateMyTickets } from '@/store';

type Props = {
  ticket: ITicket;
  isHighlighted: boolean;
  id: string;
};

export const TicketItem: React.FC<Props> = ({ ticket, isHighlighted, id }) => {
  const { t } = useTranslation('StudentCourseTranslations');
  const [displayAnswersIndex, setDisplayAnswersIndex] = useState(1);
  const { removeTicket } = useMutateMyTickets();

  const filteredMessages = useMemo(() => {
    return ticket.messages
      ?.filter(message => !message.isAutomatic)
      .sort((a, b) => (b.usefulAnswer?.length ?? 0) - (a.usefulAnswer?.length ?? 0));
  }, [ticket.messages]);

  const messages = useMemo(() => {
    return filteredMessages?.slice(0, displayAnswersIndex);
  }, [filteredMessages, displayAnswersIndex]);

  const loadMoreAnswers = () => setDisplayAnswersIndex(displayAnswersIndex + 3);
  const collapseAnswers = () => setDisplayAnswersIndex(1);

  const isLastMessage = useMemo(
    () => messages?.length === filteredMessages?.length,
    [messages, filteredMessages]
  );

  const handleDeleteTicket = () => removeTicket(ticket._id);

  return (
    <ItemWrapper id={id}>
      <Wrapper>
        <UserInfo ticket={ticket} />
        <MessageContent
          canAnswer
          content={ticket.description}
          sender={ticket.issuer}
          file={ticket.attachment}
          createdAt={ticket.createdAt}
          ticketId={ticket._id}
          onDeleteMessage={handleDeleteTicket}
          isHighlighted={isHighlighted}
        />
      </Wrapper>
      {messages?.map((message, msgIndex) => (
        <TicketAnswer
          key={message._id}
          message={message}
          ticketId={ticket._id}
          isLastMessage={msgIndex === messages?.length - 1 && filteredMessages?.length <= 1}
        />
      ))}
      {filteredMessages && filteredMessages?.length > 1 && (
        <SeeMoreAnswersWrapper>
          <ThreadWrapper>
            <AvatarThreadWrapper />
          </ThreadWrapper>
          <SeeMoreAnswersButton onClick={isLastMessage ? collapseAnswers : loadMoreAnswers}>
            <Icon i={isLastMessage ? 'ArrowUp' : 'ArrowDown'} />
            {isLastMessage ? t('SEE_LESS_ANSWERS') : t('SEE_MORE_ANSWERS')}
          </SeeMoreAnswersButton>
        </SeeMoreAnswersWrapper>
      )}
    </ItemWrapper>
  );
};

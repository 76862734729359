/* eslint-disable @typescript-eslint/no-explicit-any */
import { EActivityType } from '@constants';
import React from 'react';

import { Wrapper } from './ActivityTypes.styles';
import { DeliverableType } from './DeliverableType';
import { InteractiveVideoType } from './InteractiveVideoType';
import { PdfType } from './PdfType';
import { QuizType } from './QuizType';
import { TextType } from './TextType';
import { VideoType } from './VideoType';

type Props = {
  value?: any;
  onChange?: (value: any) => void;
  type?: keyof typeof EActivityType | null;
};

const options: { [key in EActivityType]: React.FC<Omit<Props, 'type'>> } = {
  [EActivityType.VIDEO]: VideoType,
  [EActivityType.INTERACTIVE_VIDEO]: InteractiveVideoType,
  [EActivityType.PDF]: PdfType,
  [EActivityType.QUIZ]: QuizType,
  [EActivityType.DELIVERABLE]: DeliverableType,
  [EActivityType.TEXT]: TextType
};

export const ActivityTypes: React.FC<Props> = props => {
  const { type, value, onChange } = props;

  if (!type) return null;

  const Component = options[type];
  return <Wrapper>{<Component value={value} onChange={onChange} />}</Wrapper>;
};

import { AnalyticsTrackEvents } from '@constants';
import { Button } from 'antd';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { ExamGroupItem } from './ExamGroupItem';
import { EmptyWrapper, ViewMoreWrapper, Wrapper } from './MyExamGroups.styles';

import { Icon } from '@/components/Icon';
import { useAnalytics } from '@/hooks';
import { useWidgetNextExamGroups } from '@/store';

export const MyExamGroups = () => {
  const navigateTo = useNavigate();
  const { t } = useTranslation();
  const { fireEvent } = useAnalytics();
  const { examGroups } = useWidgetNextExamGroups();

  const fireWidgetEvent = () =>
    fireEvent(AnalyticsTrackEvents.HOME_WIDGET, {
      widgetName: 'EXAM_GROUPS'
    });

  const handleGoToExamGroups = () => {
    navigateTo('/profile?activeTab=EXAM_GROUPS');
    fireWidgetEvent();
  };

  const handleGoToExamGroupDetail = (examGroupId: string) => {
    navigateTo(`/exam-groups?examGroupId=${examGroupId}`);
    fireWidgetEvent();
  };

  return (
    <Wrapper>
      {examGroups && examGroups.length > 0 ? (
        <>
          {examGroups.map((examGroup, index) => (
            <ExamGroupItem
              examGroup={examGroup}
              key={`${examGroup._id}-${index}`}
              onClick={handleGoToExamGroupDetail}
            />
          ))}
          <ViewMoreWrapper onClick={handleGoToExamGroups}>
            {t('VIEW_MORE_EXAM_GROUPS')}
          </ViewMoreWrapper>
        </>
      ) : (
        <EmptyWrapper>
          <div>{t('YOUR_EXAM_GROUPS_EMPTY')}</div>
          <Button icon={<Icon i="CalendarAdd" />} onClick={handleGoToExamGroups}>
            {t('JOIN_EXAM_GROUP')}
          </Button>
        </EmptyWrapper>
      )}
    </Wrapper>
  );
};

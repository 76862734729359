import { EFeatures, ERoles } from '@constants';

import { AppWidget } from '../components/AppWidget';
import { ChatWidget } from '../components/ChatWidget';
import { ContactWidget } from '../components/ContactWidget';
import { GamificationWidget } from '../components/GamificationWidget';
import { InfoWidget } from '../components/InfoWidget';
import { LiveClassesWidget } from '../components/LiveClassesWidget';
import { OpenTicketsWidget } from '../components/OpenTicketsWidget';
import { PendingTasksWidget } from '../components/PendingTasksWidget';
import { PostWidget } from '../components/PostWidget';
import { QuizModeWidget } from '../components/QuizModeWidget';
import { ReportsWidget } from '../components/ReportsWidget';
import { StudentContentWidget } from '../components/StudentContentWidget';

import { ExamGroupsWidget, StudentLiveClassesWidget } from '@/components/Widgets';
import { WidgetProps } from '@/types';

type Props = Record<
  ERoles,
  {
    Widget: React.FC<WidgetProps>;
    position: { x: number; y: number; w: number; h: number };
    availableForFeature?: EFeatures;
  }[]
>;

export const WIDGETS: Props = {
  [ERoles.ADMIN]: [],
  [ERoles.MANAGER]: [
    { Widget: InfoWidget, position: { x: 0, y: 0, w: 6, h: 1 } },
    { Widget: PostWidget, position: { x: 6, y: 0, w: 5, h: 2 } },
    { Widget: ReportsWidget, position: { x: 0, y: 1, w: 6, h: 1 } }
  ],
  [ERoles.TEACHER]: [
    { Widget: PendingTasksWidget, position: { x: 0, y: 0, w: 12, h: 1 } },
    { Widget: LiveClassesWidget, position: { x: 0, y: 1, w: 6, h: 1.5 } },
    { Widget: OpenTicketsWidget, position: { x: 6, y: 1, w: 6, h: 1.5 } }
  ],
  [ERoles.STUDENT]: [
    {
      Widget: StudentContentWidget,
      position: { x: 0, y: 0, w: 6, h: 1 },
      availableForFeature: EFeatures.CONTENT
    },
    {
      Widget: QuizModeWidget,
      position: { x: 6, y: 0, w: 6, h: 1 },
      availableForFeature: EFeatures.QUIZZES
    },
    {
      Widget: PostWidget,
      position: { x: 0, y: 1, w: 4, h: 2 }
    },
    {
      Widget: GamificationWidget,
      position: { x: 4, y: 2, w: 4, h: 1 }
    },
    {
      Widget: ChatWidget,
      position: { x: 8, y: 1, w: 4, h: 1 },
      availableForFeature: EFeatures.TUTORING
    },
    {
      Widget: StudentLiveClassesWidget,
      position: { x: 4, y: 1, w: 4, h: 1 },
      availableForFeature: EFeatures.LIVE_CLASSES
    },
    {
      Widget: ExamGroupsWidget,
      position: { x: 8, y: 2, w: 4, h: 1 },
      availableForFeature: EFeatures.ANNOUNCEMENTS
    },
    {
      Widget: AppWidget,
      position: { x: 0, y: 2, w: 4, h: 1 }
    },
    {
      Widget: ContactWidget,
      position: { x: 4, y: 2, w: 4, h: 1 }
    }
  ],
  [ERoles.SALES]: [{ Widget: InfoWidget, position: { x: 0, y: 0, w: 6, h: 1 } }],
  [ERoles.SUPPORT]: [{ Widget: InfoWidget, position: { x: 0, y: 0, w: 6, h: 1 } }],
  [ERoles.INSPECTOR]: [{ Widget: InfoWidget, position: { x: 0, y: 0, w: 6, h: 1 } }],
  [ERoles.AI_TEACHER]: []
};

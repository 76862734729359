import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  position: sticky;
  bottom: 0;
  width: 100%;
  background-color: ${({ theme }) => theme.colors.white};

  & > :last-child {
    margin-top: 1rem;
    display: flex;
    justify-content: space-between;
  }
`;

import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  gap: 16px;
`;

export const TitleWrapper = styled.div`
  font-weight: 600;
`;

export const FilesWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 100%;
  max-height: 280px;
  overflow-y: auto;

  .ant-checkbox-wrapper {
    width: 100%;

    & > span:last-child {
      width: 100%;
      padding-inline-end: 0 !important;
    }
  }
`;

export const FileWrapper = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  gap: 12px;
  cursor: pointer;
  border-radius: 4px;
  border: 1px solid ${({ theme }) => theme.colors.black3};
  width: 100%;
  padding: 4px;
  margin-bottom: 4px;
`;

export const FileIconWrapper = styled.div`
  display: flex;
  padding: 7px;
  border-radius: 4px;
  background: ${({ theme }) => theme.colors.red7};
  color: ${({ theme }) => theme.colors.white};
`;

export const FileNameWrapper = styled.div`
  width: 100%;
  font-weight: 600;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  display: -webkit-box;
`;

import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const PostWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  border-radius: 8px;
  background: ${({ theme }) => theme.colors.white};
  box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.15);
  flex: 1 1 0px;
  cursor: pointer;
`;

export const PostListWrapper = styled.div`
  display: flex;
  gap: 16px;
  flex-direction: row;
`;

export const TitleWrapper = styled.div`
  color: ${({ theme }) => theme.colors.black9};
  font-feature-settings: 'liga' off, 'clig' off;
  font-family: ${({ theme }) => theme.fontFamily.poppins};
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 28px;
  letter-spacing: -0.2px;

  overflow: hidden;
  text-overflow: ellipsis;

  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
`;

export const PostTitleWrapper = styled(TitleWrapper)`
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
`;

export const SubtitleWrapper = styled.div`
  overflow: hidden;
  color: ${({ theme }) => theme.colors.black6};
  text-overflow: ellipsis;
  font-family: ${({ theme }) => theme.fontFamily.openSans};
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
`;

export const DateWrapper = styled.div`
  overflow: hidden;
  color: ${({ theme }) => theme.colors.black6};
  text-overflow: ellipsis;
  font-family: ${({ theme }) => theme.fontFamily.openSans};
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  margin-top: auto;
`;

export const ImageWrapper = styled.div`
  width: 100%;
  border-radius: 8px;
  img {
    object-fit: cover;
    border-radius: 8px;
    box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.15);
  }
`;

export const InfoWrapper = styled.div`
  height: 100%;
  padding: 16px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

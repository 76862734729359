import { ITrend } from '@sdk/contracts';
import styled from 'styled-components';

type Props = {
  trend?: ITrend;
  isReverse?: boolean;
};

export const StatWrapper = styled.div<Props>`
  display: flex;
  align-items: center;
  color: ${({ theme, trend, isReverse }) =>
    trend === 'up' && isReverse
      ? theme.colors.red6
      : trend === 'up' && !isReverse
      ? theme.colors.green6
      : trend === 'down' && isReverse
      ? theme.colors.green6
      : trend === 'down' && !isReverse
      ? theme.colors.red6
      : theme.colors.black10};

  font-family: ${({ theme }) => theme.fontFamily.openSans};
  font-size: 14px;
  font-weight: 600;
  line-height: 22px;
`;

import { Flex } from 'antd';
import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  padding: 20px;
  gap: 20px;
  border: 1px solid ${({ theme }) => theme.colors.black3};
  background: ${({ theme }) => theme.colors.white};
`;

export const TitleWrapper = styled.span`
  color: ${({ theme }) => theme.colors.black9};
  font-family: ${({ theme }) => theme.fontFamily.poppins};
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: -0.2px;
`;

export const EmptyWrapper = styled(Flex)`
  height: 100%;
  padding: 40px 0;
`;

export const EmojiWrapper = styled.span`
  font-family: ${({ theme }) => theme.fontFamily.poppins};
  font-size: 30px;
  font-weight: 600;
  line-height: 40px;
  letter-spacing: -0.5px;
`;

export const NoDocumentsWrapper = styled.span`
  color: ${({ theme }) => theme.colors.black7};
  text-align: center;
  font-family: ${({ theme }) => theme.fontFamily.poppins};
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: -0.2px;
`;

export const UploadFilesWrapper = styled.span`
  color: ${({ theme }) => theme.colors.black7};
  text-align: center;
  font-family: ${({ theme }) => theme.fontFamily.openSans};
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
`;

import { IFile } from '@sdk/contracts';
import { Button } from 'antd';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Wrapper, FormulaWrapper, ButtonsWrapper } from './MessageSender.styles';

import { HtmlEditor } from '@/components/Form';
import { Icon } from '@/components/Icon';
import { InsertFormula } from '@/components/InsertFormula';
import { Upload } from '@/components/Upload';
import { useTicketMessageDraft } from '@/hooks';
import { useMutateMyTickets } from '@/store';

type Props = {
  ticketId: string;
};

export const MessageSender: React.FC<Props> = ({ ticketId }) => {
  const { isWorking, sendMessage } = useMutateMyTickets();
  const { t } = useTranslation(['CommonTicketsTranslations', 'CommonQuizFormTranslations']);
  const { getDraft, setDraft, clearDraft } = useTicketMessageDraft();
  const messagesDraft = getDraft(ticketId);
  const { draftText = undefined, draftFormula = undefined } = messagesDraft || {};

  const [text, setText] = useState<string | undefined>(draftText);
  const [formula, setFormula] = useState<string | undefined>(draftFormula);
  const [isFormulaEditorOpen, setIsFormulaEditorOpen] = useState(false);
  const [file, setFile] = useState<IFile | null>(null);
  const hasFile = Boolean(file);
  const hasText = Boolean(text);
  const hasFormula = Boolean(formula);
  const textRef = useRef<string | undefined>(text);
  const formulaRef = useRef<string | undefined>(formula);

  const handleSendMessage = () =>
    sendMessage(
      {
        ticketId,
        createMessage: {
          content: text,
          attached: file ?? undefined,
          formula: formula ?? undefined
        }
      },
      {
        onSuccess: () => {
          setFile(null);
          setFormula(undefined);
          setText(undefined);
          textRef.current = undefined;
          formulaRef.current = undefined;
        }
      }
    );
  const handleTextChange = (value: string) => {
    textRef.current = value;
    setText(value);
  };
  const handleFormulaChange = (value: string | undefined) => {
    formulaRef.current = value;
    setFormula(value);
  };

  useEffect(() => {
    return () => {
      if (textRef.current || formulaRef.current) {
        setDraft(ticketId, textRef.current, formulaRef.current);
      } else {
        clearDraft(ticketId);
      }
    };
  }, []);

  return (
    <Wrapper>
      {formula && <FormulaWrapper dangerouslySetInnerHTML={{ __html: formula }} />}
      <HtmlEditor value={text} onChange={handleTextChange} disabled={hasFile || hasFormula} />
      <ButtonsWrapper>
        <Upload
          value={file}
          onChange={(f: IFile | IFile[] | null) => setFile(f as IFile)}
          maxCount={1}
          mode="upload"
          accept="application/pdf,image/*"
          disabled={hasText}
        />
        <Button
          icon={<Icon i="Function" />}
          onClick={() => setIsFormulaEditorOpen(true)}
          style={{ marginLeft: 8 }}>
          {t('CommonQuizFormTranslations:INSERT_FORMULA')}
        </Button>
        <InsertFormula
          isOpen={isFormulaEditorOpen}
          setIsOpen={setIsFormulaEditorOpen}
          onChange={(html?: string) => handleFormulaChange(html)}
        />
        <Button
          type="primary"
          disabled={!hasText && !hasFile && !hasFormula}
          loading={isWorking}
          onClick={handleSendMessage}
          icon={<Icon i="Send" />}>
          {t('SEND')}
        </Button>
      </ButtonsWrapper>
    </Wrapper>
  );
};

import { Button } from 'antd';
import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  padding: 12px;
  align-items: center;
  justify-content: space-between;
  border-radius: 8px;
  border: 1px solid ${({ theme }) => theme.colors.black3};
  cursor: pointer;
  transition: all 0.3s ease;

  &:hover {
    background: ${({ theme }) => theme.colors.black1};
  }
`;

export const IconWrapper = styled.span`
  display: flex;
  padding: 7px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 4px;
  background: ${({ theme }) => theme.colors.red7};
`;

export const FileNameWrapper = styled.span`
  color: ${({ theme }) => theme.colors.black9};
  text-overflow: ellipsis;
  font-family: ${({ theme }) => theme.fontFamily.openSans};
  font-size: 14px;
  font-weight: 600;
  line-height: 22px;
`;

export const ButtonWrapper = styled(Button)<{ $isHovered: boolean }>`
  opacity: ${({ $isHovered }) => ($isHovered ? 1 : 0)};
  transition: all 0.3s ease;
`;

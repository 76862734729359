import styled from 'styled-components';

export const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
  gap: 8px;
  @media (min-width: ${({ theme }) => theme.mediaQueries.xs}) {
    padding: 0 24px;
  }

  @media (min-width: ${({ theme }) => theme.mediaQueries.md}) {
    padding: 0;
  }
`;

export const TextWrapper = styled.div`
  width: 100%;
  display: flex;
  padding: 16px;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  border-radius: 8px;
  background: ${({ theme }) => theme.colors.white};
  max-height: 620px;
  overflow-y: auto;

  p {
    margin: 0;
  }
`;

import styled from 'styled-components';

export const PageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 100%;
`;

export const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 32px;
  padding: 16px 16px 32px 16px;
  border: 1px solid ${({ theme }) => theme.colors.black3};
  background: ${({ theme }) => theme.colors.white};

  .ant-upload-drag-container {
    padding: 8px 16px;
  }
`;

export const TitleWrapper = styled.div`
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  display: flex;
  gap: 16px;
  align-items: center;
`;

export const InfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 100%;
`;

export const FileWrapper = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  gap: 12px;
  cursor: pointer;
`;

export const FileIconWrapper = styled.div`
  display: flex;
  padding: 7px;
  border-radius: 4px;
  background: ${({ theme }) => theme.colors.blue7};
  color: ${({ theme }) => theme.colors.white};
`;

export const FileNameWrapper = styled.div`
  width: 100%;
  font-weight: 600;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  display: -webkit-box;
`;

export const LinkIconWrapper = styled.div`
  display: flex;
  padding: 7px;
  border-radius: 4px;
  background: ${({ theme }) => theme.colors.black6};
  color: ${({ theme }) => theme.colors.white};
`;

export const LinkNameWrapper = styled.div`
  font-weight: 600;
  color: ${({ theme }) => theme.colors.green7};
`;

export const UploadableWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  width: 100%;
`;

export const SubtitleWrapper = styled.div`
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  color: ${({ theme }) => theme.colors.black6};
`;

export const UploadTextWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  width: 100%;
`;

export const UploadIconWrapper = styled.div`
  display: flex;
  padding: 7px;
  border-radius: 4px;
  background: ${({ theme }) => theme.colors.black3};
  color: ${({ theme }) => theme.colors.black7};
`;

export const UploadedFileWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  border: 1px solid ${({ theme }) => theme.colors.black3};
  border-radius: 4px;
  padding: 8px 16px;
`;

export const FooterWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-end;
`;

import { IUser } from '@sdk/contracts';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { message } from 'antd';
import { useTranslation } from 'react-i18next';

import { sdk } from '@/services';

const MyAcademyTeachersService = sdk.getService('MyAcademyTeachersService');
const MyAcademyService = sdk.getService('MyAcademyService');
const UserService = sdk.getService('UserService');

export const useMutateTeachers = () => {
  const queryClient = useQueryClient();
  const onSuccess = () => {
    queryClient.invalidateQueries({ queryKey: ['teachers'] });
    queryClient.invalidateQueries({ queryKey: ['teacher'] });
  };

  const { t } = useTranslation(['DefaultTranslations', 'ManagerTeachersTranslations']);

  const { mutate: createTeacher, isPending: isCreating } = useMutation({
    mutationFn: (newTeacher: Partial<IUser>) => MyAcademyTeachersService.createTeacher(newTeacher),
    onSuccess: () => {
      onSuccess();
      message.success(t('ManagerTeachersTranslations:NEW_TEACHER_ADDED'));
    }
  });

  const { mutate: updateTeacher, isPending: isUpdating } = useMutation({
    mutationFn: (updatedTeacher: Partial<IUser>) =>
      MyAcademyTeachersService.updateTeacher(updatedTeacher),
    onSuccess: () => {
      onSuccess();
      message.success(t('ManagerTeachersTranslations:TEACHER_UPDATED_SUCCESSFULLY'));
    }
  });

  const { mutate: removeTeacher, isPending: isRemoving } = useMutation({
    mutationFn: (userId: string) => MyAcademyService.removeUser(userId),
    onSuccess: () => {
      onSuccess();
      message.warning(t('DefaultTranslations:USER_DELETED_SUCCESSFULLY'));
    }
  });

  const { mutate: toggleActivateTeacher, isPending: isToggling } = useMutation({
    mutationFn: (userId: string) => UserService.toggleActivateUser(userId),
    onSuccess
  });

  return {
    createTeacher,
    updateTeacher,
    removeTeacher,
    toggleActivateTeacher,
    isWorking: isCreating || isUpdating || isRemoving || isToggling
  };
};

import { IGenerateQuiz } from '@sdk/contracts';
import { useMutation } from '@tanstack/react-query';

import { useQuizForm } from '@/hooks';
import { sdk } from '@/services';
import { mapGeneratedQuestionsData } from '@/utils';

const QuizService = sdk.getService('QuizService');

export const useGenerateQuiz = () => {
  const { setQuestions, questions } = useQuizForm();

  const { mutate: generateQuiz, isPending } = useMutation({
    mutationFn: (generateQuizParams: IGenerateQuiz) => QuizService.generateQuiz(generateQuizParams),
    onSuccess: generatedQuestions => setQuestions(mapGeneratedQuestionsData(generatedQuestions))
  });

  return { generateQuiz, isWorking: isPending, questions, setQuestions };
};

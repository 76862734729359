export const StudentTicketsTranslations = {
  DOUBTS: {
    es: 'Dudas',
    en: 'Doubts',
    cat: 'Dubtes',
    eus: 'Zalantzak',
    pt: 'Dúvidas'
  },
  OPEN_DOUBT: {
    es: 'Abrir duda',
    en: 'Open doubt',
    cat: 'Obre dubte',
    eus: 'Ireki zalantza',
    pt: 'Abrir dúvida'
  },
  FILTER: {
    es: 'Filtrar',
    en: 'Filter',
    cat: 'Filtra',
    eus: 'Iragazi',
    pt: 'Filtrar'
  },
  SEND: {
    es: 'Enviar',
    en: 'Send',
    cat: 'Envia',
    eus: 'Bidali',
    pt: 'Enviar'
  },
  SUBJECT: {
    es: 'Asunto',
    en: 'Subject',
    cat: 'Assumpte',
    eus: 'Gaia',
    pt: 'Assunto'
  },
  WRITE_HERE_YOUR_DOUBT: {
    es: 'Escribe aquí tu duda',
    en: 'Write here your doubt',
    cat: 'Escriu aquí el teu dubte',
    eus: 'Idatzi hemen zure zalantza',
    pt: 'Escreva aqui a sua dúvida'
  },
  COURSE: {
    es: 'Curso',
    en: 'Course',
    cat: 'Curs',
    eus: 'Ikastaroa',
    pt: 'Curso'
  },
  TICKET_CREATED_SUCCESSFULLY: {
    es: 'Ticket creado correctamente',
    en: 'Ticket created successfully',
    cat: 'Ticket creat correctament',
    eus: 'Ticketa ongi sortu da',
    pt: 'Ticket criado com sucesso'
  },
  SET_TICKET_AS_RESOLVED: {
    es: 'Marcar como resuelto',
    en: 'Set as resolved',
    cat: 'Marcar com a resolt',
    eus: 'Markatu ebaztuta',
    pt: 'Marcar como resolvido'
  },
  TICKET_HAS_BEEN_SET_AS_RESOLVED: {
    es: 'El ticket ha sido marcado como resuelto',
    en: 'The ticket has been set as resolved',
    cat: 'El ticket ha estat marcat com a resolt',
    eus: 'Ticketa ebaztuta markatu da',
    pt: 'Ticket resolvido'
  },
  DESCRIPTION_REQUIRED: {
    es: 'La descripción es requerida',
    en: 'Description is required',
    cat: 'La descripció és requerida',
    eus: 'Deskribapena beharrezkoa da',
    pt: 'Descrição é obrigatória'
  },
  DELIVER: {
    es: 'Entregar',
    en: 'Deliver',
    cat: 'Lliurar',
    eus: 'Entregatu',
    pt: 'Entregar'
  },
  CSAT_RATING_TITLE: {
    es: '¿Estás satisfecho con la resolución de tu duda?',
    en: 'Are you satisfied with the resolution of your doubt?',
    cat: 'Estàs satisfet amb la resolució del teu dubte?',
    eus: 'Zalantzaren ebazpenarekin pozik zaude?',
    pt: 'Você está satisfeito com a resolução da sua dúvida?'
  },
  THANKS_FOR_YOUR_FEEDBACK: {
    es: 'Gracias por tu feedback 😊',
    en: 'Thanks for your feedback 😊',
    cat: 'Gràcies pel teu feedback 😊',
    eus: 'Mila esker zure feedbackagatik 😊',
    pt: 'Muito obrigado pelo seu feedback 😊'
  },
  COURSE_REQUIRED: {
    es: 'El curso es requerido',
    en: 'Course is required',
    cat: 'El curs és requerit',
    eus: 'Ikastaroa beharrezkoa da',
    pt: 'O curso é obrigatório'
  },
  CHECK_YOUR_TICKETS: {
    es: 'Consulta todas las preguntas que has hecho en los temas de estudio.',
    en: 'Check all the questions you have made in the study topics.',
    cat: "Consulta totes les preguntes que has fet als temes d'estudi.",
    eus: 'Ikusi zure ikastetxean egin dituzun gaietako gai guztiak.',
    pt: 'Ver todas as perguntas que fez nos temas de estudo.'
  },
  TO_OPEN_A_TICKET: {
    es: 'Para abrir una nueva duda, entra a una lección y escribe tu pregunta en los comentarios.',
    en: 'To open a new doubt, go to a lesson and write your question in the comments.',
    cat: 'Per obrir un dubte nou, accedeix a una lliçó i escriu la teva pregunta als comentaris.',
    eus: 'Zalantza berria irekitzeko, lekziora joan eta zure galdera komentarioetan idatzi.',
    pt: 'Para abrir uma nova dúvida, vá para uma lição e escreva sua pergunta nos comentários.'
  },
  YOU_DIDNT_ASK_ANY_QUESTIONS: {
    es: 'Aún no has hecho ninguna pregunta',
    en: "You didn't ask any questions",
    cat: 'Encara no has fet cap pregunta',
    eus: 'Ez zenuen galdera bat egin',
    pt: 'Você não fez nenhuma pergunta'
  },
  TO_RESOLVE_ANY_QUESTION: {
    es: 'Para resolver una duda, entra a una lección y escribe tu pregunta en los comentarios. Todas las preguntas que hagas se guardarán aquí para que puedas revisarlas cuando necesites.',
    en: 'To resolve a doubt, go to a lesson and write your question in the comments. All the questions you make will be saved here so you can review them when you need.',
    cat: 'Per resoldre un dubte, accedeix a una lliçó i escriu la teva pregunta als comentaris. Totes les preguntes que fas es guardaran aquí per que puguis revisar-les quan necessites.',
    eus: 'Zalantza bat ebazteko, lekziora joan eta zure galdera komentarioetan idatzi. Egin dituzun gai guztiak hemen gordetu dira, beraz, behar duzun unean ebatzi ditzakezu.',
    pt: 'Para resolver uma dúvida, vá para uma lição e escreva sua pergunta nos comentários. Todas as perguntas que você faz serão salvas aqui para que você possa revisá-las quando precisar.'
  },
  WRITE_ANSWER: {
    es: 'Escribe tu respuesta',
    en: 'Write your answer',
    cat: 'Escriu la teva resposta',
    eus: 'Idatzi zure erantzuna',
    pt: 'Escreva sua resposta'
  }
};

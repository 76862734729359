import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 50%;

  .ant-switch-checked {
    background-color: ${({ theme }) => theme.colors.green6};
  }
  .ant-switch-checked:hover {
    background-color: ${({ theme }) => theme.colors.green4};
  }

  .ant-checkbox-checked .ant-checkbox-inner {
    background-color: ${({ theme }) => theme.colors.green6};
    border-color: ${({ theme }) => theme.colors.green6};
  }
`;

export const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  font-weight: 600;
`;

export const LoadingWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  height: 100%;
`;

import { useQuery } from '@tanstack/react-query';

import { sdk } from '@/services';

const MyAcademyInternalUsersService = sdk.getService('MyAcademyInternalUsersService');

export const useAllInternalUsersForSelectors = () => {
  const { isLoading, data, error } = useQuery({
    queryKey: ['internal-users', 'all'],
    queryFn: () => MyAcademyInternalUsersService.findAllInternalUsers()
  });

  return {
    internalUsers: data,
    isLoading,
    error
  };
};

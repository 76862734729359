import { ETicketStatus } from '@constants';
import { AnalyticsTrackEvents } from '@constants';
import { ITicket, ITicketRelatedContent } from '@sdk/contracts';
import { dateProvider } from '@utils';
import { Table } from 'antd';
import { TFunction } from 'i18next';
import { AlignType } from 'rc-table/lib/interface';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';

import { TicketStatusBadge } from '../TicketStatusBadge';

import { CsatReview } from './CsatReview';
import { MoreActions } from './MoreActions';
import { ActivityLinkWrapper } from './TicketList.styles';
import { TicketVisibility } from './TicketVisibility';
import { TicketVotes } from './TicketVotes';

import { ActivityPreview } from '@/components/ActivityPreview';
import { useActivityPreview, useAnalytics } from '@/hooks';
import { IInitialFilters, useMySubscriptions, useMyTicketsList, useProfile } from '@/store';

type Props = {
  initialFilters: IInitialFilters;
  activeKey?: ETicketStatus;
};

export const TicketList: React.FC<Props> = ({ initialFilters, activeKey }) => {
  const { tickets, isLoading, pagination, setPageAndLimit } = useMyTicketsList(initialFilters);
  const { t } = useTranslation('DefaultTranslations');
  const { setActivityId } = useActivityPreview();
  const { subscriptions } = useMySubscriptions();
  const { fireEvent } = useAnalytics();
  const navigateTo = useNavigate();
  const { pathname } = useLocation();

  const {
    roleCheck: { isStudent }
  } = useProfile();

  const handleNavigateToActivity = (ticketRelatedContent?: ITicketRelatedContent) => {
    if (
      !ticketRelatedContent ||
      !ticketRelatedContent.activity?._id ||
      !ticketRelatedContent.section
    )
      return;

    if (isStudent) {
      const subscription = subscriptions?.find(
        s => s.course._id === ticketRelatedContent?.course?._id
      );
      return navigateTo(
        `/subscription/${subscription?._id}?activity=${ticketRelatedContent.activity._id}`
      );
    }

    setActivityId(ticketRelatedContent.activity._id);
  };

  const onClick = (ticket: ITicket) => () => {
    navigateTo(`${pathname}?ticketId=${ticket._id}`);
    fireEvent(AnalyticsTrackEvents.DOUBTS_CONSULT, {
      title: ticket.title,
      courseName: ticket.ticketRelatedContent.course.name
    });
  };

  return (
    <>
      <Table
        rowKey="_id"
        size="small"
        loading={isLoading}
        dataSource={tickets}
        columns={columns(t, isStudent, handleNavigateToActivity, activeKey)}
        scroll={{ y: window.innerHeight - 300 }}
        onRow={ticket => ({
          onClick: onClick(ticket)
        })}
        pagination={{
          current: pagination?.page,
          pageSize: pagination?.limit,
          total: pagination?.totalDocs,
          showTotal: total => t('TOTAL', { total }),
          onChange: (newPage: number, newLimit: number) => setPageAndLimit(newPage, newLimit)
        }}
      />
      {!isStudent && <ActivityPreview />}
    </>
  );
};

const columns = (
  t: TFunction<'DefaultTranslations'>,
  isStudent: boolean,
  handleNavigateToActivity: (ticketRelatedContent?: ITicketRelatedContent) => void,
  activeKey?: ETicketStatus
) => {
  const isClosed = activeKey === ETicketStatus.CLOSED_RESOLVED;
  const items = [
    !isClosed
      ? {
          title: t('ACCOUNT_STATUS'),
          key: 'status',
          dataIndex: 'status',
          width: 120,
          align: 'center' as AlignType,
          render: (status: ETicketStatus) => <TicketStatusBadge status={status} />
        }
      : { width: 10 },
    {
      title: t('TICKET_TITLE'),
      dataIndex: 'description',
      width: 250,
      key: 'description',
      ellipsis: true
    },
    {
      title: t('USER'),
      dataIndex: isStudent ? ['assignee', 'name'] : ['issuer', 'name'],
      key: isStudent ? 'assignee' : 'issuer',
      width: 100,
      align: 'center' as AlignType
    },
    {
      title: t('COURSE'),
      dataIndex: ['ticketRelatedContent', 'course', 'name'],
      key: 'course',
      width: 250,
      ellipsis: true
    },
    {
      title: t('ACTIVITY_TITLE'),
      dataIndex: ['ticketRelatedContent'],
      key: 'activityTitle',
      width: 250,
      ellipsis: true,
      render: (ticketRelatedContent: ITicketRelatedContent) => (
        <ActivityLinkWrapper
          onClick={e => {
            e.stopPropagation();
            handleNavigateToActivity(ticketRelatedContent);
          }}>
          {ticketRelatedContent.activity?.name ?? '-'}
        </ActivityLinkWrapper>
      )
    },
    {
      title: t('DATE'),
      dataIndex: 'createdAt',
      key: 'createdAt',
      align: 'center' as AlignType,
      width: 100,
      render: (createdAt: Date) => dateProvider.format(createdAt, 'dd/MM/yyyy')
    },
    {
      title: t('CSAT'),
      dataIndex: ['review', 'csat'],
      key: 'review',
      width: 100,
      align: 'center' as AlignType,
      render: (csat: number, ticket: ITicket) => <CsatReview status={ticket.status} csat={csat} />
    }
  ];

  const managerItems = isClosed
    ? [
        {
          title: t('VISIBILITY'),
          dataIndex: 'isPublic',
          key: 'isPublic',
          width: 100,
          align: 'center' as AlignType,
          render: (isPublic: boolean, ticket: ITicket) => (
            <TicketVisibility isPublic={isPublic} ticketId={ticket._id} />
          )
        },
        {
          title: t('VOTES'),
          dataIndex: 'usefulTicket',
          key: 'usefulTicket',
          width: 100,
          align: 'center' as AlignType,
          render: (usefulTicket: string[], ticket: ITicket) => (
            <TicketVotes votes={usefulTicket} isDisabled={!ticket.isPublic} />
          )
        }
      ]
    : [];
  return [
    ...items,
    ...managerItems,
    {
      title: t('ACTIONS'),
      align: 'center' as AlignType,
      width: 100,
      render: (ticket: ITicket) => <MoreActions ticket={ticket} />
    }
  ];
};

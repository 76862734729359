import styled from 'styled-components';

export const Wrapper = styled.div`
  width: 100%;
  padding-bottom: 1rem;
  overflow-y: auto;
  height: 100%;

  & > :last-child:is(.ant-tag) {
    text-align: center;
    margin: auto;
  }
`;

export const ContentWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-start;
  gap: 4px;
  align-self: stretch;
  width: 100%;
`;

import { EVoteReason } from '@constants';
import { ICreateNpsVote } from '@sdk/contracts';
import { Form } from 'antd';
import { CarouselRef } from 'antd/es/carousel';
import { useEffect, useRef, useState } from 'react';

import { useNpsIsPromoter } from '@/hooks';
import { useMutateNpsVote, useCheckNpsVote } from '@/store';

export const useNpsVoteLogic = () => {
  const { createNpsVote, isCreating } = useMutateNpsVote();
  const { voteCheck, isPending } = useCheckNpsVote();
  const [form] = Form.useForm<ICreateNpsVote>();
  const carouselRef = useRef<CarouselRef>(null);
  const [isOpen, setIsOpen] = useState(false);
  const [step, setStep] = useState(0);
  const { isPromoter, setIsPromoter } = useNpsIsPromoter();
  const isEverythingClear = Form.useWatch('isEverythingClear', form);
  const reasons = Form.useWatch('reasons', form);
  const comment = Form.useWatch('comment', form);
  const vote = Form.useWatch('vote', form);

  useEffect(() => {
    setIsPromoter(vote);
  }, [vote]);

  const isNextDisabled =
    (step === 0 && isNaN(vote)) ||
    (step === 1 &&
      ((reasons?.includes(EVoteReason.OTHER) && !comment) || !reasons) &&
      isEverythingClear === undefined) ||
    (step === 2 && !reasons);

  useEffect(() => {
    if (voteCheck?.hasToVote && !isPending) {
      const timeout = setTimeout(() => setIsOpen(true), 5000);
      return () => clearTimeout(timeout);
    }
    return;
  }, [voteCheck]);

  const handleNextStep = () => {
    setStep(step + 1);
    carouselRef.current?.next();
  };

  const handleFinish = (values: ICreateNpsVote) => {
    if (!voteCheck) return;
    createNpsVote({
      ...values,
      type: voteCheck.type
    });
  };

  const handleCancel = () => {
    form.submit();
    setIsOpen(false);
  };

  return {
    form,
    step,
    isOpen,
    isNextDisabled,
    isCreating,
    handleNextStep,
    handleFinish,
    handleCancel,
    carouselRef,
    isPromoter,
    isEverythingClear,
    vote,
    reasons
  };
};

import { IFile } from '@sdk/contracts';
import { Button, message, Upload as BaseUpload } from 'antd';
import { RcFile } from 'antd/lib/upload';
import { UploadFileStatus } from 'antd/lib/upload/interface';
import axios from 'axios';
import { isNil } from 'lodash';
import isEmpty from 'lodash/isEmpty';
import { UploadRequestOption } from 'rc-upload/lib/interface';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Icon } from '../Icon';

import { UploadButton, Wrapper } from './Upload.styles';
import { Uploading } from './Uploading';
import UploadPreview from './UploadPreview/UploadPreview';

import { sdk } from '@/services';
import { IUpload, UploadingProps } from '@/types';
import { getFileDuration, getFilePagesNumber, getFilePdfWordCount } from '@/utils';

const { Dragger } = BaseUpload;

const DEFAULT_SIZE = 200;
const DEFAULT_MODE = 'drag';
const UPLOAD_FAILED_MESSAGE = 'Upload failed';

const FileService = sdk.getService('FileService');

export const Upload: React.FC<IUpload> = ({
  shape = 'default',
  icon,
  value,
  margin,
  block,
  iconOnly,
  onChange,
  children,
  buttonProps,
  label,
  maxCount = 1,
  preview = false,
  mode = DEFAULT_MODE,
  width = DEFAULT_SIZE,
  height = width || DEFAULT_SIZE,
  onMouseEnter,
  onMouseLeave,
  ...props
}) => {
  const isMultiple = maxCount > 1;
  const [files, setFiles] = useState<IFile[]>([]);
  const { t } = useTranslation('CourseTranslations');
  const handleChange = (data: IFile | IFile[] | null) => onChange?.(data);
  const [uploadState, setUploadState] = useState<UploadingProps>({ uploading: false });

  const handleSetFiles = useCallback(() => {
    if (value) setFiles(Array.isArray(value) ? value : [value]);
    if (!value) setFiles([]);
  }, [value]);

  const handleRemove = (fileId: string) => {
    const updatedFiles = files.filter(f => f._id !== fileId);
    handleChange(isEmpty(updatedFiles) ? null : updatedFiles);
    setFiles(updatedFiles);
  };

  const handleUpload = async ({ file }: UploadRequestOption) => {
    try {
      setUploadState(s => ({ ...s, uploading: true }));
      const { type: mimetype, name, size } = file as RcFile;

      const duration = await getFileDuration(file as RcFile);
      const pages = await getFilePagesNumber(file as File);
      const wordCount = await getFilePdfWordCount(file as File);

      const newFile = await FileService.createUploadSignedUrl({
        name,
        size,
        mimetype,
        duration,
        pages,
        wordCount
      });

      if (!newFile) {
        message.error(UPLOAD_FAILED_MESSAGE);
        return;
      }

      try {
        await axios.put(newFile?.url, file, {
          withCredentials: false,
          headers: { 'Content-Type': mimetype },
          onUploadProgress: progress => setUploadState(s => ({ ...s, progress }))
        });

        const uploadedFile = await FileService.getFileWithUrl(newFile._id);
        if (!uploadedFile) {
          message.error(UPLOAD_FAILED_MESSAGE);
          return;
        }

        handleChange(isMultiple ? [...files, uploadedFile] : uploadedFile);
        setFiles(isMultiple ? [...files, uploadedFile] : [uploadedFile]);
        setUploadState(s => ({ ...s, uploading: false }));
      } catch (err) {
        message.error(JSON.stringify(err));
        setUploadState(s => ({ ...s, uploading: false }));
      }
    } catch (err) {
      message.error(JSON.stringify(err));
      setUploadState(s => ({ ...s, uploading: false }));
    }
  };

  useEffect(() => {
    handleSetFiles();
  }, [handleSetFiles]);

  if (mode === 'upload') {
    const formatFileList = (f: IFile[]) =>
      f
        .filter(x => !isNil(x))
        .map(({ _id, name, url }) => ({
          url,
          name,
          uid: _id,
          status: 'done' as UploadFileStatus
        }));

    return (
      <UploadButton block={block} onClick={e => e.stopPropagation()}>
        <BaseUpload
          customRequest={handleUpload}
          fileList={formatFileList(files || [])}
          onRemove={file => handleRemove(file.uid)}
          {...props}>
          <Button
            shape={shape}
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
            loading={uploadState.uploading}
            block={block}
            icon={<Icon i={icon ?? 'Upload'} />}
            {...buttonProps}>
            {!iconOnly && (label ?? t('UPLOAD_FILE'))}
          </Button>
        </BaseUpload>
      </UploadButton>
    );
  }

  return (
    <Wrapper style={{ width, height, margin }} isMultiple={isMultiple} shape={shape}>
      <Dragger
        maxCount={maxCount}
        id="updload-zone"
        customRequest={handleUpload}
        showUploadList={false}
        {...props}>
        {preview && (
          <UploadPreview {...{ files, isMultiple, width, height }} onDelete={handleRemove} />
        )}
        <Uploading {...uploadState} />
        {!uploadState.uploading && !files?.length && children}
        {!preview && files.length > 0 && children}
      </Dragger>
    </Wrapper>
  );
};

export default Upload;

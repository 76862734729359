import { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`
    #root, html,body {
      padding: 0;
      margin: 0;
      font-family: ${({ theme }) => theme.fontFamily.primary};
      height: 100%;
      min-height: 100%;
    }

    #root > .ant-layout {
      height: 100%;
    }

    * {
      box-sizing: border-box;
    }

    h1, h2, h3, h4, h5, h6 {
      font-family: ${({ theme }) => theme.fontFamily.primary};
      font-weight: 600;
      font-style: normal;
    }

    h1 {
      font-size: 40px;
      line-height: 48px;
    }

    h2 {
      font-size: 32px;
      line-height: 39px;
    }

    h3 {
      font-size: 28px;
      line-height: 34px;
    }

    h4 {
      font-size: 24px;
      line-height: 24px;
    }

    h5 {
      font-size: 20px;
      line-height: 24px;
    }

    h6 {
      font-size: 16px;
      line-height: 19px;
    }

    // BUTTONS

    .ant-btn {
      border-radius: 4px;
      padding: 4px 12px;
      justify-content: center;
      align-items: center;
      gap: 8px;
      box-shadow: 1px 1px 2px 0px rgba(0, 0, 0, 0.08);
      font-size: 14px;
      font-weight: 600;
      line-height: 22px;
      font-family: ${({ theme }) => theme.fontFamily.primary};

      :disabled {
        cursor: not-allowed;
      }

      &.ant-btn-loading {
        cursor: default;
        pointer-events: none;
        opacity: 0.65;
      }
      
    }

    .ant-btn-default:not(.ant-btn-dangerous) {
      border: 1px solid ${({ theme }) => theme.colors.black3};
      color: ${({ theme }) => theme.colors.black9};
      background: transparent;

      :hover {
        border: 1px solid ${({ theme }) => theme.colors.green4} !important;
        color: ${({ theme }) => theme.colors.green7} !important;
      }

      :active {
        border: 1px solid ${({ theme }) => theme.colors.green5};
        color: ${({ theme }) => theme.colors.green7} !important;
        background: ${({ theme }) => theme.colors.green1} !important;
      }

      :disabled {
        border: 1px solid ${({ theme }) => theme.colors.black3} !important;
        background: ${({ theme }) => theme.colors.black1} !important;
        color: ${({ theme }) => theme.colors.black5} !important;
      }

      &.ant-btn-loading {
        border: 1px solid ${({ theme }) => theme.colors.green5};
        color: ${({ theme }) => theme.colors.green7} !important;
        background: ${({ theme }) => theme.colors.green1} !important;
      }
    }

    .ant-btn-primary:not(.ant-btn-dangerous):not(.ant-input-search-button) {
      border: 1px solid ${({ theme }) => theme.colors.black9};
      background: ${({ theme }) => theme.colors.black9};
      color: ${({ theme }) => theme.colors.white};

      :hover {
        border: 1px solid ${({ theme }) => theme.colors.green6};
        background: ${({ theme }) => theme.colors.green6} !important;
        color: ${({ theme }) => theme.colors.white} !important;
      }

      :active {
        border: 1px solid ${({ theme }) => theme.colors.green7};
        background: ${({ theme }) => theme.colors.green7} !important;
        color: ${({ theme }) => theme.colors.white} !important;
      }

      :disabled {
        border: 1px solid ${({ theme }) => theme.colors.black4} !important;
        background: ${({ theme }) => theme.colors.black4} !important;
        color: ${({ theme }) => theme.colors.black6} !important;
      }

      &.ant-btn-loading {
        border: 1px solid ${({ theme }) => theme.colors.green7};
        background: ${({ theme }) => theme.colors.green7} !important;
      }
    }

    .ant-btn-tertiary:not(.ant-btn-dangerous):not(.ant-input-search-button) {
      border: 1px solid ${({ theme }) => theme.colors.green4};
      background: ${({ theme }) => theme.colors.white};
      color: ${({ theme }) => theme.colors.green6};

      :hover {
        border: 1px solid ${({ theme }) => theme.colors.green4};
        background: ${({ theme }) => theme.colors.green1} !important;
        color: ${({ theme }) => theme.colors.green7} !important;
      }

      :active {
        border: 1px solid ${({ theme }) => theme.colors.green7};
        background: ${({ theme }) => theme.colors.green1} !important;
        color: ${({ theme }) => theme.colors.green7} !important;
      }

      :disabled {
        border: 1px solid ${({ theme }) => theme.colors.black3} !important;
        background: ${({ theme }) => theme.colors.black1} !important;
        color: ${({ theme }) => theme.colors.black5} !important;
      }

      &.ant-btn-loading {
        border: 1px solid ${({ theme }) => theme.colors.green7};
        background: ${({ theme }) => theme.colors.green1} !important;
        color: ${({ theme }) => theme.colors.green7} !important;
}
    }


    .ant-btn-link:not(.ant-btn-dangerous) {
      border: none;
      color: ${({ theme }) => theme.colors.green7};
      box-shadow: none;

      :hover {
        color: ${({ theme }) => theme.colors.green8} !important;
      }

      :active {
        color: ${({ theme }) => theme.colors.green8} !important;
      }

      :disabled {
        color: ${({ theme }) => theme.colors.black5} !important;
      }

      &.ant-btn-loading {
        color: ${({ theme }) => theme.colors.green8} !important;
      }
    }

    .ant-btn-text:not(.ant-btn-dangerous):not(.ant-input-search-button) {
      border: none;
      box-shadow: none;

      :hover {
        background: ${({ theme }) => theme.colors.black2} !important;
      }

      :active {
        background: ${({ theme }) => theme.colors.black3} !important;
      }

      :disabled {
        background: ${({ theme }) => theme.colors.black2} !important;
        color: ${({ theme }) => theme.colors.black5} !important;
      }

      &.ant-btn-loading {
        color: ${({ theme }) => theme.colors.black6} !important;
      }
    }

    .ant-btn-ai-primary:not(.ant-btn-dangerous):not(.ant-input-search-button) {
      border: none !important;
      color: ${({ theme }) => theme.colors.white};
      background: linear-gradient(
        308deg, ${({ theme }) => theme.colors.green6} -10.74%,
        ${({ theme }) => theme.colors.green6} 0.39%,
        ${({ theme }) => theme.colors.green6} 35.33%,
        ${({ theme }) => theme.colors.purple6} 90.51%);

      :hover:not(.ant-btn-loading) {
        border: 1px solid ${({ theme }) => theme.colors.green6};
        background: linear-gradient(
        308deg, ${({ theme }) => theme.colors.green7} -10.74%,
        ${({ theme }) => theme.colors.green7} 0.39%,
        ${({ theme }) => theme.colors.green7} 35.33%,
        ${({ theme }) => theme.colors.purple7} 90.51%);
      }

      :active {
        border: 1px solid ${({ theme }) => theme.colors.green7};
        background: linear-gradient(
        308deg, ${({ theme }) => theme.colors.green7} -10.74%,
        ${({ theme }) => theme.colors.green7} 0.39%,
        ${({ theme }) => theme.colors.green7} 35.33%,
        ${({ theme }) => theme.colors.purple7} 90.51%);
      }

      :disabled:not(.ant-btn-loading) {
        border: 1px solid ${({ theme }) => theme.colors.black3} !important;
        color: ${({ theme }) => theme.colors.black6} !important;
        background: linear-gradient(308deg, #DEDEDE -10.74%, #BFBFBF 0.39%, #DEDEDE 35.33%, #BFBFBF 90.51%) !important;
      }

      &.ant-btn-loading {
        border: 1px solid ${({ theme }) => theme.colors.green7};
        background: linear-gradient(
        308deg, ${({ theme }) => theme.colors.green7} -10.74%,
        ${({ theme }) => theme.colors.green7} 0.39%,
        ${({ theme }) => theme.colors.green7} 35.33%,
        ${({ theme }) => theme.colors.purple7} 90.51%);
      }
    }

    .ant-btn-ai-secondary {
      border: 1px solid ${({ theme }) => theme.colors.black3};
      background: linear-gradient(
          308deg, ${({ theme }) => theme.colors.green6} -10.74%,
          ${({ theme }) => theme.colors.green6} 0.39%,
          ${({ theme }) => theme.colors.green6} 35.33%,
          ${({ theme }) => theme.colors.purple6} 90.51%
        );
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      background-clip: text;
      text-fill-color: transparent;
      color: ${({ theme }) => theme.colors.green6};

      :hover:not(.ant-btn-loading) {
        border: 1px solid ${({ theme }) => theme.colors.green4};
      }

      :active:not(:disabled) {
        border: 1px solid ${({ theme }) => theme.colors.green6};
      }

      :disabled:not(.ant-btn-loading) {
        border: 1px solid ${({ theme }) => theme.colors.black3} !important;
        -webkit-text-fill-color: ${({ theme }) => theme.colors.black5} !important;
        background-clip: text !important;
      }
    }

    .ant-btn-ai-link {
      border: none;
      box-shadow: none;
      background: linear-gradient(
          308deg, ${({ theme }) => theme.colors.green6} -10.74%,
          ${({ theme }) => theme.colors.green6} 0.39%,
          ${({ theme }) => theme.colors.green6} 35.33%,
          ${({ theme }) => theme.colors.purple6} 90.51%
        );
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      background-clip: text;
      text-fill-color: transparent;
      color: ${({ theme }) => theme.colors.green6};

      &:hover:not(.ant-btn-loading):not(:disabled) {
        background: linear-gradient(
          308deg, ${({ theme }) => theme.colors.green7} -10.74%,
          ${({ theme }) => theme.colors.green7} 0.39%,
          ${({ theme }) => theme.colors.green7} 35.33%,
          ${({ theme }) => theme.colors.purple7} 90.51%
        );
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text;
        text-fill-color: transparent;
        color: ${({ theme }) => theme.colors.green7};
      }

      :disabled:not(.ant-btn-loading) {
        background: linear-gradient(308deg, #BFBFBF -10.74%, #999 0.39%, #BFBFBF 35.33%, #999 90.51%) !important;
        -webkit-background-clip: text !important;
        -webkit-text-fill-color: transparent !important;
        background-clip: text !important;
        text-fill-color: transparent !important;
      }
    }
    
    

    // FORM LABELS
    .ant-form-item-label > label {
      font-weight: 500 !important;
    }

    // TABLE
    .ant-table-row:hover {
      cursor: pointer;
    }

    .ant-table-pagination {
      margin: 0 !important;
      background-color: ${({ theme }) => theme.colors.white};
      padding: 14px 10px;
      border-bottom-left-radius: 10px;
      border-bottom-right-radius: 10px;
      border-top-style: none;
      box-shadow: 0px 2px 4px rgb(0 0 0 / 20%);
    }

    .ant-table-content {
      overflow-x: auto;
    }

    .ant-table-wrapper > .ant-spin-nested-loading > .ant-spin-container > .ant-table  {
      box-shadow: ${({ theme }) => theme.boxShadow.primary};
    }

    // SWITCH
    .ant-switch-checked:focus {
      box-shadow: none !important;
    }

    // ALERTS
    .ant-alert-with-description .ant-alert-message {
      font-weight: 600;
    }

    // EDITORJS
    .ct.ct--bottom.ct--shown {
      z-index: 1000;
    }

    //EMPTY
    .ant-empty-description {
      color: rgba(0, 0, 0, 0.25) !important;
    }

    // MODAL
    .ant-modal-confirm-btns {
      .ant-btn-primary {
        background: ${({ theme }) => theme.colors.black9};
      }
    }
    .ant-modal-confirm-content {
      width: 100%;
    }

    // TABS
    .ant-tabs-ink-bar.ant-tabs-ink-bar-animated {
      background-color: ${({ theme }) => theme.colors.green6};
    }

    .ant-tabs-tab-btn {
      font-weight: 500;
    }

    // TABLE
    .ant-table-disabled-row {
      opacity: 0.4;
      pointer-events: none;
    }

    .gamification-notification {
      .ant-message-notice-content {
        box-shadow: none !important;
        background: transparent !important;
      }
    }
`;

export { GlobalStyle };

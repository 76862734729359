import { createQueryString } from '@utils';

import { RequestService } from '../request/request.service';

import { ITeacherDetailStats, ITeacherDetailStatsFilters } from './teacher-stats.contract';

export class TeacherStatsService extends RequestService {
  public constructor(apiUrl: string) {
    super(apiUrl);
  }

  public getTeacherDetailStats = async (
    teacherId: string,
    filters?: ITeacherDetailStatsFilters
  ) => {
    const query = createQueryString({
      filters
    });
    return this.fetcher<ITeacherDetailStats>(`/teacher-stats/${teacherId}?${query}`);
  };
}

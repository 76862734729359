import { Tag } from 'antd';
import styled from 'styled-components';

import { Icon } from '../Icon';

type TagProps = {
  $backgroundColor: string;
  $borderColor: string;
};

type IconProps = {
  $color: string;
};

type TextProps = {
  $color: string;
};

export const IconWrapper = styled.div<TagProps>`
  width: fit-content;
  display: flex;
  padding: 2px 8px;
  border-radius: 4px;
  border: 1px solid ${({ $borderColor }) => $borderColor};
  background-color: ${({ $backgroundColor }) => $backgroundColor};
`;

export const StyledTag = styled(Tag)<TagProps>`
  display: flex;
  align-items: center;
  gap: 4px;
  border-radius: 4px;
  border: 1px solid ${({ $borderColor }) => $borderColor};
  background-color: ${({ $backgroundColor }) => $backgroundColor};
`;

export const StyledIcon = styled(Icon)<IconProps>`
  color: ${({ $color }) => $color};
`;

export const TextWrapper = styled.div<TextProps>`
  font-weight: 600;
  color: ${({ $color }) => $color};
`;

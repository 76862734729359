import styled from 'styled-components';

type Props = {
  color: string;
  backgroundColor: string;
};

export const Wrapper = styled.div<Props>`
  display: flex;
  gap: 8px;
  align-items: center;
  padding: 0 8px;
  font-size: 24px;
  font-weight: 600;
  line-height: 32px;
  border-radius: 4px;
  background-color: ${({ backgroundColor }) => backgroundColor};
  color: ${({ color }) => color};

  i {
    cursor: pointer;
  }

  .ant-statistic {
    line-height: 32px !important;
  }

  .ant-statistic-content-value {
    color: ${({ color }) => color} !important;
  }

  @media (min-width: ${({ theme }) => theme.mediaQueries.sm}) {
    gap: 16px;
    padding: 4px 8px;
  }
`;

import { Tag } from 'antd';
import styled from 'styled-components';

export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;
  width: calc(100% - 32px);
`;

export const IssuerWrapper = styled.div`
  font-size: 14px;
  font-weight: 600;
  line-height: 22px;
`;

export const ContentHeaderWrapper = styled.div<{ expanded: string; highlighted: string }>`
  display: flex;
  max-height: ${({ expanded }) => (expanded === 'true' ? 'none' : '280px')};
  padding: 8px;
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;
  align-self: stretch;
  width: 100%;
  position: relative;
  overflow: hidden;
  border-radius: 4px;
  transition: all 0.5s ease-in-out;
  background-color: ${({ highlighted, theme }) =>
    highlighted === 'true' ? theme.colors.green1 : undefined};
  border: ${({ highlighted, theme }) =>
    highlighted === 'true' ? `1px solid ${theme.colors.green6}` : '1px solid transparent'};
  transition: all 0.3s ease-in-out;
`;

export const UserDetailWrapper = styled.div<{ canhover: string }>`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  cursor: ${({ canhover }) => (canhover === 'true' ? 'pointer' : 'default')};

  :hover {
    color: ${({ theme, canhover }) => (canhover === 'true' ? theme.colors.green6 : undefined)};
  }
`;

export const DateWrapper = styled.div`
  font-size: 12px;
  font-weight: 400;
  line-height: 20px;
  color: ${({ theme }) => theme.colors.black5};
`;

export const FileWrapper = styled(Tag)`
  background-color: ${({ theme }) => theme.colors.green1};
  color: ${({ theme }) => theme.colors.green6};
  border: 1px solid ${({ theme }) => theme.colors.green6};
  display: flex;
  align-items: center;
  gap: 4px;
  cursor: pointer;
`;

export const SeeMoreWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
  align-self: stretch;
  cursor: pointer;
  font-size: 12px;
  font-weight: 600;
  line-height: 20px;
  color: ${({ theme }) => theme.colors.black5};
  width: 100%;
`;

export const CommentDivider = styled.div`
  position: absolute;
  bottom: 0;
  height: 40px;
  width: 100%;
  background: linear-gradient(
    180deg,
    rgba(248, 248, 248, 0) 0%,
    ${({ theme }) => theme.colors.black1} 100%
  );
`;

export const FooterWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  align-self: stretch;
  width: 100%;
`;

export const ActionsWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
`;

export const AnswerWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
  font-size: 12px;
  font-weight: 600;
  line-height: 20px;
  cursor: pointer;
  margin-bottom: 8px;

  :hover {
    color: ${({ theme }) => theme.colors.green6};
  }
`;

export const DescriptionWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 4px;
  & > p {
    margin: 0;
  }

  img {
    width: 100%;
    height: 100%;
    max-width: 100%;
    object-fit: cover;
    background-color: ${({ theme }) => theme.colors.black1};
  }
`;

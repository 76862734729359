import { dateProvider } from '@utils';
import { Flex, Tooltip as AntdTooltip } from 'antd';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
} from 'chart.js';
import { useMemo } from 'react';
import { Line } from 'react-chartjs-2';
import { useTranslation } from 'react-i18next';
import { useTheme } from 'styled-components';

import { LegendElement } from '../LegendElement';

import {
  DotWrapper,
  LegendTitleWrapper,
  LineWrapper,
  UscoreValueWrapper,
  Wrapper
} from './TeacherStats.styles';

import { Icon } from '@/components/Icon';
import { useTeacherDetailStats } from '@/store';

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

const options = {
  scales: {
    y: {
      min: 0,
      max: 5,
      ticks: {
        stepSize: 1
      }
    }
  },
  plugins: {
    legend: {
      display: false
    }
  }
};

export const TeacherStats = () => {
  const { t } = useTranslation('ManagerTeacherDetailTranslations');
  const { colors } = useTheme();
  const { teacherDetailStats } = useTeacherDetailStats();

  const data = useMemo(() => {
    if (!teacherDetailStats) return null;
    const { stats } = teacherDetailStats;

    const months = stats.map(stat => dateProvider.format(stat.createdAt, 'MMM'));
    const uscoreData = stats.map(stat => stat.uscore.value ?? 0);
    const studentsRatingData = stats.map(stat => stat.studentsRating.value ?? 0);
    const liveClassesVotesRatingData = stats.map(
      stat => stat.liveClassVoteRating.value?.value ?? 0
    );
    const activitiesRatingData = stats.map(stat => stat.activitiesRating.value?.value ?? 0);

    return {
      labels: months,
      datasets: [
        {
          label: t('USCORE'),
          data: uscoreData,
          borderColor: colors.green6,
          backgroundColor: colors.green6
        },
        {
          label: t('STUDENT_RATING'),
          data: studentsRatingData,
          borderColor: colors.purple6,
          backgroundColor: colors.purple6
        },
        {
          label: t('LIVE_CLASS_VOTE_RATING'),
          data: liveClassesVotesRatingData,
          borderColor: colors.orange5,
          backgroundColor: colors.orange5
        },
        {
          label: t('LESSON_RATING'),
          data: activitiesRatingData,
          borderColor: colors.blue4,
          backgroundColor: colors.blue4
        }
      ]
    };
  }, [teacherDetailStats]);

  const lastStat = useMemo(() => {
    if (!teacherDetailStats || teacherDetailStats.stats.length === 0) return null;
    const statsWithScore = teacherDetailStats.stats.filter(stat => stat.uscore.value > 0);
    return statsWithScore[statsWithScore.length - 1];
  }, [teacherDetailStats]);

  if (!data || !teacherDetailStats) return null;

  const { uscore, studentsRating, liveClassVoteRating, activitiesRating } = lastStat ?? {};

  return (
    <Wrapper>
      <Flex flex={1} vertical gap={24} style={{ maxWidth: 400 }}>
        <Flex vertical gap={4}>
          <Flex gap={8} align="center">
            <LegendTitleWrapper>{t('CURRENT_USCORE')}</LegendTitleWrapper>
            <AntdTooltip trigger="hover" title={t('USCORE_TOOLTIP')}>
              <div>
                <Icon i="Information" color="black5" />
              </div>
            </AntdTooltip>
          </Flex>
          <Flex gap={8} align="center">
            <DotWrapper />
            <Flex gap={4} align="center">
              <UscoreValueWrapper trend={uscore?.trend}>
                {uscore && uscore.value > 0 ? uscore.value.toFixed(2) : '-'}
              </UscoreValueWrapper>
              {uscore && uscore?.trend !== 'equal' && (
                <Icon
                  i={uscore?.trend === 'up' ? 'CaretUp' : 'CaretDown'}
                  color={uscore?.trend === 'up' ? 'green6' : 'red6'}
                />
              )}
            </Flex>
          </Flex>
        </Flex>
        <LineWrapper />
        <Flex vertical gap={16}>
          <LegendElement
            stat={studentsRating}
            title={t('STUDENT_RATING')}
            color={colors.purple6}
            tooltip={t('STUDENT_RATING_TOOLTIP')}
          />
          <LegendElement
            stat={liveClassVoteRating?.value}
            title={t('LIVE_CLASS_VOTE_RATING')}
            color={colors.orange5}
            tooltip={t('LIVE_CLASS_VOTE_RATING_TOOLTIP')}
          />
          <LegendElement
            stat={activitiesRating?.value}
            title={t('LESSON_RATING')}
            color={colors.blue4}
            tooltip={t('LESSON_RATING_TOOLTIP')}
          />
        </Flex>
      </Flex>
      <Flex flex={1} style={{ maxWidth: 800 }}>
        <Line options={options} data={data} />
      </Flex>
    </Wrapper>
  );
};

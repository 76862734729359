/* eslint-disable @typescript-eslint/no-explicit-any */
import { encodeQueryParams } from './encodeQueryParams';

interface IQueryParams {
  search?: string;
  limit?: number;
  page?: number;
  bypass?: boolean;
  filters?: Record<string, any>;
  sort?: Record<string, any>;
}

const DEFAULT_LIMIT = 50;
const DEFAULT_PAGE = 1;

export const createQueryString = (params: IQueryParams): string => {
  const { search = '', limit = DEFAULT_LIMIT, page = DEFAULT_PAGE, bypass, filters, sort } = params;

  let queryString = new URLSearchParams({
    limit: bypass ? '' : limit.toString(),
    page: page.toString(),
    search
  }).toString();

  const filtersArray = Object.entries(filters ?? {}).filter(([_, value]) => value !== undefined);
  if (filtersArray.length > 0)
    queryString += `&${encodeQueryParams(Object.fromEntries(filtersArray))}`;
  if (sort) queryString += `&${encodeQueryParams(sort)}`;

  return queryString;
};

import { IAnswer, IQuestion, IQuizConfig, IValidatedQuestion } from '@sdk/contracts';
import { differenceBy } from 'lodash';
import { create } from 'zustand';

const DEFAULT_ANSWER = { text: '' };

interface IStore {
  isConfigOpen: boolean;
  setConfigOpen: (isConfigOpen: boolean) => void;
  config: IQuizConfig;
  setConfig: (config?: IQuizConfig) => void;
  questions: IValidatedQuestion[];
  addQuestion: (question: IValidatedQuestion) => void;
  updateQuestion: <T extends keyof IQuestion>(field: T, value: IQuestion[T], index: number) => void;
  removeQuestion: (questionIndex: number) => void;
  setQuestions: (questions: IValidatedQuestion[]) => void;
  resetQuestions: () => void;
  addAnswer: (questionIndex: number) => void;
  updateAnswer: <T extends keyof IAnswer>(
    questionIndex: number,
    field: T,
    value: IAnswer[T],
    answerIndex: number
  ) => void;
  removeAnswer: (questionIndex: number, answerIndex: number) => void;
  isQuestionBankOpen: boolean;
  setIsQuestionBankOpen: (isQuestionBankOpen: boolean) => void;
  addQuestionsFromQuestionBank: (questions: IQuestion[]) => void;
  resetConfig: () => void;
  updateQuestionByIndex: (questionIndex: number, question: IValidatedQuestion) => void;
}

export const useQuizForm = create<IStore>(set => ({
  isConfigOpen: true,
  setConfigOpen: (isConfigOpen: boolean) => set({ isConfigOpen }),
  config: {} as IQuizConfig,
  setConfig: (config?: IQuizConfig) => set({ config }),
  questions: [],
  addQuestion: (question: IValidatedQuestion) =>
    set(state => ({ ...state, questions: [...state.questions, question] })),
  updateQuestion: <T extends keyof IQuestion>(field: T, value: IQuestion[T], index: number) =>
    set(state => ({
      ...state,
      questions: state.questions.map((q, idx) => (idx === index ? { ...q, [field]: value } : q))
    })),
  removeQuestion: (questionIndex: number) =>
    set(state => ({
      ...state,
      questions: state.questions.filter((_, idx) => idx !== questionIndex)
    })),
  setQuestions: questions => set({ questions }),
  resetQuestions: () => set({ questions: [] }),
  addAnswer: (questionIndex: number) =>
    set(state => ({
      ...state,
      questions: state.questions.map((q, idx) =>
        idx === questionIndex
          ? { ...q, answers: q.answers ? [...q.answers, DEFAULT_ANSWER] : [DEFAULT_ANSWER] }
          : q
      )
    })),
  updateAnswer: <T extends keyof IAnswer>(
    questionIndex: number,
    field: T,
    value: IAnswer[T],
    answerIndex: number
  ) =>
    set(state => ({
      ...state,
      questions: state.questions.map((q, idx) =>
        idx === questionIndex
          ? {
              ...q,
              answers: q.answers?.map((a, aIdx) =>
                aIdx === answerIndex ? { ...a, [field]: value } : a
              )
            }
          : q
      )
    })),
  removeAnswer: (questionIndex: number, answerIndex: number) =>
    set(state => ({
      ...state,
      questions: state.questions.map((q, idx) =>
        idx === questionIndex
          ? { ...q, answers: q.answers?.filter((_, aIdx) => aIdx !== answerIndex) }
          : q
      )
    })),
  isQuestionBankOpen: false,
  setIsQuestionBankOpen: (isQuestionBankOpen: boolean) => set({ isQuestionBankOpen }),
  addQuestionsFromQuestionBank: (questions: IQuestion[]) =>
    set(state => ({
      ...state,
      questions: [...state.questions, ...differenceBy(questions, state.questions, '_id')],
      isQuestionBankOpen: false
    })),
  resetConfig: () => set({ config: undefined, isConfigOpen: true }),
  updateQuestionByIndex: (questionIndex: number, question: IValidatedQuestion) =>
    set(state => ({
      ...state,
      questions: state.questions.map((q, idx) =>
        idx === questionIndex ? { ...q, ...question } : q
      )
    }))
}));

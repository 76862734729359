export const CommonQuizFormTranslations = {
  SAVE: {
    es: 'Guardar',
    en: 'Save',
    cat: 'Desa',
    eus: 'Gorde',
    pt: 'Guardar'
  },
  CREATE_TEST: {
    es: 'Crear test',
    en: 'Create test',
    cat: 'Crear test',
    eus: 'Testa sortu',
    pt: 'Criar teste'
  },
  EDIT_TEST: {
    es: 'Editar test',
    en: 'Edit test',
    cat: 'Editar test',
    eus: 'Testa editatu',
    pt: 'Editar teste'
  },
  IMPORT_TEST: {
    es: 'Importar test',
    en: 'Import test',
    cat: 'Importar test',
    eus: 'Testa inportatu',
    pt: 'Importar teste'
  },
  TITLE: {
    es: 'Título',
    en: 'Title',
    cat: 'Títol',
    eus: 'Izenburua',
    pt: 'Título'
  },
  QUIZ_TITLE: {
    es: 'Título del test',
    en: 'Quiz title',
    cat: 'Títol del test',
    eus: 'Testaren izenburua',
    pt: 'Título do teste'
  },
  TAGS: {
    es: 'Etiquetas',
    en: 'Tags',
    cat: 'Etiquetes',
    eus: 'Etiketak',
    pt: 'Etiquetas'
  },
  TIMER: {
    es: 'Temporizador',
    en: 'Timer',
    cat: 'Temporitzador',
    eus: 'Denboragailua',
    pt: 'Cronómetro'
  },
  SELECT_QUESTIONS: {
    es: 'Seleccionar preguntas',
    en: 'Select questions',
    cat: 'Seleccionar preguntes',
    eus: 'Galderak hautatu',
    pt: 'Selecionar questões'
  },
  QUESTIONS_TOTAL_COUNT: {
    es: '{{total}} preguntas',
    en: '{{total}} questions',
    cat: '{{total}} preguntes',
    eus: '{{total}} galdera',
    pt: '{{total}} questões'
  },
  QUESTIONS_TOTAL_SELECTED: {
    es: '{{total}} preguntas seleccionadas',
    en: '{{total}} questions selected',
    cat: '{{total}} preguntes seleccionades',
    eus: '{{total}} galdera hautatuak',
    pt: '{{total}} questões selecionadas'
  },
  QUESTIONS: {
    es: 'Preguntas',
    en: 'Questions',
    cat: 'Preguntes',
    eus: 'Galderak',
    pt: 'Questões'
  },
  QUIZ_CREATED: {
    es: 'Test creado',
    en: 'Test created',
    cat: 'Test creat',
    eus: 'Testa sortua',
    pt: 'Teste criado'
  },
  QUIZ_UPDATED: {
    es: 'Test actualizado',
    en: 'Test updated',
    cat: 'Test actualitzat',
    eus: 'Testa eguneratua',
    pt: 'Teste atualizado'
  },
  FAMILY: {
    es: 'Familia',
    en: 'Family',
    cat: 'Família',
    eus: 'Familia',
    pt: 'Família'
  },
  USE_THE_FILTER_TO_SELECT_QUESTIONS: {
    es: 'Usa el filtro para seleccionar preguntas',
    en: 'Use the filter to select questions',
    cat: 'Utilitza el filtre per seleccionar preguntes',
    eus: 'Erabili iragazkia galderak hautatzeko',
    pt: 'Utilize o filtro para selecionar questões'
  },
  NO_QUESTIONS_FOUND: {
    es: 'No se encontraron preguntas',
    en: 'No questions found',
    cat: 'No s`han trobat preguntes',
    eus: 'Ez da galderarik aurkitu',
    pt: 'Nenhuma questão encontrada'
  },
  TEST_CONFIGURATION: {
    es: 'Configuración del test',
    en: 'Test configuration',
    cat: 'Configuració del test',
    eus: 'Testaren konfigurazioa',
    pt: 'Configuração do teste'
  },
  CANCEL: {
    es: 'Cancelar',
    en: 'Cancel',
    cat: 'Cancel·lar',
    eus: 'Utzi',
    pt: 'Cancelar'
  },
  DISCARD: {
    es: 'Descartar',
    en: 'Discard',
    cat: 'Descartar',
    eus: 'Ezabatu',
    pt: 'Descartar'
  },
  CANCEL_CONFIRMATION: {
    es: '¿Seguro que quieres descartar el test?',
    en: 'Are you sure you want to discard the test?',
    cat: 'Estàs segur que vols descartar el test?',
    eus: 'Ziur zaude testa baztertu nahi duzula?',
    pt: 'Tem certeza que deseja descartar o teste?'
  },
  QUIZ_MODE: {
    es: 'Tipo',
    en: 'Mode',
    cat: 'Tipus',
    eus: 'Mota',
    pt: 'Modo'
  },
  SAVE_QUIZ: {
    es: 'Guardar test',
    en: 'Save test',
    cat: 'Desar test',
    eus: 'Testa gorde',
    pt: 'Guardar teste'
  },
  ADD_QUESTION: {
    es: 'Añadir pregunta',
    en: 'Add question',
    cat: 'Afegir pregunta',
    eus: 'Galdera gehitu',
    pt: 'Adicionar questão'
  },
  QUESTION_TITLE: {
    es: 'Enunciado de la pregunta',
    en: 'Question title',
    cat: 'Enunciat de la pregunta',
    eus: 'Galderaren enuntziatua',
    pt: 'Título da questão'
  },
  ACTIONS: {
    es: 'Acciones',
    en: 'Actions',
    cat: 'Accions',
    eus: 'Ekintzak',
    pt: 'Ações'
  },
  QUESTION_TITLE_PLACEHOLDER: {
    es: 'Introduce el título de la pregunta',
    en: 'Enter the question title',
    cat: 'Introdueix el títol de la pregunta',
    eus: 'Sartu galderaren izenburua',
    pt: 'Introduza o título da questão'
  },
  INSERT_FORMULA: {
    es: 'Insertar fórmula',
    en: 'Insert formula',
    cat: 'Inserir fórmula',
    eus: 'Txertatu formula',
    pt: 'Inserir fórmula'
  },
  INSERT_IMAGE: {
    es: 'Insertar imagen',
    en: 'Insert image',
    cat: 'Inserir imatge',
    eus: 'Txertatu irudia',
    pt: 'Inserir imagem'
  },
  QUESTION_DELETE_CONFIRMATION: {
    es: '¿Seguro que quieres borrar la pregunta?',
    en: 'Are you sure you want to delete the question?',
    cat: 'Estàs segur que vols esborrar la pregunta?',
    eus: 'Ziur zaude galdera ezabatu nahi duzula?',
    pt: 'Tem certeza que deseja eliminar a questão?'
  },
  ADD_ANSWER: {
    es: 'Añadir respuesta',
    en: 'Add answer',
    cat: 'Afegir resposta',
    eus: 'Erantzun gehitu',
    pt: 'Adicionar resposta'
  },
  ANSWER_DELETE_CONFIRMATION: {
    es: '¿Seguro que quieres borrar la respuesta?',
    en: 'Are you sure you want to delete the answer?',
    cat: 'Estàs segur que vols esborrar la resposta?',
    eus: 'Ziur zaude erantzuna ezabatu nahi duzula?',
    pt: 'Tem certeza que deseja eliminar a resposta?'
  },
  ANSWER_TITLE_PLACEHOLDER: {
    es: 'Introduce el título de la respuesta',
    en: 'Enter the answer title',
    cat: 'Introdueix el títol de la resposta',
    eus: 'Sartu erantzunaren izenburua',
    pt: 'Introduza o título da resposta'
  },
  QUESTION_COUNT: {
    es: '{{count}} pregunta(s)',
    en: '{{count}} question(s)',
    cat: '{{count}} pregunta(es)',
    eus: '{{count}} galdera(k)',
    pt: '{{count}} questão(ões)'
  },
  CREATE_QUESTION: {
    es: 'Crear pregunta',
    en: 'Create question',
    cat: 'Crear pregunta',
    eus: 'Galdera sortu',
    pt: 'Criar questão'
  },
  ADD_FROM_QUESTION_BANK: {
    es: 'Añadir desde banco de preguntas',
    en: 'Add from question bank',
    cat: 'Afegir des del banc de preguntes',
    eus: 'Galdera bankutik gehitu',
    pt: 'Adicionar do banco de questões'
  },
  CONFIGURATION: {
    es: 'Configuración',
    en: 'Configuration',
    cat: 'Configuració',
    eus: 'Konfigurazioa',
    pt: 'Configuração'
  },
  UPDATE_QUESTION: {
    es: 'Actualizar pregunta',
    en: 'Update question',
    cat: 'Actualitzar pregunta',
    eus: 'Eguneratu galdera',
    pt: 'Atualizar questão'
  },
  SELECT_FROM_QUESTION_BANK: {
    es: 'Seleccionar preguntas',
    en: 'Select questions',
    cat: 'Barrejar preguntes',
    eus: 'Hautatu galderak',
    pt: 'Selecionar questões'
  },
  NO_QUESTIONS_ADDED: {
    es: 'No se han añadido preguntas.',
    en: 'No questions added.',
    cat: 'No s`han afegit preguntes.',
    eus: 'Galderarik ez da gehitu.',
    pt: 'Nenhuma questão adicionada.'
  },
  QUESTION: {
    es: 'Pregunta',
    en: 'Question',
    cat: 'Pregunta',
    eus: 'Galdera',
    pt: 'Questão'
  },
  YEAR: {
    es: 'Año',
    en: 'Year',
    cat: 'Any',
    eus: 'Urtea',
    pt: 'Ano'
  },
  DIFFICULTY: {
    es: 'Dificultad',
    en: 'Difficulty',
    cat: 'Dificultat',
    eus: 'Zailtasuna',
    pt: 'Dificuldade'
  },
  ANSWERS: {
    es: 'Respuestas',
    en: 'Answers',
    cat: 'Respostes',
    eus: 'Erantzunak',
    pt: 'Respostas'
  },
  EXPLANATION: {
    es: 'Explicación',
    en: 'Explanation',
    cat: 'Explicació',
    eus: 'Azalpena',
    pt: 'Explicação'
  },
  SELECT_IMAGE: {
    es: 'Seleccionar imagen',
    en: 'Select image',
    cat: 'Seleccionar imatge',
    eus: 'Irudi bat hautatu',
    pt: 'Selecionar imagem'
  },
  EASY: {
    es: 'Fácil',
    en: 'Easy',
    cat: 'Fàcil',
    eus: 'Erraza',
    pt: 'Fácil'
  },
  MEDIUM: {
    es: 'Medio',
    en: 'Medium',
    cat: 'Mitjà',
    eus: 'Ertaina',
    pt: 'Médio'
  },
  HARD: {
    es: 'Difícil',
    en: 'Hard',
    cat: 'Difícil',
    eus: 'Zaila',
    pt: 'Difícil'
  },
  QUESTION_CREATED: {
    es: 'Pregunta creada.',
    en: 'Question created.',
    cat: 'Pregunta creada.',
    eus: 'Galdera sortua.',
    pt: 'Questão criada.'
  },
  QUESTION_UPDATED: {
    es: 'Pregunta actualizada.',
    en: 'Question updated.',
    cat: 'Pregunta actualitzada.',
    eus: 'Galdera eguneratua.',
    pt: 'Questão atualizada.'
  },
  QUESTION_DELETED: {
    es: 'Pregunta eliminada.',
    en: 'Question deleted.',
    cat: 'Pregunta eliminada.',
    eus: 'Galdera ezabatua.',
    pt: 'Questão eliminada.'
  },
  SEARCH_PLACEHOLDER: {
    es: 'Buscar',
    en: 'Search',
    cat: 'Cerca',
    eus: 'Bilatu',
    pt: 'Pesquisar'
  },
  EDIT_QUESTION: {
    es: 'Editar pregunta',
    en: 'Edit question',
    cat: 'Editar pregunta',
    eus: 'Editatu galdera',
    pt: 'Editar questão'
  },
  DELETE_QUESTION: {
    es: 'Eliminar pregunta',
    en: 'Delete question',
    cat: 'Eliminar pregunta',
    eus: 'Galdera ezabatu',
    pt: 'Eliminar questão'
  },
  DELETE_QUESTION_CONFIRMATION: {
    es: '¿Estás seguro de eliminar esta pregunta?',
    en: 'Are you sure you want to delete this question?',
    cat: 'Segur que vols eliminar aquesta pregunta?',
    eus: 'Zihur zaude galdera hau ezabatu nahi duzula?',
    pt: 'Tem certeza que deseja eliminar a questão?'
  },
  DELETE_QUESTION_SUCCESS: {
    es: 'Pregunta eliminada con éxito',
    en: 'Question deleted successfully',
    cat: 'Pregunta eliminada amb èxit',
    eus: 'Galdera ongi ezabatu da',
    pt: 'Questão eliminada com sucesso'
  },
  DELETE_QUESTIONS_SUCCESS: {
    es: 'Preguntas eliminadas con éxito',
    en: 'Questions deleted successfully',
    cat: 'Preguntes eliminades amb èxit',
    eus: 'Galderak ongi ezabatu dira',
    pt: 'Questões eliminadas com sucesso'
  },
  WRITE_HERE_THE_ANSWER: {
    es: 'Escribe aquí la respuesta',
    en: 'Write here the answer',
    cat: 'Escriu aquí la resposta',
    eus: 'Idatzi hemen erantzuna',
    pt: 'Escreva aqui a resposta'
  },
  ARE_YOU_SURE_TO_DELETE_ANSWER: {
    es: '¿Estás seguro de eliminar esta respuesta?',
    en: 'Are you sure to delete this answer?',
    cat: 'Segur que vols eliminar aquesta resposta?',
    eus: 'Zihur zaude erantzun hau ezabatu nahi duzula?',
    pt: 'Tem certeza que deseja eliminar a resposta?'
  },
  ADD_NEW_EMPTY_ANSWER: {
    es: 'Agregar respuesta',
    en: 'Add new response',
    cat: 'Afegir resposta nova',
    eus: 'Erantzun berria gehitu',
    pt: 'Adicionar resposta nova'
  },
  COURSE: {
    es: 'Curso',
    en: 'Course',
    cat: 'Curs',
    eus: 'Kurtsua',
    pt: 'Curso'
  },
  SECTION: {
    es: 'Tema',
    en: 'Section',
    cat: 'Tema',
    eus: 'Gaia',
    pt: 'Tema'
  },
  MANUAL: {
    es: 'Generar preguntas de forma Manual',
    en: 'Generate questions manually',
    cat: 'Generar preguntes de forma manual',
    eus: 'Galderak eskuz sortu',
    pt: 'Gerar questões manualmente'
  },
  UPLOAD_DOCUMENT: {
    es: 'Sube un archivo',
    en: 'Upload document',
    cat: 'Pujar un arxiu',
    eus: 'Artxiboa igo',
    pt: 'Carregar documento'
  },
  BACK: {
    es: 'Atrás',
    en: 'Back',
    cat: 'Enrere',
    eus: 'Atzera',
    pt: 'Voltar'
  },
  QUESTIONS_PROCESSED_SUCCESSFULLY: {
    es: 'Preguntas procesadas correctamente',
    en: 'Questions processed successfully',
    cat: 'Preguntes processades correctament',
    eus: 'Galderak ongi prozesatu dira',
    pt: 'Questões processadas com sucesso'
  },
  QUESTION_TYPE: {
    es: 'Tipo de pregunta',
    en: 'Question type',
    cat: 'Tipus de pregunta',
    eus: 'Galdera mota',
    pt: 'Tipo de questão'
  },
  TYPE_AI: {
    es: 'IA',
    en: 'AI',
    cat: 'IA',
    eus: 'IA',
    pt: 'IA'
  },
  TYPE_MANUAL: {
    es: 'Manual',
    en: 'Manual',
    cat: 'Manual',
    eus: 'Eskuzkoa',
    pt: 'Manual'
  },
  CONTINUE: {
    es: 'Continuar',
    en: 'Continue',
    cat: 'Continua',
    eus: 'Jarraitu',
    pt: 'Continuar'
  },
  PREVIEW: {
    es: 'Vista previa',
    en: 'Preview',
    cat: 'Vista prèvia',
    eus: 'Aurrebista',
    pt: 'Pré-visualização'
  },
  SELECT_QUESTION: {
    es: 'Selecciona una pregunta',
    en: 'Select a question',
    cat: 'Selecciona una pregunta',
    eus: 'Galdera bat hautatu',
    pt: 'Selecionar questão'
  },
  SEARCH_A_QUESTION_TO_FILTER: {
    es: 'Busca una pregunta para filtrar',
    en: 'Search a question to filter',
    cat: 'Cerca una pregunta per filtrar',
    eus: 'Bilatu galdera bat iragazteko',
    pt: 'Pesquisar uma questão para filtrar'
  },
  FILTERS: {
    es: 'Filtros',
    en: 'Filters',
    cat: 'Filtres',
    eus: 'Iragazkiak',
    pt: 'Filtros'
  },
  INSERT_MEDIA: {
    es: 'Insertar recurso',
    en: 'Insert media',
    cat: 'Inserir recurs',
    eus: 'Baliabidea txertatu',
    pt: 'Inserir recurso'
  },
  ACTIVITY_MATERIAL: {
    es: 'Material en actividades',
    en: 'Activity material',
    cat: 'Material en activitats',
    eus: 'Materiala jardueretan',
    pt: 'Material em atividades'
  },
  RELATED_CONTENT: {
    es: 'Contenido relacionado',
    en: 'Related content',
    cat: 'Contingut relacionat',
    eus: 'Eduki lotura',
    pt: 'Conteúdo relacionado'
  },
  UPDATE_QUESTION_WARNING: {
    es: 'AVISO: Si modificas esta pregunta, afectará a todos los tests en los que esté',
    en: 'WARNING: If you modify this question, it will affect all the tests in which it is',
    cat: 'AVÍS: Si modifiques aquesta pregunta, afectarà a tots els tests en els que estigui',
    eus: 'KONTUZ: Galdera hau aldatzen baduzu, test guztietan eragina izango du',
    pt: 'AVISO: Se modificar esta questão, afetará todos os testes em que está'
  },
  COURSES: {
    es: 'Cursos',
    en: 'Courses',
    cat: 'Cursos',
    eus: 'Kurtsoak',
    pt: 'Cursos'
  },
  SECTIONS: {
    es: 'Temas',
    en: 'Sections',
    cat: 'Temes',
    eus: 'Gaiei',
    pt: 'Temas'
  },
  QUIZZES_CONTAINING_QUESTION: {
    es: 'Tests que contienen esta pregunta',
    en: 'Quizzes containing this question',
    cat: 'Tests que contenen aquesta pregunta',
    eus: 'Galdera hau duten testak',
    pt: 'Testes que contêm esta questão'
  },
  MAX_RETRIES: {
    es: 'Máximo de intentos',
    en: 'Max attempts',
    cat: 'Màxim d`intents',
    eus: 'Saio kopurua',
    pt: 'Número de tentativas'
  },
  EXPLANATION_TOOLTIP: {
    es: 'Añade una explicación para que el alumno comprenda la respuesta correcta.',
    en: 'Add an explanation so that the student understands the correct answer.',
    cat: 'Afegeix una explicació perquè l`alumne entengui la resposta correcta.',
    eus: 'Azalpena gehitu ikasleak zuzena ulertzeko.',
    pt: 'Adicione uma explicação para que o aluno compreenda a resposta correta.'
  },
  GENERATE_WITH_AI: {
    es: 'Generar con IA',
    en: 'Generate with AI',
    cat: 'Generar amb IA',
    eus: 'IA erabiliz sortu',
    pt: 'Gerar com IA'
  },
  NUMBER_OF_QUESTIONS: {
    es: 'Número de preguntas',
    en: 'Number of questions',
    cat: 'Número de preguntes',
    eus: 'Galdera kopurua',
    pt: 'Número de questões'
  },
  AI_MODE_DISABLED_TOOLTIP: {
    es: 'Elige el modo libre o modo actividad y completa los campos necesarios',
    en: 'Choose free mode or activity mode and complete the required fields',
    cat: 'Escull el mode lliure o mode d`activitat i completa els camps necessaris',
    eus: 'Ezabatu aite moduan eta beharrezko eremuak bete',
    pt: 'Escolha o modo livre ou modo de atividade e complete os campos necessários'
  },
  CHOOSE_FILES: {
    es: 'Selecciona los archivos',
    en: 'Select the files',
    cat: 'Selecciona els arxius',
    eus: 'Hautatu fitxategiak',
    pt: 'Selecione os ficheiros'
  },
  GENERATE: {
    es: 'Generar',
    en: 'Generate',
    cat: 'Generar',
    eus: 'Sortu',
    pt: 'Gerar'
  },
  GENERATING_QUESTIONS: {
    es: 'Generando preguntas, tardará alrededor de 1 minuto',
    en: 'Generating questions, it will take around 1 minute',
    cat: 'Generant preguntes, trigarà al voltant de 1 minut',
    eus: 'Galderak sortzen, 1 minutuko hartuko du',
    pt: 'Gerando questões, levará cerca de 1 minuto'
  },
  VALIDATE_QUESTIONS: {
    es: 'Tienes preguntas sin validar',
    en: 'You have questions to validate',
    cat: 'Tens preguntes per validar',
    eus: 'Galderak egiaztatu behar dituzu',
    pt: 'Tem questões para validar'
  },
  NOT_VALIDATED_QUESTIONS: {
    es: 'Tienes <a>{{count}}</a> preguntas no validadas',
    en: 'You have <a>{{count}}</a> questions not validated',
    cat: 'Tens <a>{{count}}</a> preguntes no validades',
    eus: 'Ezabatu aite moduan eta beharrezko eremuak bete',
    pt: 'Tem <a>{{count}}</a> questões não validadas'
  },
  VALIDATE_WITH_AI: {
    es: 'Validar con IA',
    en: 'Validate with AI',
    cat: 'Validar amb IA',
    eus: 'Egiaztatu IA erabiliz',
    pt: 'Validar com IA'
  },
  SET_VALIDATED: {
    es: 'Marcar como validada',
    en: 'Mark as validated',
    cat: 'Marca com a validada',
    eus: 'Egiaztatu',
    pt: 'Marcar como validada'
  },
  QUESTIONS_VALIDATED_SUCCESSFULLY: {
    es: 'Explicaciones actualizadas correctamente',
    en: 'Explanations updated successfully',
    cat: 'Explicacions actualitzades correctament',
    eus: 'Azalpenak ongi eguneratu dira',
    pt: 'Explicações atualizadas com sucesso'
  }
};

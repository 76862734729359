import { Button } from 'antd';
import { useTranslation } from 'react-i18next';

import { ColumnWrapper, TextWrapper, TitleWrapper, Wrapper } from '../NpsVote.styles';

import { useNpsIsPromoter } from '@/hooks';

const TRUSTPILOT_URL = 'https://www.trustpilot.com/review/ucademy.com';

export const PostNpsActions: React.FC = () => {
  const { t } = useTranslation('CommonDashboardTranslations');
  const { isPromoter } = useNpsIsPromoter();
  const handleOpenTrustpilot = () => window.open(TRUSTPILOT_URL, '_blank');

  return (
    <Wrapper>
      {
        // TODO: Bad case of NPS
        isPromoter ? (
          <ColumnWrapper>
            <TitleWrapper>{t('CAN_YOU_WRITE_A_REVIEW')}</TitleWrapper>
            <Button onClick={handleOpenTrustpilot}>{t('CLICK_HERE')}</Button>
          </ColumnWrapper>
        ) : (
          <TextWrapper>{t('NPS_THANK_YOU')}</TextWrapper>
        )
      }
    </Wrapper>
  );
};

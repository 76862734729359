import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 48px;
  width: 90vw;

  @media (min-width: ${({ theme }) => theme.mediaQueries.xs}) {
    width: 500px;
  }

  @media (min-width: ${({ theme }) => theme.mediaQueries.sm}) {
    width: 700px;
  }

  .ant-picker {
    width: 100%;
  }

  .ant-table-tbody > tr > td {
    padding: 0;
  }
`;

export const HeaderWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  align-self: stretch;

  & > h2 {
    margin: 0;
  }
`;

export const ItemsWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 24px;

  .ant-select {
    min-width: 100px;
  }
`;

export const ItemWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;

  & :last-child {
    flex: none;
  }
`;

import Lottie from 'lottie-react';
import styled from 'styled-components';

export const StyledH1 = styled.h1`
  text-align: center;
  max-width: 240px;
  overflow-wrap: break-word;
  align-self: center;
  @media (min-width: ${({ theme }) => theme.mediaQueries.xs}) {
    max-width: 500px;
  }
`;

export const TextWrapper = styled.div`
  text-align: center;
  text-justify: center;
`;

export const LottieWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  margin: 24px auto;
`;

export const StyledLottie = styled(Lottie)`
  width: 150px;
  height: 150px;

  @media (min-width: ${({ theme }) => theme.mediaQueries.xs}) {
    width: 150px;
    height: 230px;
    top: -44px;
  }

  @media (min-width: ${({ theme }) => theme.mediaQueries.md}) {
    width: 120px;
    height: 120px;
    top: -24px;
  }
`;

import { Button } from 'antd';
import styled, { css } from 'styled-components';

type ButtonProps = {
  step: number;
};

export const CurvedArrowVector = styled.img`
  position: absolute;
  bottom: 20px;
  left: -90px;
`;

export const StraightRightArrowVector = styled.img`
  position: absolute;
  bottom: -50px;
  left: -120px;
`;

export const StraightLeftArrowVector = styled.img`
  position: absolute;
  left: 140px;
`;

const getContentJustify = (step: number) => {
  switch (step) {
    case 0:
    case 12:
      return 'center';
    case 13:
      return 'flex-start';
    default:
      return 'space-between';
  }
};

export const Wrapper = styled.div<ButtonProps>(
  ({ step }) => css`
    position: relative;
    display: flex;
    width: 100%;
    align-items: center;
    padding: 0 16px 16px 16px;
    align-self: flex-start;
    flex-direction: row;
    justify-content: ${step > 7 && step < 12 ? 'space-between' : 'center'};
    gap: 16px;

    @media (min-width: ${({ theme }) => theme.mediaQueries.xs}) {
      margin-top: 16px;
      flex-direction: row;
      align-items: center;
      justify-content: ${getContentJustify(step)};
    }

    @media (min-width: ${({ theme }) => theme.mediaQueries.md}) {
      align-self: flex-end;
    }
  `
);

export const ButtonsWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 16px;
  align-items: center;
  width: 100%;
`;

export const StyledUmyImg1 = styled.img`
  position: absolute;
  bottom: -400px;
  right: -350px;
  transition: bottom 0.5s ease-in-out;

  @media (min-width: ${({ theme }) => theme.mediaQueries.sm}) {
    right: -280px;
  }
`;

export const StyledUmyImg2 = styled.img`
  position: absolute;
  bottom: -400px;
  left: -350px;
  transition: bottom 0.5s ease-in-out;
  height: 320px;

  @media (min-width: ${({ theme }) => theme.mediaQueries.sm}) {
    left: -220px;
  }
`;

export const StyledUmyImg3 = styled.img`
  position: absolute;
  bottom: -400px;
  left: -350px;
  transition: bottom 0.5s ease-in-out;

  @media (min-width: ${({ theme }) => theme.mediaQueries.sm}) {
    left: -180px;
  }
`;

export const StepperWrapper = styled.div`
  align-self: center;
`;

export const StyledButton = styled(Button)<ButtonProps>(
  ({ step }) => css`
    z-index: 10;

    @media (min-width: ${({ theme }) => theme.mediaQueries.xs}) {
      align-self: ${step === 6 || step === 0 ? `center` : step === 8 ? 'flex-start' : `unset`};
    }
  `
);

export const SkipPlanButton = styled.div`
  cursor: pointer;
  color: ${({ theme }) => theme.colors.black5};
  font-weight: 600;
`;

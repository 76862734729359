import { EVerticalNames } from '@constants';
import { Flex } from 'antd';
import { uniq } from 'lodash';
import { useTranslation } from 'react-i18next';

import { TitleWrapper, Wrapper } from './ContactWidget.styles';

import { Icon } from '@/components/Icon';
import { WidgetBase } from '@/components/WidgetBase';
import { useProfile } from '@/store';
import { WidgetProps } from '@/types';

type Props = WidgetProps;

const DEFAULT_EMAIL = 'atencionalalumno@ucademy.com';

const PHONES = {
  [EVerticalNames.UNI]: {
    ' ': '902 02 80 24'
  },
  [EVerticalNames.FP]: {
    ' ': '919 49 94 84'
  },
  [EVerticalNames.OPOS]: {
    Educación: '919 03 63 48',
    FyCSE: '919 49 94 49',
    Administración: '925 94 05 58'
  },
  [EVerticalNames.WKC]: {
    FP: '919 49 94 84'
  }
} as const;

export const ContactWidget: React.FC<Props> = props => {
  const { t, i18n } = useTranslation('CommonDashboardTranslations');
  const { profile } = useProfile();

  const tags = profile?.tags || [];
  const verticals = uniq(tags.map(tag => tag.vertical));
  const currentLanguage = i18n.resolvedLanguage?.toLocaleUpperCase() || 'ES';

  return (
    <WidgetBase title={t('CONTACT_WIDGET_TITLE')} {...props}>
      <Wrapper>
        <div>
          <TitleWrapper>
            <Icon i="Email" />
            <div>{t('CONTACT_BY_EMAIL')}</div>
          </TitleWrapper>
          <a href={`mailto:${DEFAULT_EMAIL}`}>{DEFAULT_EMAIL}</a>
        </div>

        {verticals.length > 0 && currentLanguage === 'ES' && (
          <Flex vertical>
            <TitleWrapper>
              <Icon i="Phone" />
              <div>{t('CONTACT_BY_PHONE')}</div>
            </TitleWrapper>
            {verticals.map(vertical => (
              <Flex key={vertical} vertical>
                {Object.entries(PHONES[vertical] || {}).map(([area, phone]: [string, string]) => (
                  <Flex key={`${vertical}-${area}`} justify="space-between">
                    {area && <div>{area}</div>}
                    <a href={`tel:${phone}`}>{phone}</a>
                  </Flex>
                ))}
              </Flex>
            ))}
          </Flex>
        )}
      </Wrapper>
    </WidgetBase>
  );
};

import { ITeacherDetailStatsFilters } from '@sdk/contracts';
import { useQuery } from '@tanstack/react-query';
import { useParams } from 'react-router-dom';
import { create } from 'zustand';

import { sdk } from '@/services';

const TeacherStatsService = sdk.getService('TeacherStatsService');

interface IStore {
  filter?: ITeacherDetailStatsFilters;
  setFilter: (filter: ITeacherDetailStatsFilters) => void;
}

const store = create<IStore>(set => ({
  setFilter: (filter: ITeacherDetailStatsFilters) => set(state => ({ ...state, filter }))
}));

export const useTeacherDetailStats = (teacherSelectedId?: string) => {
  const { _id } = useParams<{ _id: string }>();
  const teacherId = _id ?? teacherSelectedId;
  const { filter, setFilter } = store();

  const { isLoading, data, error } = useQuery({
    queryKey: ['teacher', 'stats', teacherId, filter],
    queryFn: () => TeacherStatsService.getTeacherDetailStats(teacherId!, filter),
    enabled: !!teacherId
  });

  return {
    teacherDetailStats: data,
    filter,
    setFilter,
    isLoading,
    error
  };
};

import { ERoles } from '@constants';
import { createQueryString } from '@utils';

import { IPaginatedResponse, IPagination } from '../common';
import { RequestService } from '../request/request.service';
import { IUser } from '../user';

export class MyAcademyInternalUsersService extends RequestService {
  public constructor(apiUrl: string) {
    super(apiUrl);
  }

  public getInternalUsers = async (role: ERoles, search?: string, pagination?: IPagination) => {
    const query = createQueryString({
      search,
      limit: pagination?.limit,
      page: pagination?.page,
      filters: { role }
    });
    return this.fetcher<IPaginatedResponse<IUser>>(`/my-academy/internal-users?${query}`);
  };

  public getInternalUser = async (internalUserId: string) => {
    return this.fetcher<IUser>(`/my-academy/internal-users/${internalUserId}`);
  };

  public createInternalUser = async (newInternalUser: Partial<IUser>) => {
    return this.poster<IUser, Partial<IUser>>('/my-academy/internal-users', newInternalUser);
  };

  public updateInternalUser = async (internalUser: Partial<IUser>) => {
    return this.putter<IUser, Partial<IUser>>(
      `/my-academy/internal-users/${internalUser._id}`,
      internalUser
    );
  };

  public findAllInternalUsers = async () => {
    return this.fetcher<IUser[]>('/my-academy/internal-users/all-for-selectors');
  };
}

import { IModeratedTicket, ITicket, ITicketFormValues } from '@sdk/contracts';
import { Form, Select } from 'antd';
import { forwardRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { ListWrapper, SelectorWrapper, Wrapper } from './ActivityTicketsList.styles';
import { TicketItem } from './TicketItem';

import { LoadingCircleSpinner } from '@/components/Loaders';
import { CreateTicketForm } from '@/components/TicketList/CreateTicketForm';
import { useReviewingTicketModal } from '@/hooks';
import { useActivityTicketsList, useActivityTrack, useMutateMyTickets } from '@/store';

type Props = {
  tickets: ITicket[];
};

export const ActivityTicketsList = forwardRef<HTMLDivElement, Props>(({ tickets }, ref) => {
  const { t } = useTranslation('StudentCourseTranslations');
  const { isLoading, sort, setSort } = useActivityTicketsList();
  const { createTicket } = useMutateMyTickets();
  const { subscription, getCurrentActivity } = useActivityTrack();
  const { activity, section } = getCurrentActivity();
  const [highlightedTicket, setHighlightedTicket] = useState<string | null>(null);
  const { setIsOpen: setBannedCommentModalOpen } = useReviewingTicketModal();
  const [isActive, setIsActive] = useState(false);
  const [form] = Form.useForm();

  const handleCloseForm = () => {
    setIsActive(false);
    form.resetFields();
  };

  const onTicketCreated = (moderatedTicket: IModeratedTicket) => {
    const { ticket, canCreateTicket } = moderatedTicket;
    handleCloseForm();
    if (!canCreateTicket) return setBannedCommentModalOpen(true);
    if (!ticket) return;
    setHighlightedTicket(ticket._id);
    setTimeout(() => {
      const ticketElement = document.getElementById(`ticket-${ticket._id}`);
      ticketElement?.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }, 100);
    setTimeout(() => {
      setHighlightedTicket(null);
    }, 3000);
  };

  const onFormSubmit = (values: ITicketFormValues) => {
    if (!subscription || !section || !activity) return;
    createTicket(
      {
        ...values,
        ticketRelatedContent: {
          course: subscription.course._id,
          section: section._id,
          activity: activity._id
        }
      },
      { onSuccess: onTicketCreated }
    );
  };

  const sortOptions = [
    { label: t('MOST_USEFUL'), value: 'usefulTicket' },
    { label: t('MOST_RECENT'), value: 'createdAt' }
  ];

  return (
    <Wrapper ref={ref}>
      <CreateTicketForm
        form={form}
        isActive={isActive}
        setIsActive={setIsActive}
        onFormSubmit={onFormSubmit}
      />
      <ListWrapper>
        <SelectorWrapper>
          <Select
            value={sort.field}
            options={sortOptions}
            size="small"
            variant="borderless"
            onChange={value => setSort({ field: value, order: 'descend' })}
          />
        </SelectorWrapper>
        {tickets.map(ticket => (
          <TicketItem
            key={ticket._id}
            id={`ticket-${ticket._id}`}
            ticket={ticket}
            isHighlighted={highlightedTicket === ticket._id}
          />
        ))}
      </ListWrapper>
      {isLoading && <LoadingCircleSpinner />}
    </Wrapper>
  );
});

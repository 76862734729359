import styled from 'styled-components';

export const TitleWrapper = styled.div`
  overflow: hidden;
  color: ${({ theme }) => theme.colors.black9};
  text-overflow: ellipsis;
  font-family: ${({ theme }) => theme.fontFamily.poppins};
  font-size: 24px;
  font-weight: 600;
  line-height: 32px;
  letter-spacing: -0.2px;
`;

export const DescriptionWrapper = styled.div`
  display: flex;
  gap: 4px;
  color: ${({ theme }) => theme.colors.black9};
  text-align: center;
  font-family: ${({ theme }) => theme.fontFamily.openSans};
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
`;

export const PositionWrapper = styled(DescriptionWrapper)`
  font-weight: 700;
`;

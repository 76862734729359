import styled from 'styled-components';

export const Wrapper = styled.div`
  border: 1px solid transparent;
  align-self: flex-end;
  border-radius: 4px;
  background: linear-gradient(
        ${({ theme }) => theme.colors.white},
        ${({ theme }) => theme.colors.white}
      )
      padding-box,
    linear-gradient(
        to left,
        ${({ theme }) => theme.colors.green6},
        ${({ theme }) => theme.colors.blue4},
        ${({ theme }) => theme.colors.green6},
        ${({ theme }) => theme.colors.purple6}
      )
      border-box;
`;

export const TextWrapper = styled.div`
  background-color: ${({ theme }) => theme.colors.white};
  border-radius: 4px;
  display: flex;
  align-items: center;
  gap: 4px;
  padding: 4px 12px;
`;

export const CountWrapper = styled.div`
  color: ${({ theme }) => theme.colors.green6};
  font-weight: 600;
  font-size: 22px;
`;

const ManagerRoutesTranslations = {
  USERS: {
    es: 'Usuarios',
    en: 'Users',
    cat: 'Usuaris',
    eus: 'Erabiltzaileak',
    pt: 'Usuários'
  },
  STUDENTS: {
    es: 'Alumnos',
    en: 'Students',
    cat: 'Alumnes',
    eus: 'Ikasleak',
    pt: 'Alunos'
  },
  INTERNAL_USERS: {
    es: 'Usuarios internos',
    en: 'Internal users',
    cat: 'Usuaris interns',
    eus: 'Barne erabiltzaileak',
    pt: 'Usuários internos'
  },
  CATEGORIES: {
    es: 'Formaciones',
    en: 'Formations',
    cat: 'Formació',
    eus: 'Formazioak',
    pt: 'Formações'
  },
  TESTS: {
    es: 'Tests',
    en: 'Tests',
    cat: 'Tests',
    eus: 'Testak',
    pt: 'Testes'
  },
  CREATE_TEST: {
    es: 'Crear test',
    en: 'Create test',
    cat: 'Crear test',
    eus: 'Testa sortu',
    pt: 'Criar teste'
  },
  TUTORING_GROUPS: {
    es: 'Tutorías',
    en: 'Tutoring groups',
    cat: 'Tutories',
    eus: 'Tutoretzak',
    pt: 'Grupos de tutoria'
  },
  EXAM_GROUPS: {
    es: 'Convocatorias',
    en: 'Exam groups',
    cat: 'Convocatòries',
    eus: 'Deialdiak',
    pt: 'Grupos de exames'
  },
  SCRAPPED_EXAM_GROUPS: {
    es: 'Convocatorias descargadas',
    en: 'Scrapped exam groups',
    cat: 'Convocatòries descarregades',
    eus: 'Convocatorias descargadas',
    pt: 'Grupos de exames descarregados'
  },
  QUESTIONS_BANK: {
    es: 'Banco de preguntas',
    en: 'Questions bank',
    cat: 'Banc de preguntes',
    eus: 'Galderen bankua',
    pt: 'Banco de perguntas'
  },
  STATS: {
    es: 'Estadísticas',
    en: 'Stats',
    cat: 'Estadístiques',
    eus: 'Estatistikak',
    pt: 'Estatísticas'
  },
  COURSES_STATS: {
    es: 'Cursos',
    en: 'Courses',
    cat: 'Cursos',
    eus: 'Ikastaroak',
    pt: 'Cursos'
  },
  ACTIVITY_STATS: {
    es: 'Actividad',
    en: 'Activity',
    cat: 'Activitat',
    eus: 'Ekintzak',
    pt: 'Atividades'
  },
  CONTENT: {
    es: 'Contenido',
    en: 'Content',
    cat: 'Contingut',
    eus: 'Edukiak',
    pt: 'Conteúdo'
  },
  HISTORIC: {
    es: 'Tabla de gestión',
    en: 'Management table',
    cat: 'Taula de gestió',
    eus: 'Kudeaketa taula',
    pt: 'Tabela de gestão'
  },
  TOTALS: {
    es: 'Totales',
    en: 'Totals',
    cat: 'Totals',
    eus: 'Guztiak',
    pt: 'Totais'
  },
  PROBLEM_REPORTS: {
    es: 'Control de problemas',
    en: 'Problem control',
    cat: 'Control de problemes',
    eus: 'Arazoen kontrola',
    pt: 'Controle de problemas'
  },
  INDEXATION_LANDINGS: {
    es: 'Landings de indexación',
    en: 'Indexation landings',
    cat: "Landings d'indexació",
    eus: 'Indexazio landings',
    pt: 'Landings de indexação'
  },
  FOLLOW_UP: {
    es: 'Seguimiento',
    en: 'Follow up',
    cat: 'Seguimiento',
    eus: 'Seguimiento',
    pt: 'Seguimento'
  },
  COURSE_DRIP_CONTENT: {
    es: 'Drip content',
    en: 'Drip content',
    cat: 'Contingut de drip',
    eus: 'Drip edukiak',
    pt: 'Conteúdo de drip'
  }
};

const AdminRoutesTranslations = {
  USERS: {
    es: 'Usuarios',
    en: 'Users',
    cat: 'Usuaris',
    eus: 'Erabiltzaileak',
    pt: 'Usuários'
  },
  AI_TESTING: {
    es: 'Pruebas AI',
    en: 'AI Testing',
    cat: 'Proves d`IA',
    eus: 'AI Froga',
    pt: 'Testes de IA'
  },
  ACADEMIES: {
    es: 'Academias',
    en: 'Academies',
    cat: 'Acadèmies',
    eus: 'Akademiak',
    pt: 'Académias'
  },
  COURSES: {
    es: 'Cursos',
    en: 'Courses',
    cat: 'Cursos',
    eus: 'Ikastaroak',
    pt: 'Cursos'
  },
  IA_TESTING: {
    es: 'IA Testing',
    en: 'IA Testing',
    cat: 'IA Testing',
    eus: 'IA Froga',
    pt: 'Testes de IA'
  },
  AI_TEACHERS: {
    es: 'Profesores IA',
    en: 'AI Teachers',
    cat: 'Professors IA',
    eus: 'IA Irakasleak',
    pt: 'Professores de IA'
  },
  PROMPTS: {
    es: 'Prompts',
    en: 'Prompts',
    cat: 'Prompts',
    eus: 'Prompts',
    pt: 'Prompts'
  }
};

const StudentRoutesTranslations = {
  USERS: {
    es: 'Usuarios',
    en: 'Users',
    cat: 'Usuaris',
    eus: 'Erabiltzaileak',
    pt: 'Usuários'
  },
  ACADEMIES: {
    es: 'Academias',
    en: 'Academies',
    cat: 'Acadèmies',
    eus: 'Akademiak',
    pt: 'Académias'
  },
  TEST: {
    es: 'Test',
    en: 'Test',
    cat: 'Prova',
    eus: 'Froga',
    pt: 'Teste'
  },
  ALL_TEST: {
    es: 'Todos los test',
    en: 'All test',
    cat: 'Totes les proves',
    eus: 'Guztiak frogak',
    pt: 'Todos os testes'
  },
  ONBOARDING: {
    es: 'Onboarding',
    en: 'Onboarding',
    cat: 'Onboarding',
    eus: 'Onboarding',
    pt: 'Onboarding'
  },
  PROFILE: {
    es: 'Perfil',
    en: 'Profile',
    cat: 'Perfil',
    eus: 'Profila',
    pt: 'Perfil'
  },
  RANKING: {
    es: 'Ranking',
    en: 'Ranking',
    cat: 'Ranking',
    eus: 'Ranking',
    pt: 'Ranking'
  },
  STUDY: {
    es: 'Estudiar',
    en: 'Study',
    cat: 'Estudiar',
    eus: 'Ikasi',
    pt: 'Estudar'
  },
  PRACTICE_WITH_TESTS: {
    es: 'Practicar con tests',
    en: 'Practice with tests',
    cat: 'Practicar amb proves',
    eus: 'Praktikatu frogekin',
    pt: 'Praticar com testes'
  },
  RATE_YOUR_EXPERIENCE: {
    es: 'Valorar tu experiencia',
    en: 'Rate your experience',
    cat: 'Valorar la teva experiència',
    eus: 'Baloratu zure esperientzia',
    pt: 'Avaliar sua experiência'
  },
  TUTOR_VOTE: {
    es: 'Valora a tu tutor',
    en: 'Rate your tutor',
    cat: 'Valorar el teu tutor',
    eus: 'Baloratu zure tutor',
    pt: 'Avaliar seu tutor'
  },
  TEACHER_VOTE: {
    es: 'Valora a tu profesor',
    en: 'Rate your teacher',
    cat: 'Valorar el teu professor',
    eus: 'Baloratu zure ikasle',
    pt: 'Avaliar seu professor'
  },
  LIVE_CLASS_VOTE: {
    es: 'Valora tus clases en directo',
    en: 'Rate your live classes',
    cat: 'Valorar les teves classes en directe',
    eus: 'Baloratu zure zuzeneko klaseak',
    pt: 'Avaliar suas aulas ao vivo'
  }
};

const PublicRoutesTranslations = {
  LANDING: {
    es: 'Landing',
    en: 'Landing',
    cat: 'Pàgina d`inici',
    eus: 'Hasiera orria',
    pt: 'Página de início'
  },
  LOGIN: {
    es: 'Login',
    en: 'Login',
    cat: 'Iniciar sessió',
    eus: 'Sartu',
    pt: 'Entrar'
  },
  SIGN_UP: {
    es: 'Registro',
    en: 'Sign up',
    cat: 'Registre',
    eus: 'Izena eman',
    pt: 'Registrar'
  },
  RECOVER: {
    es: 'Recuperar',
    en: 'Recover',
    cat: 'Recuperar',
    eus: 'Berreskuratu',
    pt: 'Recuperar'
  }
};

const CommonRoutesTranslations = {
  DASHBOARD: {
    es: 'Inicio',
    en: 'Dashboard',
    cat: 'Tauler de control',
    eus: 'Arbela panela',
    pt: 'Painel de controle'
  },
  COURSES: {
    es: 'Cursos',
    en: 'Courses',
    cat: 'Cursos',
    eus: 'Ikastaroak',
    pt: 'Cursos'
  },
  TEACHERS: {
    es: 'Profesores',
    en: 'Teachers',
    cat: 'Professors',
    eus: 'Irakasleak',
    pt: 'Professores'
  },
  DOUBTS: {
    es: 'Dudas',
    en: 'Doubts',
    cat: 'Dubtes',
    eus: 'Zalantzak',
    pt: 'Dúvidas'
  },
  MY_DOUBTS: {
    es: 'Mis dudas',
    en: 'My doubts',
    cat: 'Els meus dubtes',
    eus: 'Nire zalantzak',
    pt: 'Minhas dúvidas'
  },
  LIVE_EVENTS: {
    es: 'Clases en directo',
    en: 'Live classes',
    cat: 'Classes en directe',
    eus: 'Zuzeneko klaseak',
    pt: 'Aulas ao vivo'
  },
  CALENDAR: {
    es: 'Calendario',
    en: 'Calendar',
    cat: 'Calendari',
    eus: 'Egutegia',
    pt: 'Calendário'
  },
  EVENT: {
    es: 'Evento',
    en: 'Event',
    cat: 'Esdeveniment',
    eus: 'Ekitaldi',
    pt: 'Evento'
  },
  DEMODAY: {
    es: 'Demo Day',
    en: 'Demo Day',
    cat: 'Demo Day',
    eus: 'Demo Day',
    pt: 'Demo Day'
  },
  MATERIAL: {
    es: 'Material',
    en: 'Material',
    cat: 'Material',
    eus: 'Materiala',
    pt: 'Material'
  },
  CHAT_TUTOR: {
    es: 'Chat tutor',
    en: 'Chat tutor',
    cat: 'Xat tutor',
    eus: 'Tutorearen txat',
    pt: 'Chat tutor'
  },
  POST: {
    es: 'Post',
    en: 'Post',
    cat: 'Post',
    eus: 'Post',
    pt: 'Post'
  },
  SECTION_REVIEWS: {
    es: 'Valoración de secciones',
    en: 'Section reviews',
    cat: 'Valoració de seccions',
    eus: 'Atalak baloratu',
    pt: 'Avaliações de secções'
  },
  COMMUNICATION: {
    es: 'Comunicación',
    en: 'Communication',
    cat: 'Comunicació',
    eus: 'Komunikazioa',
    pt: 'Comunicação'
  },
  EXAMS: {
    es: 'Exámenes',
    en: 'Exams',
    cat: 'Exàmens',
    eus: 'Ekzamenak',
    pt: 'Exames'
  },
  DELIVERABLES: {
    es: 'Entregables',
    en: 'Deliverables',
    cat: 'Lliurables',
    eus: 'Entregagarriak',
    pt: 'Entregáveis'
  }
};

export const RoutesTranslations = {
  ...CommonRoutesTranslations,
  ...ManagerRoutesTranslations,
  ...AdminRoutesTranslations,
  ...StudentRoutesTranslations,
  ...PublicRoutesTranslations
};

import { ETicketStatus } from '@constants';
import { message, Modal } from 'antd';
import { useEffect, useState } from 'react';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';

import { MessageContent } from '../TicketList/MessageContent';
import { TicketAnswer } from '../TicketList/TicketAnswer';
import { UserInfo } from '../TicketList/UserInfo';

import { MessageSender } from './MessageSender';
import { ReassignTeacher } from './ReassignTeacher';
import { ContentWrapper, FooterWrapper, Wrapper } from './TicketViewer.styles';

import { TicketStatusBar } from '@/components/TicketStatusBar';
import { TICKET_MODAL_WIDTH } from '@/constants';
import { useIsMobile } from '@/hooks';
import { useMutateMyTickets, useMyTicketDetail } from '@/store';

export const TicketViewer = () => {
  const { t } = useTranslation(['CommonTicketsTranslations', 'DefaultTranslations']);
  const [searchParams] = useSearchParams();
  const ticketId = searchParams.get('ticketId');
  const { pathname } = useLocation();
  const navigateTo = useNavigate();
  const { ticket } = useMyTicketDetail(ticketId);
  const { updateTicket } = useMutateMyTickets();
  const [displayAnswersIndex, setDisplayAnswersIndex] = useState(1);
  const isMobile = useIsMobile();

  useEffect(() => {
    if (!ticket) return;

    const filteredMessages = ticket.messages
      ?.filter(msg => !msg.isAutomatic)
      .sort((a, b) => (b.usefulAnswer?.length ?? 0) - (a.usefulAnswer?.length ?? 0));

    setDisplayAnswersIndex(filteredMessages?.length ?? 1);
  }, [ticket]);

  const filteredMessages = useMemo(() => {
    if (!ticket) return [];

    return ticket.messages
      ?.filter(msg => !msg.isAutomatic)
      .sort((a, b) => (b.usefulAnswer?.length ?? 0) - (a.usefulAnswer?.length ?? 0));
  }, [ticket?.messages]);

  const messages = useMemo(() => {
    return filteredMessages?.slice(0, displayAnswersIndex);
  }, [filteredMessages, displayAnswersIndex]);

  if (!ticket) return null;

  const { _id, status } = ticket;

  const isSenderDisabled =
    status === ETicketStatus.CLOSED_NOT_RESOLVED || status === ETicketStatus.CLOSED_RESOLVED;

  const handleStatusChange = (newStatus: ETicketStatus) => {
    updateTicket(
      { _id, status: newStatus },
      {
        onSuccess: () => {
          message.info(
            t('CommonTicketsTranslations:TICKET_STATUS_UPDATED', {
              status: t(`DefaultTranslations:${newStatus}`)
            })
          );
        }
      }
    );
  };

  const handleClose = () => {
    if (!ticketId) return;
    navigateTo(pathname.replace(ticketId, ''));
  };

  return (
    <Modal
      footer={null}
      closable={false}
      open={Boolean(ticketId)}
      onCancel={handleClose}
      title={<TicketStatusBar ticket={ticket} onChange={handleStatusChange} />}
      width={isMobile ? '100%' : TICKET_MODAL_WIDTH}
      styles={{
        body: {
          height: '100%',
          maxHeight: '70vh',
          overflowY: 'auto',
          position: 'relative'
        }
      }}>
      <Wrapper>
        <ContentWrapper>
          <UserInfo ticket={ticket} />
          <MessageContent
            canDelete={false}
            content={ticket.description}
            sender={ticket.issuer}
            file={ticket.attachment}
            createdAt={ticket.createdAt}
            ticketId={ticket._id}
            isHighlighted={false}
          />
        </ContentWrapper>
        {messages.map((msg, msgIndex) => (
          <TicketAnswer
            key={msg._id}
            message={msg}
            ticketId={ticket._id}
            isLastMessage={msgIndex === messages.length - 1}
          />
        ))}
      </Wrapper>
      <FooterWrapper>
        {!isSenderDisabled && <MessageSender ticketId={ticket._id} />}
        <ReassignTeacher teacherId={ticket.assignee?._id} name={ticket.assignee?.name} />
      </FooterWrapper>
    </Modal>
  );
};

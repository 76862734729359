import { ETicketStatus } from '@constants';
import { ITicket } from '@sdk/contracts';
import { dateProvider } from '@utils';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { TimeWidget } from './components';
import {
  BadgeWrapper,
  InfoWrapper,
  ItemWrapper,
  StyledLink,
  Wrapper
} from './TicketStatusBar.styles';
import { getFirstMessageCreationDate } from './utils';

import { Icon } from '@/components/Icon';
import { TicketStatusBadge } from '@/components/TicketStatusBadge';
import { useActivityPreview } from '@/hooks';
import { useMySubscriptions, useProfile } from '@/store';
import { getFormattedDatesDifference } from '@/utils';

type Props = {
  ticket: ITicket;
  onChange?: (status: ETicketStatus) => void;
};

export const TicketStatusBar: React.FC<Props> = ({ ticket, onChange }) => {
  const { t } = useTranslation();
  const { status, issuer, ticketRelatedContent, createdAt, solvedAt, messages } = ticket;
  const { subscriptions } = useMySubscriptions();
  const { setActivityId } = useActivityPreview();
  const {
    roleCheck: { isStudent }
  } = useProfile();
  const navigateTo = useNavigate();

  const firstMessageCreationDate = useMemo(
    () => getFirstMessageCreationDate(issuer._id, messages),
    [issuer._id, messages]
  );

  const handleGoToActivity = () => {
    if (!isStudent) return setActivityId(ticketRelatedContent.activity?._id);
    if (!subscriptions) return;
    const activitySubscription = subscriptions.find(
      subscription => subscription.course._id === ticketRelatedContent.course._id
    );

    if (!activitySubscription) return;

    navigateTo(
      `/subscription/${activitySubscription._id}?activity=${ticketRelatedContent.activity?._id}&activeTab=QUESTIONS`
    );
  };

  return (
    <Wrapper>
      <BadgeWrapper>
        <TicketStatusBadge status={status} editable={!!onChange} onChange={onChange} />
        {solvedAt && (
          <TimeWidget
            title={t('RESOLUTION_TIME')}
            subtitle={getFormattedDatesDifference(createdAt, solvedAt, t)}
          />
        )}
        {firstMessageCreationDate && (
          <TimeWidget
            title={t('FIRST_RESPONSE_TIME')}
            subtitle={getFormattedDatesDifference(createdAt, firstMessageCreationDate, t)}
          />
        )}
      </BadgeWrapper>
      <InfoWrapper>
        <div>
          <Icon i="UserAvatar" />
          <ItemWrapper>{`${issuer.name} ${issuer.lastName}`}</ItemWrapper>
        </div>
        <div>
          <Icon i="Book" />
          <ItemWrapper>{ticketRelatedContent.course.name}</ItemWrapper>
          <ItemWrapper>
            <StyledLink onClick={handleGoToActivity}>
              {ticketRelatedContent.activity?.name}
            </StyledLink>
          </ItemWrapper>
        </div>
        <div>
          <Icon i="Calendar" />
          <ItemWrapper>{dateProvider.format(createdAt, 'dd/MM/yy')}</ItemWrapper>
        </div>
      </InfoWrapper>
    </Wrapper>
  );
};

import { useQuery } from '@tanstack/react-query';
import { useParams } from 'react-router-dom';

import { sdk } from '@/services';

const MyAcademyTeachersService = sdk.getService('MyAcademyTeachersService');

export const useTeacherDetail = (teacherSelectedId?: string) => {
  const { _id } = useParams<{ _id: string }>();
  const teacherId = _id ?? teacherSelectedId;

  const { isLoading, data, error } = useQuery({
    queryKey: ['teacher', 'detail', teacherId],
    queryFn: () => MyAcademyTeachersService.getTeacherById(teacherId!),
    enabled: !!teacherId
  });

  return {
    teacherDetail: data,
    isLoading,
    error
  };
};

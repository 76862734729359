import { AnalyticsTrackEvents } from '@constants';
import { IMessage } from '@sdk/contracts';
import { Avatar } from 'antd';
import { useMemo } from 'react';
import { DefaultTheme, useTheme } from 'styled-components';

import { MessageContent } from '../MessageContent';

import {
  AvatarThreadWrapper,
  InfoWrapper,
  LikesWrapper,
  ThreadWrapper,
  UserInfoWrapper,
  Wrapper
} from './TicketAnswer.styles';

import { Icon } from '@/components/Icon';
import { useAnalytics } from '@/hooks';
import { useMutateMyTickets, useProfile } from '@/store';

type Props = {
  message: IMessage;
  ticketId: string;
  isLastMessage: boolean;
};

const AVATAR_SIZE = 32;
const FAV_ICON_SIZE = 24;

export const TicketAnswer: React.FC<Props> = ({ message, ticketId, isLastMessage }) => {
  const { colors } = useTheme();
  const { content, sender, attached, createdAt, usefulAnswer, formula } = message;
  const { deleteMessage, changeUsefulMessageVote } = useMutateMyTickets();
  const { profile } = useProfile();
  const { fireEvent } = useAnalytics();

  const isLiked = useMemo(() => {
    if (!usefulAnswer || !profile) return false;
    return usefulAnswer.includes(profile._id);
  }, [usefulAnswer, profile]);

  const handleDeleteMessage = () => deleteMessage({ ticketId, messageId: message._id });

  const handleChangeUsefulMessageVote = () => {
    changeUsefulMessageVote(
      { ticketId, messageId: message._id },
      {
        onSuccess: () => {
          if (!isLiked) {
            fireEvent(AnalyticsTrackEvents.COMMENT_LIKE, {
              ID: sender._id,
              Name: sender.name
            });
          }
        }
      }
    );
  };

  return (
    <Wrapper>
      <ThreadWrapper $isLastMessage={isLastMessage}>
        <AvatarThreadWrapper />
      </ThreadWrapper>
      <InfoWrapper>
        <UserInfoWrapper>
          <Avatar src={sender.avatar?.url} size={AVATAR_SIZE} style={{ flex: 'none' }}>
            {!sender.avatar?.url && `${sender.name[0]}${sender.lastName[0]}`}
          </Avatar>
          <LikesWrapper>
            <Icon
              i={isLiked ? 'FavoriteFilled' : 'Favorite'}
              size={FAV_ICON_SIZE}
              color={colors.green6 as keyof DefaultTheme['colors']}
              onClick={handleChangeUsefulMessageVote}
            />
            <div>{usefulAnswer?.length || 0}</div>
          </LikesWrapper>
        </UserInfoWrapper>
      </InfoWrapper>
      <MessageContent
        content={content}
        formula={formula}
        sender={sender}
        file={attached}
        createdAt={createdAt}
        ticketId={ticketId}
        onDeleteMessage={handleDeleteMessage}
      />
    </Wrapper>
  );
};

import { ITrend } from '@sdk/contracts';
import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  padding: 20px;
  gap: 32px;
  border-radius: 8px;
  border: 1px solid ${({ theme }) => theme.colors.black3};
  background: ${({ theme }) => theme.colors.white};
`;

export const LegendTitleWrapper = styled.span`
  color: ${({ theme }) => theme.colors.black9};
  font-family: ${({ theme }) => theme.fontFamily.openSans};
  font-size: 14px;
  font-weight: 600;
  line-height: 22px;
`;

export const LineWrapper = styled.div`
  width: 100%;
  height: 1px;
  background: ${({ theme }) => theme.colors.black3};
`;

export const DotWrapper = styled.div`
  width: 10px;
  height: 10px;
  background: ${({ theme }) => theme.colors.green6};
  border-radius: 100px;
`;

export const UscoreValueWrapper = styled.span<{ trend?: ITrend }>`
  color: ${({ theme, trend }) =>
    trend === 'up'
      ? theme.colors.green6
      : trend === 'down'
      ? theme.colors.red6
      : theme.colors.black9};
  text-align: center;
  font-family: ${({ theme }) => theme.fontFamily.poppins};
  font-size: 30px;
  font-weight: 600;
  line-height: 40px;
  letter-spacing: -0.5px;
`;

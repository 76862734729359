import styled from 'styled-components';

export const Wrapper = styled.div`
  width: 300px;
  display: flex;
  flex-direction: column;
  gap: 16px;

  .ant-picker {
    width: 100%;
  }
`;

import styled from 'styled-components';

export const LabelWrapper = styled.span`
  color: ${({ theme }) => theme.colors.black6};
  font-family: ${({ theme }) => theme.fontFamily.openSans};
  font-size: 12px;
  font-weight: 600;
  line-height: 16px;
`;

export const ValueWrapper = styled.span`
  color: ${({ theme }) => theme.colors.black9};
  font-family: ${({ theme }) => theme.fontFamily.openSans};
  text-align: right;
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
`;

import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { FC, PropsWithChildren, StrictMode, Suspense } from 'react';
import { BrowserRouter } from 'react-router-dom';

import { ChatMessagesHandler } from './ChatMessagesHandler';
import { EjectionHandler } from './EjectionHandler';
import { FeatureFlagsProvider } from './FeatureFlagsProvider';
import { GamificationNotifications } from './GamificationNotifications';
import { NotificationHandler } from './NotificationHandler';
import { OnlineUsersHandler } from './OnlineUsersHandler';
import { SectionReviewHandler } from './SectionReviewHandler';
import { UserUpdateHandler } from './UserUpdateHandler';

import { LoadingBar } from '@/components/Loaders';
import { GlobalStyle, ThemeProvider } from '@/styles';

const queryClient = new QueryClient();

export const Providers: FC<PropsWithChildren> = ({ children }) => {
  return (
    <StrictMode>
      <QueryClientProvider client={queryClient}>
        <BrowserRouter>
          <ThemeProvider>
            <FeatureFlagsProvider>
              <Suspense fallback={<LoadingBar />}>{children}</Suspense>
              <EjectionHandler />
              <NotificationHandler />
              <OnlineUsersHandler />
              <GamificationNotifications />
              <SectionReviewHandler />
              <UserUpdateHandler />
              <ChatMessagesHandler />
              <GlobalStyle />
              <ReactQueryDevtools initialIsOpen={false} />
            </FeatureFlagsProvider>
          </ThemeProvider>
        </BrowserRouter>
      </QueryClientProvider>
    </StrictMode>
  );
};

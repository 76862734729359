export const DeliverableViewerTranslations = {
  CLOSE_DELIVERABLE_PREVIEW: {
    es: 'Cerrar vista previa',
    en: 'Close deliverable preview',
    cat: 'Tancar vista prèvia',
    eus: 'Itxi aurreikuspena',
    pt: 'Fechar vista prévia'
  },
  TEACHER_COMMENT_PLACEHOLDER: {
    es: 'Escribe aquí tu comentario',
    en: 'Write your comment here',
    cat: 'Escriu el teu comentari aquí',
    eus: 'Ezazu zure komentario hemen',
    pt: 'Escreva o seu comentário aqui'
  },
  SAVE_TEACHER_COMMENT: {
    es: 'Guardar comentario',
    en: 'Save comment',
    cat: 'Guardar comentari',
    eus: 'Gordetu komentarioa',
    pt: 'Salvar comentário'
  },
  TEACHER_COMMENT_TITLE: {
    es: 'Comentario del profesor:',
    en: 'Teacher comment:',
    cat: 'Comentari del professor:',
    eus: 'Ikastaroko komentarioa:',
    pt: 'Comentário do professor:'
  },
  OPEN_FILE: {
    es: 'Abrir archivo',
    en: 'Open file',
    cat: 'Obrir arxiu',
    eus: 'Ireki fitxategia',
    pt: 'Abrir ficheiro'
  },
  DOWNLOAD_FILE: {
    es: 'Descargar archivo',
    en: 'Download file',
    cat: 'Descarregar arxiu',
    eus: 'Erantzun fitxategia',
    pt: 'Descarregar ficheiro'
  }
};

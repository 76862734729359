import { Trans, useTranslation } from 'react-i18next';

import {
  ImageWrapper,
  NameWrapper,
  SubtitleWrapper,
  TitleWrapper,
  Wrapper
} from './Welcome.styles';

import UNDERLINE from '@/assets/onboarding_underline.svg';
import UMY_WELCOME from '@/assets/umy_welcome.svg';
import NAME_VECTOR from '@/assets/umy_welcome_vector.svg';
import { useProfile } from '@/store';

export const Welcome = () => {
  const { t } = useTranslation('StudentCourseSummaryTranslations');
  const { profile } = useProfile();

  if (!profile) return null;

  const { name } = profile;

  return (
    <Wrapper>
      <TitleWrapper>
        {t('WELCOME_TITLE')}
        <NameWrapper>
          {t('SUPER_NAME', {
            name: name.charAt(0).toLocaleUpperCase() + name.slice(1)
          })}
          <ImageWrapper alt="Welcome vector" src={NAME_VECTOR} />
        </NameWrapper>
        <img alt="Underline" width="auto" src={UNDERLINE} />
      </TitleWrapper>
      <img alt="Welcome" src={UMY_WELCOME} height={200} />
      <SubtitleWrapper>
        <Trans i18nKey="WELCOME_SUBTITLE_1" components={{ a: <strong /> }} />
      </SubtitleWrapper>
    </Wrapper>
  );
};

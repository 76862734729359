import { IValidateQuizQuestion } from '@sdk/contracts';
import { useMutation, useQueryClient } from '@tanstack/react-query';

import { sdk } from '@/services';

const QuizService = sdk.getService('QuizService');

export const useValidateQuizQuestion = () => {
  const queryClient = useQueryClient();

  const onSuccess = () =>
    queryClient.invalidateQueries({
      queryKey: ['quizzes']
    });

  const { mutate: validateQuizQuestion, isPending: isValidateQuizQuestionPending } = useMutation({
    mutationFn: (quizQuestion: IValidateQuizQuestion) =>
      QuizService.validateQuizQuestion(quizQuestion),
    onSuccess
  });

  const { mutate: validateBulkQuestions, isPending: isValidateBulkQuestionsPending } = useMutation({
    mutationFn: (questions: IValidateQuizQuestion[]) =>
      QuizService.validateBulkQuestions(questions),
    onSuccess
  });

  return {
    validateQuizQuestion,
    isWorking: isValidateQuizQuestionPending || isValidateBulkQuestionsPending,
    validateBulkQuestions
  };
};

import { Flex } from 'antd';
import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  border: 1px solid ${({ theme }) => theme.colors.black2};

  .ant-radio-group {
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  .ant-radio-wrapper span.ant-radio + * {
    width: 100%;
  }
`;

export const AddAnswerWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  padding: 10px 0px;
`;

export const ExplanationWrapper = styled.div`
  border: 1px solid transparent;
  border-radius: 4px;
  padding: 4px 4px;
  position: relative;
  background: linear-gradient(
        ${({ theme }) => theme.colors.white},
        ${({ theme }) => theme.colors.white}
      )
      padding-box,
    linear-gradient(
        to left,
        ${({ theme }) => theme.colors.green6},
        ${({ theme }) => theme.colors.blue4},
        ${({ theme }) => theme.colors.green6},
        ${({ theme }) => theme.colors.purple6}
      )
      border-box;
  .ant-input-outlined {
    padding: 8px 12px !important;

    border-radius: 4px;
    border: 1px solid transparent;
  }

  .ant-input-outlined:hover,
  .ant-input-outlined:focus,
  .ant-input-outlined:focus-within,
  .ant-input-outlined:active {
  }

  .ant-input-outlined:focus,
  .ant-input-outlined:focus-within {
    box-shadow: none;
  }
`;

export const TitleWrapper = styled.div`
  font-weight: 600;
  font-size: 14px;
`;

export const StyledFlex = styled(Flex)`
  padding: 8px 16px;
  gap: 8px;
`;

export const PreviewWrapper = styled.div`
  padding: 12px;
  font-size: 12px;
  background: ${({ theme }) => theme.colors.white};
  border-radius: 6px;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
  overflow: auto;

  .katex {
    font-size: 14px;
  }
`;

export const PreviewContentWrapper = styled.div`
  min-height: 24px;
  display: flex;
  gap: 4px;
  flex-wrap: wrap;
`;

export const PreviewButtonWrapper = styled.div`
  position: absolute;
  top: 4px;
  right: 4px;
  z-index: 2;
`;

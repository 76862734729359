import { createQueryString } from '@utils';

import { IPaginatedResponse, IPagination } from '../common';
import { IGeneratedQuestion } from '../question';
import { RequestService } from '../request';
import { ISection } from '../section';

import {
  ICreateQuiz,
  IFreeModeQuiz,
  IGenerateFreeModeQuiz,
  IQuiz,
  IQuizFilters,
  IQuizList,
  IPracticeQuizFilters,
  IUpdateQuiz,
  IGenerateQuiz,
  IValidateQuizQuestion
} from './quiz.contract';

export class QuizService extends RequestService {
  constructor(apiUrl: string) {
    super(apiUrl);
  }
  async createQuiz(quiz: ICreateQuiz) {
    return this.poster<IQuiz, ICreateQuiz>('/quizzes', quiz);
  }

  async getQuizzes(
    search: string,
    pagination: IPagination,
    filters?: IQuizFilters,
    bypass?: boolean
  ) {
    const query = createQueryString({
      search,
      limit: pagination.limit,
      page: pagination.page,
      filters,
      bypass
    });
    return this.fetcher<IPaginatedResponse<IQuizList>>(`/quizzes?${query}`);
  }

  async getStudentPracticeQuizzes(search?: string, pagination?: IPagination) {
    const query = createQueryString({
      search,
      limit: pagination?.limit,
      page: pagination?.page
    });
    return this.fetcher<IPaginatedResponse<IQuizList>>(`/quizzes/student-practice?${query}`);
  }

  async getPracticeQuizzes(
    search?: string,
    pagination?: IPagination,
    bypass?: boolean,
    filters?: IPracticeQuizFilters
  ) {
    const query = createQueryString({
      search,
      limit: pagination?.limit,
      page: pagination?.page,
      bypass,
      filters
    });
    return this.fetcher<IPaginatedResponse<IQuizList>>(`/quizzes/practice?${query}`);
  }

  async getQuiz(quizId: string) {
    return this.fetcher<IQuiz>(`/quizzes/${quizId}`);
  }

  async updateQuiz(quiz: IUpdateQuiz) {
    return this.putter<IQuiz, IUpdateQuiz>(`/quizzes/${quiz._id}`, quiz);
  }

  async removeQuiz(quizId: string) {
    return this.deleter(`/quizzes/${quizId}`);
  }

  public getActivityQuizzesForSelector = async (courseId: string, sectionId: string) => {
    return this.fetcher<IQuiz[]>(`/quizzes/activity/by-course/${courseId}/section/${sectionId}`);
  };

  public toggleQuizStatus(quizId: string) {
    return this.putter(`/quizzes/toggle-status/${quizId}`);
  }

  async getQuizSectionsByCourse(courseId: string) {
    return this.fetcher<ISection[]>(`/quizzes/sections-by-course/${courseId}`);
  }

  async generateFreeModeQuiz(generateFreeModeQuiz: IGenerateFreeModeQuiz) {
    return this.poster<IFreeModeQuiz, IGenerateFreeModeQuiz>(
      `/quizzes/generate-free-mode`,
      generateFreeModeQuiz
    );
  }

  async duplicateQuiz(quizId: string) {
    return this.poster(`/quizzes/${quizId}/duplicate`);
  }

  async generateQuiz(generateQuiz: IGenerateQuiz) {
    const { sectionId, questionCount } = generateQuiz;
    return this.poster<IGeneratedQuestion[], IGenerateQuiz>(
      `/quizzes/generate-quiz/${sectionId}?questionCount=${questionCount}`
    );
  }

  async validateQuizQuestion(validateQuizQuestion: IValidateQuizQuestion) {
    return this.poster<IValidateQuizQuestion, IValidateQuizQuestion>(
      `/quizzes/validate-question`,
      validateQuizQuestion
    );
  }

  async validateBulkQuestions(questions: IValidateQuizQuestion[]) {
    return this.poster<IValidateQuizQuestion[], IValidateQuizQuestion[]>(
      `/quizzes/validate-bulk-questions`,
      questions
    );
  }
}

import { EContractMode } from '@constants';

export const ManagerTeacherDetailTranslations = {
  TEACHER_DETAIL: {
    es: 'Detalle del profesor',
    en: 'Teacher Detail',
    cat: 'Detall del professor',
    eus: 'Irakaslearen xehetasunak',
    pt: 'Detalhe do professor'
  },
  CURRENT_USCORE: {
    es: 'UScore actual',
    en: 'Current UScore',
    cat: 'UScore actual',
    eus: 'Uneko UScore',
    pt: 'UScore atual'
  },
  USCORE: {
    es: 'UScore',
    en: 'UScore',
    cat: 'UScore',
    eus: 'UScore',
    pt: 'UScore'
  },
  STUDENT_RATING: {
    es: 'V. alumnos',
    en: 'Student Rating',
    cat: 'Valoració alumnes',
    eus: 'Ikasleen balorazioa',
    pt: 'Avaliação dos alunos'
  },
  LIVE_CLASS_VOTE_RATING: {
    es: 'V. clases en directo',
    en: 'Live Class Rating',
    cat: 'Valoració classes en directe',
    eus: 'Zuzeneko klaseen balorazioa',
    pt: 'Avaliação das aulas ao vivo'
  },
  TUTORIAL_RATING: {
    es: 'V. tutorías',
    en: 'Tutorial Rating',
    cat: 'Valoració tutories',
    eus: 'Tutoretzen balorazioa',
    pt: 'Avaliação das tutorias'
  },
  TUTORIAL_QUALITY: {
    es: 'Calidad de la tutoría',
    en: 'Tutorial Quality',
    cat: 'Qualitat de la tutoria',
    eus: 'Tutoretzaren kalitatea',
    pt: 'Qualidade da tutoria'
  },
  ATTENTION_AND_MONITORING: {
    es: 'Atención y seguimiento',
    en: 'Attention and Monitoring',
    cat: 'Atenció i seguiment',
    eus: 'Arreta eta jarraipena',
    pt: 'Atenção e monitoramento'
  },
  DOUBT_RESOLUTION: {
    es: 'Resolución de dudas',
    en: 'Doubt Resolution',
    cat: 'Resolució de dubtes',
    eus: 'Zalantzak argitzea',
    pt: 'Resolução de dúvidas'
  },
  TEACHER_RATING: {
    es: 'Valoración profesor',
    en: 'Teacher Rating',
    cat: 'Valoració professor',
    eus: 'Irakaslearen balorazioa',
    pt: 'Avaliação do professor'
  },
  CLASS_DIFFICULTY: {
    es: 'Dificultad de la clase',
    en: 'Class Difficulty',
    cat: 'Dificultat de la classe',
    eus: 'Klasearen zailtasuna',
    pt: 'Dificuldade da aula'
  },
  CLASS_ATTENDANCE: {
    es: 'Asistencia a clases',
    en: 'Class Attendance',
    cat: 'Assistència a classes',
    eus: 'Klaseetara bertaratzea',
    pt: 'Presença nas aulas'
  },
  CLASSES_TAUGHT: {
    es: 'Clases impartidas',
    en: 'Classes Taught',
    cat: 'Classes impartides',
    eus: 'Emandako klaseak',
    pt: 'Aulas ministradas'
  },
  LESSON_RATING: {
    es: 'V. lecciones',
    en: 'Lesson Rating',
    cat: 'Valoració lliçons',
    eus: 'Ikasgaien balorazioa',
    pt: 'Avaliação das lições'
  },
  VIDEOS: {
    es: 'Vídeos',
    en: 'Videos',
    cat: 'Vídeos',
    eus: 'Bideoak',
    pt: 'Vídeos'
  },
  PDF: {
    es: 'PDF',
    en: 'PDF',
    cat: 'PDF',
    eus: 'PDF',
    pt: 'PDF'
  },
  LESSON_TESTS: {
    es: 'Tests de lecciones',
    en: 'Lesson Tests',
    cat: 'Tests de lliçons',
    eus: 'Ikasgaien testak',
    pt: 'Testes das lições'
  },
  TEST_PERFORMANCE: {
    es: 'Rendimiento en tests',
    en: 'Test Performance',
    cat: 'Rendiment en tests',
    eus: 'Testen errendimendua',
    pt: 'Desempenho nos testes'
  },
  FREE_TESTS_CREATED: {
    es: 'Tests libres creados',
    en: 'Free Tests Created',
    cat: 'Tests lliures creats',
    eus: 'Sortutako test libreak',
    pt: 'Testes livres criados'
  },
  LESSON_TESTS_CREATED: {
    es: 'Tests de lecciones creados',
    en: 'Lesson Tests Created',
    cat: 'Tests de lliçons creats',
    eus: 'Sortutako ikasgaien testak',
    pt: 'Testes de lições criados'
  },
  SIMULATIONS_CREATED: {
    es: 'Simulacros creados',
    en: 'Simulations Created',
    cat: 'Simulacres creats',
    eus: 'Sortutako simulazioak',
    pt: 'Simulações criadas'
  },
  REPORTED_QUESTIONS: {
    es: 'Preguntas reportadas',
    en: 'Reported Questions',
    cat: 'Preguntes reportades',
    eus: 'Jakinarazitako galderak',
    pt: 'Questões reportadas'
  },
  TASK_PERFORMANCE: {
    es: 'Rendimiento en tareas',
    en: 'Task Performance',
    cat: 'Rendiment en tasques',
    eus: 'Zereginen errendimendua',
    pt: 'Desempenho nas tarefas'
  },
  TASKS_ON_TIME: {
    es: 'Tareas a tiempo',
    en: 'Tasks on Time',
    cat: 'Tasques a temps',
    eus: 'Garaiz egindako zereginak',
    pt: 'Tarefas no prazo'
  },
  TASKS_OVERDUE: {
    es: 'Tareas fuera de plazo',
    en: 'Tasks Overdue',
    cat: 'Tasques fora de termini',
    eus: 'Epez kanpoko zereginak',
    pt: 'Tarefas atrasadas'
  },
  METRICS: {
    es: 'Métricas',
    en: 'Metrics',
    cat: 'Mètriques',
    eus: 'Metrikak',
    pt: 'Métricas'
  },
  SEARCH_PLACEHOLDER: {
    es: 'Buscar',
    en: 'Search',
    cat: 'Cercar',
    eus: 'Bilatu',
    pt: 'Pesquisar'
  },
  ACTIVE: {
    es: 'Activo',
    en: 'Active',
    cat: 'Actiu',
    eus: 'Aktibo',
    pt: 'Ativo'
  },
  TEACHER_OF: {
    es: 'Profesor de {{verticals}}',
    en: 'Teacher of {{verticals}}',
    cat: 'Professor de {{verticals}}',
    eus: 'Irakaslea {{verticals}}',
    pt: 'Professor de {{verticals}}'
  },
  PERFORMANCE_TAB: {
    es: 'Rendimiento',
    en: 'Performance',
    cat: 'Rendiment',
    eus: 'Eroketa',
    pt: 'Desempenho'
  },
  POSITION_IN_RANKING: {
    es: 'Puesto <a>{{position}}º</a> de {{total}}',
    en: 'Position <a>{{position}}º</a> of {{total}}',
    cat: 'Posició <a>{{position}}º</a> de {{total}}',
    eus: 'Postua <a>{{position}}º</a>tik {{total}}',
    pt: 'Posição <a>{{position}}º</a> de {{total}}'
  },
  COURSE: {
    es: 'Curso',
    en: 'Course',
    cat: 'Curs',
    eus: 'Ikastarok',
    pt: 'Curso'
  },
  SELECT_A_COURSE_TO_FILTER: {
    es: 'Selecciona un curso',
    en: 'Select a course',
    cat: 'Selecciona un curs',
    eus: 'Hautatu ikastarok',
    pt: 'Selecione um curso'
  },
  CLEAR_SEARCH_FILTER: {
    es: 'Limpiar búsqueda',
    en: 'Clear search',
    cat: 'Netejar cerca',
    eus: 'Ezabatu bilatu',
    pt: 'Limpar pesquisa'
  },
  FILTERS: {
    es: 'Filtros',
    en: 'Filters',
    cat: 'Filtres',
    eus: 'Fitxategiak',
    pt: 'Filtros'
  },
  USCORE_TOOLTIP: {
    es: 'El UScore está compuesto por un promedio entre la valoración directa del profesor, la valoración de lecciones y la valoración clases en directo. Si alguna de estas valoraciones no está disponible, no se incluye en el cálculo.',
    en: "The UScore is composed of an average between the teacher's direct rating, the lesson rating, and the live class rating. If any of these ratings are not available, they are not included in the calculation.",
    cat: 'El UScore està compost per una mitjana entre la valoració directa del professor, la valoració de lliçons i la valoració classes en directe. Si alguna de aquestes valoracions no està disponible, no es inclou en el càlcul.',
    eus: 'UScore-a hiru balioeko batez besteko bat da: ikaslearen balorazioa, ikasgaien balorazioa eta zuzeneko klaseen balorazioa. Ez badago batek, ezin da kalkulatu.',
    pt: 'O UScore é composto por uma média entre a avaliação direta do professor, a avaliação de lições e a avaliação de aulas ao vivo. Se alguma dessas avaliações não estiver disponível, não será incluída no cálculo.'
  },
  STUDENT_RATING_TOOLTIP: {
    es: 'Valoración de los alumnos',
    en: 'Student rating',
    cat: "Valoració d'alumnes",
    eus: 'Ikasleen ebaluazioa',
    pt: 'Avaliação dos alunos'
  },
  LIVE_CLASS_VOTE_RATING_TOOLTIP: {
    es: 'Valoración de las clases en directo',
    en: 'Live class rating',
    cat: 'Valoració de les classes en directe',
    eus: 'Zuzeneko klaseen balorazioa',
    pt: 'Avaliação das aulas ao vivo'
  },
  LESSON_RATING_TOOLTIP: {
    es: 'Valoración de las lecciones',
    en: 'Lesson rating',
    cat: 'Valoració de les lliçons',
    eus: 'Ikasgaiaren ebaluazioa',
    pt: 'Avaliação da aula'
  },
  STUDENT_RATING_TOOLTIP_EXPLANATION: {
    es: 'Calificación que refleja del 1 al 5 la satisfacción de los alumnos con el profesor.',
    en: 'Rating that reflects the satisfaction of students with the teacher from 1 to 5.',
    cat: "Valoració que refleja de l'1 al 5 la satisfacció dels alumnes amb el professor.",
    eus: 'Ikasleek irakaslearekin duten gogobetetasuna 1etik 5era adierazten duen balorazioa.',
    pt: 'Avaliação que reflete a satisfação dos alunos com o professor, de 1 a 5.'
  },
  TUTORIAL_RATING_TOOLTIP_EXPLANATION: {
    es: 'Calificación que refleja del 1 al 5 la satisfacción de los alumnos con el tutor.',
    en: 'Rating that reflects the satisfaction of students with the tutor from 1 to 5.',
    cat: "Valoració que refleja de l'1 al 5 la satisfacció dels alumnes amb el tutor.",
    eus: 'Ikasleek tutorearekin duten gogobetetasuna 1etik 5era adierazten duen balorazioa.',
    pt: 'Avaliação que reflete a satisfação dos alunos com o tutor, de 1 a 5.'
  },
  LIVE_CLASS_VOTE_RATING_TOOLTIP_EXPLANATION: {
    es: 'Calificación que refleja del 1 al 5 la satisfacción de los alumnos con las clases en directo.',
    en: 'Rating that reflects the satisfaction of students with the live classes from 1 to 5.',
    cat: "Valoració que refleja de l'1 al 5 la satisfacció dels alumnes amb les classes en directe.",
    eus: '1etik 5era ikasleen gogobetetasuna zuzeneko klaseekin islatzen duen balorazioa.',
    pt: 'Avaliação que reflete a satisfação dos alunos com as aulas ao vivo, de 1 a 5.'
  },
  LESSON_RATING_TOOLTIP_EXPLANATION: {
    es: 'Calificación que refleja del 1 al 5 la satisfacción de los alumnos con el contenido del curso.',
    en: 'Rating that reflects the satisfaction of students with the course content from 1 to 5.',
    cat: "Valoració que refleja de l'1 al 5 la satisfacció dels alumnes amb el contingut del curs.",
    eus: '1etik 5era ikasleen gogobetetasuna irakasgaiaren edukiarekin islatzen duen balorazioa.',
    pt: 'Avaliação que reflete a satisfação dos alunos com o conteúdo do curso, de 1 a 5.'
  },
  TEST_PERFORMANCE_TOOLTIP_EXPLANATION: {
    es: 'Porcentaje de preguntas reportadas en comparación con el total de preguntas creadas.',
    en: 'Percentage of reported questions compared to the total number of questions created.',
    cat: 'Percentatge de preguntes reportades en comparació amb el total de preguntes creades.',
    eus: 'Salatutako galderen ehunekoa sortutako galdera guztien aldean.',
    pt: 'Percentagem de questões reportadas em comparação com o número total de questões criadas.'
  },
  TASK_PERFORMANCE_TOOLTIP_EXPLANATION: {
    es: 'Porcentaje de tareas entregadas dentro del plazo sobre el total de tareas asignadas.',
    en: 'Percentage of tasks delivered within the deadline compared to the total number of tasks assigned.',
    cat: 'Percentatge de tasques entregades dins del termini comparat amb el total de tasques assignades.',
    eus: 'Epe barruan emandako zereginen ehunekoa, esleitutako zeregin guztien gainetik.',
    pt: 'Percentagem de tarefas entregues dentro do prazo em comparação com o número total de tarefas atribuídas.'
  },
  INFORMATION_TAB: {
    es: 'Información',
    en: 'Information',
    cat: 'Informació',
    eus: 'Informazioa',
    pt: 'Informação'
  },
  COURSES_TAB: {
    es: 'Cursos',
    en: 'Courses',
    cat: 'Cursos',
    eus: 'Ikastarok',
    pt: 'Cursos'
  },
  DOCUMENTS_TAB: {
    es: 'Documentos',
    en: 'Documents',
    cat: 'Documents',
    eus: 'Dokumentuak',
    pt: 'Documentos'
  },
  EDIT: {
    es: 'Editar',
    en: 'Edit',
    cat: 'Editar',
    eus: 'Editatu',
    pt: 'Editar'
  },
  DELETE_TEACHER: {
    es: 'Eliminar profesor',
    en: 'Delete teacher',
    cat: 'Eliminar professor',
    eus: 'Irakarazi ezabatu',
    pt: 'Excluir professor'
  },
  ARE_YOU_SURE_TO_DELETE_TEACHER: {
    es: '¿Estás seguro de querer eliminar este profesor?',
    en: 'Are you sure you want to delete this teacher?',
    cat: 'Estàs segur que vols eliminar aquest professor?',
    eus: 'Ziur al dakit zuretzat ezabatu nahi duzula?',
    pt: 'Tem certeza que deseja excluir este professor?'
  },
  RESET_PASSWORD: {
    es: 'Reiniciar contraseña',
    en: 'Reset password',
    cat: 'Reiniciar contrasenya',
    eus: 'Berrezarri pasahitza',
    pt: 'Reiniciar senha'
  },
  ARE_YOU_SURE_TO_RESET_PASSWORD: {
    es: '¿Estás seguro de querer reiniciar la contraseña de este profesor?',
    en: 'Are you sure you want to reset the password of this teacher?',
    cat: 'Estàs segur que vols reiniciar la contrasenya de aquest professor?',
    eus: 'Ziur al dakit zuretzat berrezarri nahi duzula?',
    pt: 'Tem certeza que deseja reiniciar a senha deste professor?'
  },
  DEACTIVATE_ACCOUNT: {
    es: 'Desactivar cuenta',
    en: 'Deactivate account',
    cat: 'Desactivar compte',
    eus: 'Ezabatu kontua',
    pt: 'Desativar conta'
  },
  ARE_YOU_SURE_TO_DEACTIVATE_ACCOUNT: {
    es: '¿Estás seguro de querer desactivar esta cuenta?',
    en: 'Are you sure you want to deactivate this account?',
    cat: 'Estàs segur que vols desactivar aquesta compte?',
    eus: 'Ziur al dakit zuretzat ezabatu nahi duzula?',
    pt: 'Tem certeza que deseja desativar esta conta?'
  },
  ARE_YOU_SURE_TO_ACTIVATE_ACCOUNT: {
    es: '¿Estás seguro de querer activar esta cuenta?',
    en: 'Are you sure you want to activate this account?',
    cat: 'Estàs segur que vols activar aquesta compte?',
    eus: 'Ziur al dakit zuretzat aktibatu nahi duzula?',
    pt: 'Tem certeza que deseja ativar esta conta?'
  },
  ACTIVATE_ACCOUNT: {
    es: 'Activar cuenta',
    en: 'Activate account',
    cat: 'Activar compte',
    eus: 'Aktibatu kontua',
    pt: 'Ativar conta'
  },
  TEACHER_INFORMATION: {
    es: 'Información del profesor',
    en: 'Teacher information',
    cat: 'Informació del professor',
    eus: 'Irakaslearen informazioa',
    pt: 'Informação do professor'
  },
  NAME_AND_LAST_NAME: {
    es: 'Nombre y apellidos',
    en: 'Name and last name',
    cat: 'Nom i cognoms',
    eus: 'Izena eta abizenak',
    pt: 'Nome e apelidos'
  },
  EMAIL: {
    es: 'Email',
    en: 'Email',
    cat: 'Email',
    eus: 'Email',
    pt: 'Email'
  },
  PHONE: {
    es: 'Teléfono',
    en: 'Phone',
    cat: 'Telèfon',
    eus: 'Telefonoa',
    pt: 'Telefone'
  },
  LEGAL_ID: {
    es: 'DNI',
    en: 'DNI',
    cat: 'DNI',
    eus: 'DNI',
    pt: 'DNI'
  },
  ADDRESS: {
    es: 'Dirección',
    en: 'Address',
    cat: 'Direcció',
    eus: 'Helbidea',
    pt: 'Endereço'
  },
  CONTRACT_MODE: {
    es: 'Modalidad de contratación',
    en: 'Contract mode',
    cat: 'Modalitat de contractació',
    eus: 'Kontratatzeko modua',
    pt: 'Modalidade de contrato'
  },
  CREATED_AT: {
    es: 'Fecha de alta',
    en: 'Date of registration',
    cat: "Data d'alta",
    eus: 'Izena ezagutu',
    pt: 'Data de alta'
  },
  DELETED_AT: {
    es: 'Fecha de baja',
    en: 'Date of deletion',
    cat: "Data d'exclusió",
    eus: 'Izena ezabatu',
    pt: 'Data de exclusão'
  },
  [EContractMode.PROFESSIONAL]: {
    es: 'Clave F (profesional)',
    en: 'Key F (professional)',
    cat: 'Clau F (profesional)',
    eus: 'Eskuerra F (profesional)',
    pt: 'Chave F (profissional)'
  },
  [EContractMode.INDEPENDENT]: {
    es: 'Clave G (autónomo)',
    en: 'Key G (independent)',
    cat: 'Clau G (autònom)',
    eus: 'Eskuerra G (autonomo)',
    pt: 'Chave G (autônomo)'
  },
  [EContractMode.CONTRACT]: {
    es: 'Contrato',
    en: 'Contract',
    cat: 'Contracte',
    eus: 'Kontratua',
    pt: 'Contrato'
  },
  [EContractMode.COMPANY]: {
    es: 'Empresa',
    en: 'Company',
    cat: 'Empresa',
    eus: 'Enpresa',
    pt: 'Empresa'
  },
  COURSES_ASSIGNED: {
    es: '{{count}} cursos asignados',
    en: '{{count}} courses assigned',
    cat: '{{count}} cursos assignats',
    eus: '{{count}} ikastarok esleituta',
    pt: '{{count}} cursos atribuídos'
  },
  ASSIGN_COURSE: {
    es: 'Asignar un curso',
    en: 'Assign a course',
    cat: 'Assignar un curs',
    eus: 'Eskatu ikastarok',
    pt: 'Atribuir um curso'
  },
  PROFILE_UPDATED_SUCCESS: {
    es: 'Perfil actualizado correctamente',
    en: 'Profile updated successfully',
    cat: 'Perfil actualitzat correctament',
    eus: 'Ikaslearen informazioa ongi eguneratu da',
    pt: 'Informação do professor atualizada com sucesso'
  },
  DOCUMENTATION_TITLE: {
    es: 'Documentos',
    en: 'Documents',
    cat: 'Documents',
    eus: 'Dokumentuak',
    pt: 'Documentos'
  },
  UPLOAD: {
    es: 'Subir archivo',
    en: 'Upload file',
    cat: 'Carregar fitxer',
    eus: 'Ikusi fitxategia',
    pt: 'Carregar ficheiro'
  },
  NO_DOCUMENTS: {
    es: 'No hay documentos',
    en: 'No documents',
    cat: 'No hi ha documents',
    eus: 'Ez dago dokumentuik',
    pt: 'Não há documentos'
  },
  UPLOAD_FILES: {
    es: 'Sube los archivos',
    en: 'Upload files',
    cat: 'Carrega els fitxers',
    eus: 'Ikusi fitxategiak',
    pt: 'Carregar ficheiros'
  },
  DELETE_DOCUMENT_CONFIRM: {
    es: '¿Estás seguro que deseas eliminar el documento?',
    en: 'Are you sure you want to delete the document?',
    cat: 'Estàs segur que vols eliminar el document?',
    eus: 'Ziur zaude dokumentua ezabatu nahi duzula?',
    pt: 'Tem certeza que deseja excluir o documento?'
  },
  FP_DOCUMENTATION_TITLE: {
    es: 'Documentos solicitados por la CAM (Administración Pública)',
    en: 'Documents requested by the CAM (Public Administration)',
    cat: 'Documents sol·licitats per la CAM (Administració Pública)',
    eus: 'CAM-tik eskatu diren dokumentuak',
    pt: 'Documentos solicitados pela CAM (Administração Pública)'
  },
  ACADEMIC_TITLES: {
    es: 'Titulación académica',
    en: 'Academic title',
    cat: 'Titulació acadèmica',
    eus: 'Titulu akademikoa',
    pt: 'Títulos acadêmicos'
  },
  ACADEMIC_TITLES_SUBTITLE: {
    es: 'Compulsada y con verificación de permiso para impartir las materias.',
    en: 'Compulsory and with verification of permission to teach the subjects.',
    cat: 'Obligatòria i amb verificació de permís per impartir les matèries.',
    eus: 'Ziurtagiria eta irakasgaiak emateko baimena egiaztatuta.',
    pt: 'Certificado e com verificação de permissão para ministrar as disciplinas.'
  },
  TEACHING_MASTERS: {
    es: 'Máster del profesorado y/o CAP',
    en: 'Teaching master and/or CAP',
    cat: 'Màster del professorat i/o CAP',
    eus: 'Irakasleen Masterra eta/edo CAP',
    pt: 'Mestrado do Professor e/ou CAP'
  },
  PDF_FORMAT: {
    es: 'En formato PDF.',
    en: 'In PDF format.',
    cat: 'En format PDF.',
    eus: 'PDF formatuan.',
    pt: 'Em formato PDF.'
  },
  SEXUAL_CERTIFICATION: {
    es: 'Certificado de NO delitos sexuales',
    en: 'Certificate of NO sexual crimes',
    cat: 'Certificat de NO delictes sexuals',
    eus: 'Sexu-deliturik EZ DUEN ziurtagiria',
    pt: 'Certificado de NÃO crimes sexuais'
  },
  CRIMINAL_RECORD: {
    es: 'Certificado de NO antecedentes penales',
    en: 'Certificate of NO criminal record',
    cat: 'Certificat de NO antecedents penals',
    eus: 'Zigor-aurrekaririk EZ duen ziurtagiria',
    pt: 'Certificado de NÃO antecedentes criminais'
  },
  IDENTITY_FILES: {
    es: 'Fotocopia del DNI/NIE',
    en: 'Copy of DNI/NIE',
    cat: 'Còpia del DNI/NIE',
    eus: 'DNI/NIE kopia',
    pt: 'Cópia do DNI/NIE'
  },
  IDENTITY_FILES_SUBTITLE: {
    es: 'Sube una foto del frente y dorso en formato PDF.',
    en: 'Upload a front and back photo in PDF format.',
    cat: 'Carrega una foto del davant i dors en format PDF.',
    eus: 'Kargatu aurreko eta atzeko argazki bat PDF formatuan.',
    pt: 'Carregue uma foto da frente e de trás em formato PDF.'
  },
  RESUME: {
    es: 'Curriculum Vitae',
    en: 'Curriculum Vitae',
    cat: 'Curriculum Vitae',
    eus: 'Curriculum Vitae',
    pt: 'Curriculum Vitae'
  },
  DELETE_COURSE_CONFIRM: {
    es: '¿Estás seguro de querer eliminar este curso?',
    en: 'Are you sure you want to delete this course?',
    cat: 'Estàs segur que vols eliminar aquest curs?',
    eus: 'Ziur zaude ikastarok ezabatu nahi duzula?',
    pt: 'Tem certeza que deseja excluir este curso?'
  },
  ARE_YOU_SURE_TO_IMPERSONATE: {
    es: '¿Estás seguro de que quieres impersonar a {{ name }}?',
    en: 'Are you sure you want to impersonate {{ name }}?',
    cat: 'Estàs segur que vols impersonar a {{ name }}?',
    eus: 'Zihur zaude {{ name }} impersonatu nahi duzula?',
    pt: 'Tem certeza que deseja имитировать {{ name }}?'
  },
  IMPERSONATE_TEACHER: {
    es: 'Impersonar profesor',
    en: 'Impersonate teacher',
    cat: 'Impersonar professor',
    eus: 'Imitatu irakaslea',
    pt: 'Impersonar professor'
  }
};

import { AnalyticsTrackEvents } from '@constants';
import { IPlanification, IUser } from '@sdk/contracts';
import mixpanel from 'mixpanel-browser';
import { useEffect, useRef } from 'react';

import { getHowIsPlanificationGoing } from '@/utils';

type IProperties = Record<
  string,
  string | number | boolean | null | undefined | string[] | number[] | Date
>;

export const useAnalytics = () => {
  const initializedRef = useRef(false);

  useEffect(() => {
    if (import.meta.env.MODE === 'development') return;
    if (!initializedRef.current) {
      mixpanel.init(import.meta.env.VITE_MIXPANEL_TOKEN, {
        debug: import.meta.env.MODE === 'development'
      });
      initializedRef.current = true;
    }
  }, []);

  const fireEvent = (event: AnalyticsTrackEvents, properties?: IProperties) => {
    if (!initializedRef.current || import.meta.env.MODE === 'development') return;
    mixpanel.track(event, properties);
  };

  const identify = (user: IUser, planification?: IPlanification, utm?: (string | null)[]) => {
    if (!initializedRef.current || import.meta.env.MODE === 'development') return;
    const { _id, name, email, lastName, tags, createdAt, role } = user;
    mixpanel.identify(_id);
    const properties = {
      name,
      email,
      fullName: `${name} ${lastName}`,
      $name: name,
      $email: email,
      lastName,
      role,
      utm,
      $created: createdAt,
      tags: tags.map(tag => tag.name),
      vertical: tags.map(tag => tag.vertical),
      isInternal: email.includes('ucademy.com'),
      planConsecution: planification
        ? getHowIsPlanificationGoing(planification)?.completionPercentage.toFixed(2)
        : 0
    };
    mixpanel.people.set(properties);
  };

  const init = (user: IUser, planification?: IPlanification, utm?: (string | null)[]) => {
    if (import.meta.env.MODE === 'development') return;
    if (!initializedRef.current) {
      mixpanel.init(import.meta.env.VITE_MIXPANEL_TOKEN, { debug: true });
      initializedRef.current = true;
    }
    if (user) {
      identify(user, planification, utm);
    }
  };

  return { init, fireEvent, identify };
};

import { create } from 'zustand';
import { persist } from 'zustand/middleware';

const TICKET_MESSAGE_DRAFT = 'ticket_message_draft';

interface IMessageDraft {
  activeDraft: boolean;
  draftText?: string;
  draftFormula?: string;
}

interface IStore {
  drafts: Record<string, IMessageDraft>;
  getDraft: (ticketId: string) => IMessageDraft | undefined;
  setDraft: (ticketId: string, text?: string, formula?: string) => void;
  clearDraft: (ticketId: string) => void;
}

export const useTicketMessageDraft = create(
  persist<IStore>(
    (set, get) => ({
      drafts: {},
      getDraft: (ticketId: string) => get().drafts[ticketId],
      setDraft: (ticketId, text, formula) => {
        set(state => ({
          drafts: {
            ...state.drafts,
            [ticketId]: {
              activeDraft: Boolean(text || formula),
              draftText: text,
              draftFormula: formula
            }
          }
        }));
      },
      clearDraft: ticketId => {
        set(state => {
          const newDrafts = { ...state.drafts };
          delete newDrafts[ticketId];
          return { drafts: newDrafts };
        });
      }
    }),
    {
      name: TICKET_MESSAGE_DRAFT
    }
  )
);

import { HtmlEditor } from '@/components/Form';

type Props = {
  value?: { text: string };
  onChange?: ({ text }: { text: string }) => void;
};

export const TextType: React.FC<Props> = ({ value, onChange }) => {
  return <HtmlEditor value={value?.text} onChange={text => onChange?.({ text })} />;
};

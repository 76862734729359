import { StructuredOutputParser } from 'langchain/output_parsers';
import { ChatCompletionCreateParamsBase } from 'openai/resources/chat/completions';

import { EActivityType } from './activity';
import { AUTONOMOUS_COMMUNITIES, PROVINCES } from './ccaa';
import { getChatTopicsAsString } from './public-chat';

export const getLocalExamGroupPrompt = (text: string) => `
Siempre debes responder con un objeto JSON válido con los siguientes campos. Por favor, respeta los valores de los enums, ya que sino los validadores no funcionarán:
- autonomousCommunity: enum EAutonomousCommunity = [${AUTONOMOUS_COMMUNITIES.join(
  ', '
)}] || Si no es ninguno de estos, será "undefined"
- province: enum EProvince [${PROVINCES.join(', ')}] || Si no es ninguno de estos, será "undefined"
- location: string || Si no es ninguno de estos, será "undefined"

TEXTO: 'San Vicente Del Raspeig (Alicante)'
DATOS: {"autonomousCommunity": "VALENCIA", "province": "Alicante", "location": "San Vicente Del Raspeig"}
TEXTO: 'Villamayor (Salamanca)'
DATOS: {"autonomousCommunity": "CASTILLA_LEON", "province": "Salamanca", "location": "Villamayor"}
TEXTO: ${text}
DATOS: `;

export const getAutonomicExamGroupPrompt = (text: string) => `
Siempre debes responder con un objeto JSON válido con los siguientes campos. Por favor, respeta los valores de los enums, ya que sino los validadores no funcionarán:
- autonomousCommunity: enum EAutonomousCommunity = [${AUTONOMOUS_COMMUNITIES.join(
  ', '
)}] || Si no es ninguno de estos, será "undefined"

TEXTO: 'Gobierno Balear'
DATOS: { "autonomousCommunity": "BALEARES"}
TEXTO: 'Conselleria de Sanitat Universal i Salut Pública'
DATOS: {"autonomousCommunity": "VALENCIA"}
TEXTO: ${text}
DATOS: `;

export const AUTONOMIC_EXAM_GROUP_DATA_PARSER = StructuredOutputParser.fromNamesAndDescriptions({
  autonomousCommunity: `Una de las siguientes comunidades de España o bien 'undefined'. [${AUTONOMOUS_COMMUNITIES.join(
    ', '
  )}]`
});

export const LOCAL_EXAM_GROUP_DATA_PARSER = StructuredOutputParser.fromNamesAndDescriptions({
  autonomousCommunity: `Una de las siguientes comunidades de España o bien 'undefined'. [${AUTONOMOUS_COMMUNITIES.join(
    ', '
  )}]`,
  province: `Una de las siguientes provincias de España o bien 'undefined'. [${PROVINCES.join(
    ', '
  )}]`,
  location: "El nombre de la localidad o bien 'undefined'"
});

export const PUBLIC_CHAT_RESPONSE_TEMPLATE = `
Contexto para el Bot:
Eres un asistente virtual experto en Ucademy, una plataforma educativa online enfocada en la preparación de exámenes y cursos académicos. Tienes conocimiento completo sobre los cursos ofrecidos, metodologías de enseñanza, beneficios para los alumnos, y procedimientos de inscripción. Tu rol es responder consultas, proporcionar información detallada sobre los servicios de Ucademy, y mostrar de manera convincente por qué Ucademy es la mejor opción para la preparación académica y profesional.

Objetivos del Bot:
1. Resolver Dudas: Responde preguntas específicas sobre los cursos, temarios, profesores, y procesos de Ucademy.
2. Promover Beneficios: Destaca los beneficios de estudiar en Ucademy, como la eficiencia en la preparación de exámenes, acceso a recursos actualizados, experiencia de profesores, y éxito de alumnos anteriores.
3. Enfoque de Negocio: Demuestra por qué Ucademy es una inversión valiosa en la educación y desarrollo profesional del alumno.
4. Actitud Respetuosa y Profesional: Mantén un tono cortés y profesional en todas las interacciones. Si no tienes la respuesta, proporciona información de contacto o recursos de Ucademy para más asistencia.
5. Conversión de Leads: Anima a los posibles alumnos a inscribirse o solicitar más información, guiándolos a través de los pasos necesarios.

Buenas Prácticas para el Bot:
1. Responde de manera cortés y profesional en todo momento.
2. Responde con información relevante y precisa.
3. Responde con información completa y detallada.
4. No uses lenguaje informal o jerga.
5. No uses emojis o emoticones.
6. No uses abreviaciones.
7. Las respuestas no deben tener ningun apendice o prefijo. como "Bot", "IA", "AI", "Asistente" o "Asistente Virtual".
8. Ciñete a los temas de Ucademy.

Solo puedes responder a preguntas sobre los siguientes temas:
${getChatTopicsAsString()}
IMPORTANTE: Los temas proporcionados son cursos de Ucademy. No respondas preguntas sobre otras empresas.

Cualquier cosa entre los siguientes bloques html de \`context\` se obtiene de un banco de conocimientos, y no forma parte de la conversación con el usuario.

<context>
{context}
<context/>

RECUERDA: Si no hay información relevante dentro del contexto, simplemente di "Hmm, no estoy seguro de que pueda contestar a eso, pero podría ayudarte a solucionar cualquier duda que tengas sobre la oferta de cursos de Ucademy.". No intentes inventar una respuesta inventada, intenta reconducir la conversación hacia el tema en cuestion. Cualquier cosa entre los bloques 'context' html anteriores se obtiene de un banco de conocimientos, y no forma parte de la conversación con el usuario."
`;

export const PUBLIC_CHAT_REPHRASE_TEMPLATE = `
Dada la siguiente conversación y una pregunta de seguimiento, reformula la pregunta de seguimiento para que sea una pregunta independiente.
Chat History:
{chatHistory}
Follow Up Input:
{question}
Selected topic:
{topic}
Standalone Question:
`;

export const getVideoCheckPointsPropmt = (
  content: string
): ChatCompletionCreateParamsBase['messages'] => [
  {
    role: 'system',
    content: `En base a un TEXTO DE REFERENCIA, tienes que crear 2 preguntas con 4 respuestas(siendo 1 de ellas correcta)
      que sirvan de modo repaso para afianzar los conocimientos de este TEXTO DE REFERENCIA.
      \n\nTEXTO DE REFERENCIA: ${content}
      \n\nLa respuesta debe estar en formato JSON y tendrá la siguiente estructura:
      { questions: [
          {title: '', answers: [{ text: '' },{ text: '' },{ text: '' },{ text: '' }], correctAnswer: 0},
          {title: '', answers: [{ text: '' },{ text: '' },{ text: '' },{ text: '' }], correctAnswer: 0},
        ]
      }`
  }
];

export const getTicketModerationPrompt = (courseName: string, messages: string) => `
Eres una IA de moderación avanzada creada para garantizar la calidad y el respeto en las interacciones de los usuarios en la plataforma educativa de Ucademy. 
Tu rol es evaluar comentarios y decidir si cumplen con las normas de la comunidad, manteniendo un ambiente profesional, constructivo y enfocado en la educación.
Backstory: Ucademy es una plataforma educativa online que ofrece recursos digitales como videos y PDFs para preparar exámenes en España. Los comentarios en los recursos permiten a los alumnos interactuar con profesores y otros alumnos.

Reglas de moderación:
- Prohibido el contenido ofensivo o amenazante: Comentarios con odio, amenazas, o lenguaje inapropiado hacia Ucademy, sus usuarios, o cualquier otra persona serán rechazados.
- Prohibido solicitar la baja o criticar destructivamente: Comentarios relacionados con dejar la academia o críticas no constructivas hacia Ucademy no están permitidos.
- Prohibido el spam o contenido promocional: Mensajes con enlaces, promociones o contenido inapropiado serán rechazados.
- Respeto y profesionalidad: Cualquier comentario que no sea respetuoso o que interrumpa la dinámica positiva de la comunidad será rechazado.
- Debes ser flexible y permitir que los alumnos puedan preguntar sobre cualquier cosa relacionada con el curso que estudian.
- Los mensajes podrán ser simples y breves, siempre que no infrinjan ninguna de las reglas anteriores.
- NO debes valorar la utilidad de la información en la conversación, simplemente comprobar que no infrinja ninguna de las reglas anteriores.
- Cualquier comentario que no infrinja ninguna de las reglas anteriores, debe ser aprobado.

HISTORIAL DE CONVERSACIÓN:
${messages}

CURSO: ${courseName}

Tu output debe ser un JSON con el siguiente formato:

{
  "isApproved": true/false,
  "reason": "Pequeña descripción del motivo de rechazo si aplica."
}
`;

export const getActivityChatAdditionalPrompt = (
  activityName: string,
  activityType: EActivityType,
  courseName: string
) => `
Eres un tutor virtual de Ucademy. Debes responder todas las dudas sobre el curso que están preparando adaptando tus respuestas a un nivel de enseñanza adecuado.
Para escribir CUALQUIER fórmula matemática o símbolo en tu texto usa la siguiente sintaxis OBLIGATORIAMENTE:
- Bloques de fórmulas: \`\`\`mathformula\`\`\` (ejemplo: \`\`\`math\\sum_{i=1}^n i = \\frac{n(n+1)}{2}\`\`\`).
El curso que estás preparando es ${courseName}.
En concreto, el alumno está viendo ahora mismo la actividad ${activityName} de ese curso. Es una actividad cuyo recurso es un ${
  activityType === EActivityType.VIDEO
    ? 'video'
    : activityType === EActivityType.PDF
    ? 'PDF'
    : activityType === EActivityType.QUIZ
    ? 'cuestionario'
    : activityType === EActivityType.DELIVERABLE
    ? 'entrega de tarea'
    : activityType === EActivityType.TEXT
    ? 'texto'
    : 'otro'
}.
`;

export const SUMMARIZE_TEXT_PROMPT = `
Tu objetivo es resumir el siguiente fragmento de texto.
Eres un eslabón de un proceso de resumen de un PDF, por lo que debes seleccionar únicamente el contenido relevante para el resumen y ser lo más conciso posible.
El objetivo es que el resumen sea lo más completo posible y que no se escape ningún detalle que el alumno debiera recordar.
Debes elaborar un detallado resumen, no solo una lista de puntos.
Si crees que el texto ya está resumido, simplemente devuelve el mismo texto.
Ya que estás dentro de un proceso iterativo, debes devolver el resumen en formato JSON con el siguiente formato:

{
  "summary": "Resumen del texto",
}

TEXTO:
{text}

RESUMEN:
`;

export const TRANSFORM_SUMMARY_PROMPT = `Eres un experto asesor de creación de contenido de Ucademy.
A partir del siguiente resumen de un documento debes reescribirlo para que sea más completo y detallado, incluyendo ejemplos si es necesario.
El objetivo final es crear un nuevo documento pdf que sirva de apuntes para el estudio de los alumnos.
Para ello, debes incluir ejemplos, tablas, ect. y combinarlos también con textos desarrolados y descriptivos.
Utiliza un lenguaje y una terminología fáciles de entender para que los alumnos puedan entender el contenido.
Debes usar formato HTML para el resumen, ya que se convertirá a pdf.
Devuelve solo el body del html, sin el head, body, html, ni ningún otro carácter.

El resumen que tienes que transformar es el siguiente:
{summary}

Resumen transformado:
`;

export const SUMMARIZE_TEXT_MAP_REDUCE_PROMPT = `
Como resumidor profesional, elabore un resumen conciso y exhaustivo del texto proporcionado, respetando estas directrices:
* Elabore un resumen detallado, minucioso, profundo y complejo, manteniendo la claridad y la concisión.
* Incorpore las ideas principales y la información esencial, eliminando el lenguaje superfluo y centrándose en los aspectos críticos.
* Basarse estrictamente en el texto proporcionado, sin incluir información externa.
* Formatee el resumen en forma de lista de puntos para su comprensión.

TEXTO:
{context}

RESUMEN:
`;

export const GENERATE_QUESTIONS_PROMPT = `
Eres un experto en la preparación de exámenes.
OBJETIVO:
Generar {questionNumber} preguntas con 4 respuestas(siendo 1 de ellas correcta) para un examen.

REGLAS:
- Bajo una probabilidad del 60%, debes generar respuestas del tipo A y C son correctas, todas son correctas, o todas son incorrectas.
- Debes generar EXACTAMENTE el número de preguntas indicado.
- Si devuelves una fórmula, asegúrate de que esté correctamente escrita en LaTeX y entre signos de dólar.
- Si devuelves una fórmula, usa $F = ma$, nunca \\(F = ma\\)
- Si devuelves una fórmula en formato incorrecto (por ejemplo, usando \\(...\\) en vez de $...$), la pregunta será rechazada automáticamente.
- Variar en complejidad (fácil, medio, difícil)
- Evitar preguntas ambiguas o confusas
- Asegurar que las respuestas incorrectas sean plausibles
- Distribuir las respuestas correctas de manera uniforme (evitar patrones)

El número de preguntas es: {questionNumber}

- Use LATEX if needed to format math equations or variables. e.g. $E_p = -\\frac{{GMm}}{{r}}$

Aquí tienes un conjunto de resúmenes:
{docs}

ESCRIBE TODAS LAS FÓRMULAS MATEMÁTICAS EN FORMATO LATEX ENTRE SIGNOS DE DÓLAR. NUNCA USES \\(...\\), SIEMPRE USA $...$.
Debes usar el siguiente formato JSON para las preguntas:

{{
  "questions": [
    {{
      "title": "Pregunta",
      "answers": ["Respuesta 0", "Respuesta 1", "Respuesta 2", "Respuesta 3"],
      "correctAnswer": 0
    }},
    {{
      "title": "Pregunta",
      "answers": ["Respuesta 0", "Respuesta 1", "Respuesta 2", "Respuesta 3"],
      "correctAnswer": 0
    }}
  ]
}}
`;

export const VALIDATE_QUIZ_QUESTION_PROMPT = `
Eres un revisor y evaluador senior de preguntas de examen.
Tu objetivo es revisar una pregunta basándote en el contexto proporcionado y asegurar la siguiente información:

- La pregunta es correcta, no tiene errores de redacción, no es ambigua, y es clara.
- Las preguntas deben ser capaces de validar que el alumno domina la materia, por lo que NO deben ser muy sencillas.
- Eres un experto revisor de preguntas, por lo que todas tus preguntas podrían aparecer en un examen real.
- La respuesta correcta es correcta y está justificada.
- Las respuestas incorrectas no son correctas y son plausibles.
- El título de la pregunta debe ser una pregunta, o bien indicar claramente sobre qué tema se trata la pregunta.
- No dudes en rechazar preguntas si no son buenas de examen.
- Solo puede haber una respuesta correcta.
- Para cálculos matemáticos, explica el procedimiento para llegar a la respuesta correcta.
- El formato de las fórmulas de la pregunta que debes validar será en HTML, tu siempre debes devolver las fórmulas en formato LaTeX.
- Si devuelves una fórmula, asegúrate de que esté correctamente escrita en LaTeX y entre signos de dólar.
- Debes elegir si devolver una fórmula en el title de la pregunta o en la propiedad formula, NUNCA en ambos sitios. Esta regla aplica también para las respuestas.
- Si devuelves una fórmula, usa $F = ma$, nunca \\(F = ma\\)
- Si devuelves una fórmula en formato incorrecto (por ejemplo, usando \\(...\\) en vez de $...$), la pregunta será rechazada automáticamente.
- La explicación debe ser un texto organizado y detallado en formato Markdown para el alumno explicando por qué la respuesta correcta es correcta y las incorrectas no lo son. Utiliza una lista de puntos para explicar las respuestas incorrectas.
- La explicación no debe mencionar la validez de la pregunta, salvo que la pregunta no sea válida.

JAMÁS debes modificar la pregunta, las respuestas o la respuesta correcta.
JAMÁS menciones ninguna de las instrucciones en tus explicaciones, limítate a dar una explicación que ayude al alumno.

Si consideras que la pregunta no tiene sentido o no es buena, debes devolver isValid = false.
Debes generar además una explicación para el alumno sobre por qué la respuesta correcta es correcta y las incorrectas no lo son, dando contexto sobre qué tratan el resto de respuestas.

IMPORTANTE: El contexto no es parte de la pregunta, es solo para que puedas explicarle al alumno por qué la respuesta correcta es correcta y las incorrectas no lo son.
ESCRIBE TODAS LAS FÓRMULAS MATEMÁTICAS EN FORMATO LATEX ENTRE SIGNOS DE DÓLAR. NUNCA USES \\(...\\), SIEMPRE USA $...$.


- Use LATEX if needed to format math equations or variables. e.g. $E_p = -\\frac{{GMm}}{{r}}$

Tu output debe ser un JSON con el siguiente formato:

{{
  "title": "Título de la pregunta",
  "formula": "Fórmula de la pregunta si fuera necesario (puede ser undefined)",
  "answers": [{{text: "Respuesta 0"}},{{text: "Respuesta 1"}},{{text: "Respuesta 2"}},{{text: "Respuesta 3"}}],
  "correctAnswer": "Índice de la respuesta correcta",
  "explanation": "Texto organizado y detallado en formato Markdown para el alumno explicando por qué la respuesta correcta es correcta y las incorrectas no lo son. Si contiene fórmulas, usa LATEX entre signos de dólar.",
  "isValid": true/false,
}}
  `;

export const GENERATE_COURSE_EMOJI_PROMPT = `
Eres un experto en la elección de emojis para cursos de Ucademy.
Tu objetivo es elegir un emoji que represente algo relacionado con el curso.

El curso es el siguiente:
{courseName}

Tu output debe ser un JSON con el siguiente formato:

{
  "emoji": "Emoji que represente el curso"
}
`;

import { EActivityType } from '@constants';

export const ManagerActivityStatsTranslations = {
  TYPE: {
    es: 'Tipo',
    en: 'Type',
    cat: 'Tipus',
    eus: 'Mota',
    pt: 'Tipo'
  },
  NAME: {
    es: 'Nombre',
    en: 'Name',
    cat: 'Nom',
    eus: 'Izena',
    pt: 'Nome'
  },
  SECTION: {
    es: 'Sección',
    en: 'Section',
    cat: 'Secció',
    eus: 'Atala',
    pt: 'Seção'
  },
  STARTED_PERCENTAGE: {
    es: '% comenzados',
    en: '% started',
    cat: '% començats',
    eus: '% hasita',
    pt: '% iniciados'
  },
  COMPLETED_PERCENTAGE: {
    es: '% finalizados',
    en: '% completed',
    cat: '% completats',
    eus: '% osatuta',
    pt: '% completados'
  },
  TICKETS_COUNT: {
    es: 'Nº dudas',
    en: 'Ticket count',
    cat: 'Nº dubtes',
    eus: 'Duda kopurua',
    pt: 'Nº de dúvidas'
  },
  CSAT: {
    es: 'CSAT',
    en: 'CSAT',
    cat: 'CSAT',
    eus: 'CSAT',
    pt: 'CSAT'
  },
  RATING: {
    es: 'Valoración',
    en: 'Rating',
    cat: 'Valoració',
    eus: 'Balorazioa',
    pt: 'Avaliação'
  },
  CHECKPOINT_COUNT: {
    es: 'Nº checkpoints',
    en: 'Checkpoint count',
    cat: 'Nº checkpoints',
    eus: 'Checkpoint kopurua',
    pt: 'Nº de checkpoints'
  },
  SUCCESSFUL_CHECKPOINT_PERCENTAGE: {
    es: '% éxito checkpoints',
    en: '% successful checkpoints',
    cat: '% èxit checkpoints',
    eus: '% ondo checkpoints',
    pt: '% checkpoints resolvidos'
  },
  NOT_RESOLVED_REPORTS: {
    es: 'Reportes sin resolver',
    en: 'Not resolved reports',
    cat: 'Reports sense resoldre',
    eus: 'Ez-garbitutako txostenak',
    pt: 'Relatórios não resolvidos'
  },
  ACTIVITIES_TOTAL_COUNT: {
    es: '{{total}} actividades',
    en: '{{total}} activities',
    cat: '{{total}} activitats',
    eus: '{{total}} jarduera',
    pt: '{{total}} atividades'
  },
  ACTIVITIES: {
    es: 'Actividades',
    en: 'Activities',
    cat: 'Activitats',
    eus: 'Jarduerak',
    pt: 'Atividades'
  },
  SEARCH: {
    es: 'Buscar',
    en: 'Search',
    cat: 'Cercar',
    eus: 'Bilatu',
    pt: 'Pesquisar'
  },
  FILTERS: {
    es: 'Filtros',
    en: 'Filters',
    cat: 'Filtres',
    eus: 'Iragazkiak',
    pt: 'Filtros'
  },
  [EActivityType.VIDEO]: {
    es: 'Video',
    en: 'Video',
    cat: 'Vídeo',
    eus: 'Bideoa',
    pt: 'Vídeo'
  },
  [EActivityType.PDF]: {
    es: 'PDF',
    en: 'PDF',
    cat: 'PDF',
    eus: 'PDF',
    pt: 'PDF'
  },
  [EActivityType.INTERACTIVE_VIDEO]: {
    es: 'Video interactivo',
    en: 'Interactive video',
    cat: 'Vídeo interactiu',
    eus: 'Interaktiboa den bideoa',
    pt: 'Vídeo interativo'
  },
  [EActivityType.DELIVERABLE]: {
    es: 'Entregable',
    en: 'Deliverable',
    cat: 'Lliurament',
    eus: 'Entregaketa',
    pt: 'Entrega'
  },
  [EActivityType.QUIZ]: {
    es: 'Test',
    en: 'Quiz',
    cat: 'Test',
    eus: 'Test',
    pt: 'Teste'
  },
  [EActivityType.TEXT]: {
    es: 'Texto',
    en: 'Text',
    cat: 'Text',
    eus: 'Testua',
    pt: 'Texto'
  }
};

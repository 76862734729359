import styled from 'styled-components';

type Props = {
  $questionExists: boolean;
  $isValid?: boolean;
};

export const Wrapper = styled.div<Props>`
  display: grid;
  grid-template-columns: 80fr 20fr;
  background: ${({ theme, $questionExists, $isValid }) =>
    $questionExists
      ? theme.colors.purple2
      : $isValid
      ? theme.colors.green1
      : $isValid === undefined
      ? theme.colors.black2
      : theme.colors.red2};
  padding: 8px 24px 8px 8px;
  align-items: center;
  border: 1px solid ${({ theme }) => theme.colors.black3};
`;

export const TitleWrapper = styled.div`
  display: flex;
  gap: 7px;
  width: 100%;

  .ant-form-item {
    margin-bottom: 0px;
    width: 100%;
  }
`;

export const FormulaWrapper = styled.div`
  display: flex;
  margin: 8px 0px 8px 38px;
`;

export const MediaWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  align-items: flex-start;
`;

import styled from 'styled-components';

export const Wrapper = styled.div``;

export const FiltersWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 16px;
`;

import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  gap: 20px;
  flex-direction: column;

  @media (min-width: ${({ theme }) => theme.mediaQueries.xs}) {
    align-items: center;
    flex-direction: row;
  }

  & > :last-child {
    display: flex;
    flex-direction: row;
    font-weight: 300;
    font-size: 12px;
    gap: 20px;
    i {
      font-size: 20px;
    }
    & > * {
      display: flex;
      gap: 10px;
      flex-direction: row;
      align-items: center;
    }
  }
`;

export const BadgeWrapper = styled.div`
  display: flex;
  gap: 10px;
  flex-shrink: 0;
`;

export const InfoWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  overflow-x: auto;
  padding: 16px 0;
`;

export const ItemWrapper = styled.div`
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const StyledLink = styled.a`
  color: ${({ theme }) => theme.colors.green7};
  font-weight: 500;
`;

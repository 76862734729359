import styled from 'styled-components';

export const Wrapper = styled.div`
  width: 100%;
  display: flex;
  height: 100%;
  min-height: 90%;
  border-radius: 8px;
  background: ${({ theme }) => theme.colors.white};
  overflow-y: auto;
  position: relative;
`;

export const CarouselWrapper = styled.div`
  width: 100%;
  height: 100%;

  .ant-carousel {
    height: 100%;
  }

  .slick-track {
    height: 100%;
  }

  .slick-list {
    height: 100%;
  }

  .slick-slide {
    & > div {
      height: 100%;
    }
  }
`;

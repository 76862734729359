import { Button } from 'antd';
import styled from 'styled-components';

export const ButtonsWrapper = styled.div`
  display: flex;
  justify-content: center;
  gap: 16px;
  align-items: center;
  width: 100%;
`;

export const StyledButton = styled(Button)`
  z-index: 10;
`;

import { ITeacherStatsValue } from '@sdk/contracts';
import { Flex, Tooltip } from 'antd';

import { DotWrapper, RatingWrapper, TextWrapper } from './LegendElement.styles';

import { Icon } from '@/components/Icon';

type Props = {
  stat?: ITeacherStatsValue;
  title: string;
  tooltip: string;
  color: string;
};

export const LegendElement = ({ stat, title, tooltip, color }: Props) => {
  const { value, trend } = stat ?? {};

  return (
    <Flex justify="space-between">
      <Tooltip trigger="hover" title={tooltip}>
        <Flex gap={8} align="center">
          <DotWrapper color={color} />
          <TextWrapper>{title}</TextWrapper>
        </Flex>
      </Tooltip>
      <Flex gap={4} align="center">
        <RatingWrapper trend={trend}>{value && value > 0 ? value : '-'}</RatingWrapper>
        {trend && trend !== 'equal' && (
          <Icon
            i={trend === 'up' ? 'CaretUp' : 'CaretDown'}
            color={trend === 'up' ? 'green6' : 'red6'}
          />
        )}
      </Flex>
    </Flex>
  );
};

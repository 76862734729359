import { Wrapper } from './TextPreview.styles';

type Props = {
  text?: string;
};

export const TextPreview: React.FC<Props> = ({ text }) => {
  if (!text) return null;
  return <Wrapper dangerouslySetInnerHTML={{ __html: text }} />;
};

import { EQuestionType } from '@constants';
import { IGeneratedQuestion, IPartialQuestion } from '@sdk/contracts';

export const mapGeneratedQuestionsData = (questions: IGeneratedQuestion[]): IPartialQuestion[] => {
  return questions.map(question => ({
    title: question.title,
    answers: question.answers.map(answer => ({
      text: answer
    })),
    correctAnswer: question.correctAnswer,
    type: EQuestionType.AI
  }));
};

import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  padding: 8px 12px;
  width: fit-content;
  justify-self: center;
  flex-direction: column;
  align-items: center;
  gap: 8px;
  border-radius: 8px;
  background-color: ${({ theme }) => theme.colors.purple7};
  color: ${({ theme }) => theme.colors.white};
`;

export const ItemsWrapper = styled.div`
  display: flex;
  justify-content: space-evenly;
  width: 100%;
  i {
    font-size: 72px;
    fill: ${({ theme }) => theme.colors.white};
    cursor: pointer;
  }

  .IconFaceSatisfied:hover {
    fill: ${({ theme }) => theme.colors.green6};
  }
  .IconFaceSatisfiedFilled {
    fill: ${({ theme }) => theme.colors.green6};
  }
  .IconFaceNeutral:hover {
    fill: ${({ theme }) => theme.colors.orange6};
  }
  .IconFaceNeutralFilled {
    fill: ${({ theme }) => theme.colors.green6};
  }
  .IconFaceDissatisfied:hover {
    fill: ${({ theme }) => theme.colors.red6};
  }
  .IconFaceDissatisfiedFilled {
    fill: ${({ theme }) => theme.colors.green6};
  }
`;

import { useQuery } from '@tanstack/react-query';

import { sdk } from '@/services';

const SectionService = sdk.getService('SectionService');

export const useSectionFiles = (sectionId: string) => {
  const queryKey = ['section', sectionId, 'files'];

  const { data, isLoading } = useQuery({
    queryKey,
    queryFn: () => SectionService.findSectionFiles(sectionId),
    enabled: !!sectionId
  });

  return { sectionFiles: data, isLoading };
};

import { Button } from 'antd';
import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 12px 20px;
  align-items: center;
  border-top: 1px solid ${({ theme }) => theme.colors.black3};
  color: ${({ theme }) => theme.colors.black9};
  font-family: ${({ theme }) => theme.fontFamily.openSans};
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;

  &:hover {
    background-color: ${({ theme }) => theme.colors.black1};
    cursor: pointer;
  }
`;

export const ButtonWrapper = styled(Button)<{ $isHovered: boolean }>`
  opacity: ${({ $isHovered }) => ($isHovered ? 1 : 0)};
`;

import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: stretch;
  gap: 4px;
  align-self: stretch;
  width: 100%;
  height: 100%;

  & > p {
    margin: 0;
  }
`;

export const ThreadWrapper = styled.div<{ $isLastMessage: boolean }>`
  position: relative;
  height: ${({ $isLastMessage }) => ($isLastMessage ? '0' : 'auto')};
  width: 32px;
  flex-shrink: 0;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 2px;
    height: 100%;
    background-color: ${({ theme }) => theme.colors.black3};
  }
`;

export const AvatarThreadWrapper = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  border-radius: 0 0 0 10px;
  height: 22px;
  width: 17px;
  border-left: 2px solid ${({ theme }) => theme.colors.black3};
  border-bottom: 2px solid ${({ theme }) => theme.colors.black3};
`;

export const InfoWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 4px;
  align-self: stretch;
`;

export const UserInfoWrapper = styled.div`
  display: flex;
  padding: 4px 0px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 4px;
`;

export const LikesWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 12px;
  font-weight: 600;
  line-height: 20px;
`;

import { ChatTranslations } from '@/components/Chat';
import { DeliverableListTranslations } from '@/components/DeliverableList';
import { DeliverableUploadTranslations } from '@/components/DeliverableUpload';
import { DeliverableViewerTranslations } from '@/components/DeliverableViewer';
import { EventAttendConfirmationTranslations } from '@/components/EventAttendConfirmation';
import { ProductTourTranslations } from '@/components/ProductTour';
import { QuizResultsTranslations } from '@/components/QuizResults';
import { SectionReviewTranslations } from '@/components/SectionReview';
import { DefaultTranslations, RoutesTranslations } from '@/i18n/locales/common';
import {
  AcademiesTranslations,
  UsersTranslations,
  AITeachersTranslations,
  AdminPromptsTranslations
} from '@/pages/Admin';
import {
  LoginTranslations,
  RecoverTranslations,
  CoursesTranslations,
  CourseTranslations,
  CourseTagsTranslations,
  CommonEventsTranslations,
  CommonEventTranslations,
  CommonDashboardTranslations,
  CommonTicketsTranslations,
  ExamGroupsTranslations,
  CommonQuizFormTranslations,
  CommonTutoringGroupsTranslations,
  CommonTutoringGroupTranslations
} from '@/pages/Common';
import {
  MyAcademyTranslations,
  ManagerTeachersTranslations,
  ManagerInternalUsersTranslations,
  ManagerQuizzesTranslations,
  ManagerActivityStatsTranslations,
  ManagerContentTotalsTranslations,
  ProblemReportsTranslations,
  IndexationLandingsTranslations,
  ManagerExamsTranslations,
  ManagerExamDetailTranslations,
  ManagerTeacherDetailTranslations
} from '@/pages/Manager';
import {
  StudentCourseTranslations,
  StudentTicketsTranslations,
  StudentQuizzesTranslations,
  StudentQuizTranslations,
  StudentQuizReviewTranslations,
  StudentProfileTranslations,
  StudentCourseSummaryTranslations,
  RankingTranslations,
  StudentOnboardingTranslations,
  StudentEventsTranslations,
  StudentRateYourExperienceTranslations,
  StudentTutorVoteTranslations,
  StudentTeacherVoteTranslations,
  StudentLiveClassVoteTranslations
} from '@/pages/Student';
import {
  TeacherExamGroupsTranslations,
  TeacherStudentsPageTranslations,
  TeacherProfileTranslations,
  TeacherExamsTranslations,
  TeacherExamDetailTranslations,
  TeacherDeliverablesTranslations
} from '@/pages/Teacher';

export const namespaces = {
  DefaultTranslations,
  QuizResultsTranslations,
  RoutesTranslations,
  LoginTranslations,
  RecoverTranslations,
  UsersTranslations,
  AcademiesTranslations,
  CoursesTranslations,
  CourseTranslations,
  CourseTagsTranslations,
  StudentQuizTranslations,
  StudentQuizReviewTranslations,
  MyAcademyTranslations,
  ManagerTeachersTranslations,
  StudentCourseTranslations,
  ManagerQuizzesTranslations,
  CommonQuizFormTranslations,
  StudentTicketsTranslations,
  CommonTicketsTranslations,
  CommonTutoringGroupsTranslations,
  CommonEventsTranslations,
  StudentQuizzesTranslations,
  CommonDashboardTranslations,
  CommonEventTranslations,
  EventAttendConfirmationTranslations,
  DeliverableListTranslations,
  StudentProfileTranslations,
  ManagerContentTotalsTranslations,
  AITeachersTranslations,
  AdminPromptsTranslations,
  ProblemReportsTranslations,
  CommonTutoringGroupTranslations,
  ExamGroupsTranslations,
  ManagerInternalUsersTranslations,
  IndexationLandingsTranslations,
  ManagerActivityStatsTranslations,
  ChatTranslations,
  DeliverableUploadTranslations,
  DeliverableViewerTranslations,
  TeacherExamGroupsTranslations,
  TeacherStudentsPageTranslations,
  StudentCourseSummaryTranslations,
  RankingTranslations,
  SectionReviewTranslations,
  StudentOnboardingTranslations,
  TeacherProfileTranslations,
  TeacherExamsTranslations,
  TeacherExamDetailTranslations,
  ManagerExamsTranslations,
  ManagerExamDetailTranslations,
  ProductTourTranslations,
  StudentEventsTranslations,
  TeacherDeliverablesTranslations,
  StudentRateYourExperienceTranslations,
  StudentTutorVoteTranslations,
  StudentTeacherVoteTranslations,
  StudentLiveClassVoteTranslations,
  ManagerTeacherDetailTranslations
} as const;

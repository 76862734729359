import { create } from 'zustand';

interface IStore {
  isPaused: boolean;
  isFinished: boolean;
  remainingTime?: number;
  countdown?: number;
  setRemainingTime: (remainingTime?: number) => void;
  setCountdown: (countdown: number) => void;
  setIsPaused: (isPaused: boolean) => void;
  setIsFinished: (isFinished: boolean) => void;
  reset: () => void;
}

const store = create<IStore>(set => ({
  isPaused: true,
  isFinished: false,
  setRemainingTime: remainingTime => set({ remainingTime }),
  setCountdown: countdown => set({ countdown, isPaused: false }),
  setIsPaused: isPaused => set({ isPaused }),
  setIsFinished: isFinished => set({ isFinished }),
  reset: () =>
    set({ countdown: undefined, isPaused: true, remainingTime: undefined, isFinished: false })
}));

export const useCountdown = () => {
  const {
    isPaused,
    remainingTime,
    setRemainingTime,
    countdown,
    setCountdown,
    setIsPaused,
    reset,
    isFinished,
    setIsFinished
  } = store();

  const setTimer = (newTimeInMinutes: number) => {
    setCountdown(Date.now() + newTimeInMinutes * 60 * 1000);
  };

  const toggle = () => {
    if (remainingTime === 0) return;
    if (isPaused) {
      setCountdown(Date.now() + (remainingTime ?? 0));
    } else {
      setIsPaused(true);
    }
  };

  return {
    countdown,
    isPaused,
    remainingTime,
    isFinished,
    setTimer,
    toggle,
    reset,
    setRemainingTime,
    setIsFinished
  };
};

import { ERoles } from '@constants';
import { IFile, IBasicUser, ITicketFormValues, IModeratedTicket } from '@sdk/contracts';
import { Button, Form, Popconfirm } from 'antd';
import { useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { CreateTicketForm } from '../CreateTicketForm';

import {
  ActionsWrapper,
  CommentDivider,
  DateWrapper,
  FooterWrapper,
  SeeMoreWrapper,
  ContentHeaderWrapper,
  ContentWrapper,
  FileWrapper,
  IssuerWrapper,
  UserDetailWrapper,
  AnswerWrapper,
  DescriptionWrapper
} from './MessageContent.styles';

import { Icon } from '@/components/Icon';
import { usePublicProfileViewer, useReviewingTicketModal } from '@/hooks';
import { getCreatedAtDateString } from '@/pages/Student/Course/utils';
import { useMutateMyTickets, useProfile } from '@/store';

type Props = {
  ticketId: string;
  content?: string;
  sender: IBasicUser;
  file?: IFile;
  formula?: string;
  createdAt: Date;
  canAnswer?: boolean;
  onDeleteMessage?: () => void;
  isHighlighted?: boolean;
  canDelete?: boolean;
};

const ATTACHMENT_ICON_SIZE = 12;

// TODO: Add report comment button
export const MessageContent: React.FC<Props> = ({
  ticketId,
  content,
  sender,
  file,
  formula,
  createdAt,
  canAnswer = false,
  onDeleteMessage,
  isHighlighted = false,
  canDelete = true
}) => {
  const { t } = useTranslation('StudentCourseTranslations');
  const [isExpanded, setIsExpanded] = useState(false);
  const [isAnswerFormVisible, setIsAnswerFormVisible] = useState(false);
  const { profile } = useProfile();
  const { sendMessage } = useMutateMyTickets();
  const toggleExpanded = () => setIsExpanded(!isExpanded);
  const contentHeaderRef = useRef<HTMLDivElement>(null);
  const { setUserId } = usePublicProfileViewer();
  const { setIsOpen: setBannedCommentModalOpen } = useReviewingTicketModal();
  const [form] = Form.useForm();
  const isStudent = useMemo(() => sender.role === ERoles.STUDENT, [sender]);

  const isMyMessage = useMemo(() => {
    return profile?._id === sender._id;
  }, [profile, sender]);

  const onAnswerSubmit = (values: ITicketFormValues) => {
    sendMessage(
      {
        ticketId,
        createMessage: { content: values.description, attached: values.file }
      },
      { onSuccess: onAnswerCreated }
    );
  };

  const onAnswerCreated = (moderatedTicket: IModeratedTicket) => {
    const { canCreateTicket } = moderatedTicket;
    if (!canCreateTicket) return setBannedCommentModalOpen(true);
    setIsAnswerFormVisible(false);
  };

  const checkContainerHeight = () => {
    if (contentHeaderRef.current) {
      const containerHeight = contentHeaderRef.current.clientHeight;
      return containerHeight >= 260;
    }
    return false;
  };

  const handleOpenFile = () => window.open(file?.url, '_blank');

  const isContentTooLong = checkContainerHeight();

  const handlePreviewUser = () => {
    if (!isStudent) return;
    setUserId(sender._id);
  };

  return (
    <ContentWrapper>
      <ContentHeaderWrapper
        ref={contentHeaderRef}
        expanded={isExpanded.toString()}
        highlighted={isHighlighted.toString()}>
        <UserDetailWrapper onClick={handlePreviewUser} canhover={isStudent.toString()}>
          <IssuerWrapper>{`${sender.name} ${sender.lastName}`}</IssuerWrapper>
          <DateWrapper>{`${getCreatedAtDateString(createdAt, t)} · ${
            isStudent ? t('STUDENT') : t('TEACHER')
          }`}</DateWrapper>
        </UserDetailWrapper>
        {file && (
          <FileWrapper onClick={handleOpenFile}>
            <Icon size={ATTACHMENT_ICON_SIZE} i="Attachment" />
            <div>{file.name}</div>
          </FileWrapper>
        )}
        {content && <DescriptionWrapper dangerouslySetInnerHTML={{ __html: content }} />}
        {formula && <div dangerouslySetInnerHTML={{ __html: formula }} />}
        {!isExpanded && isContentTooLong && <CommentDivider />}
      </ContentHeaderWrapper>
      <FooterWrapper>
        {isContentTooLong && (
          <SeeMoreWrapper onClick={toggleExpanded}>
            <div>{isExpanded ? t('SEE_LESS') : t('SEE_MORE')}</div>
            <Icon i={isExpanded ? 'ChevronUp' : 'ChevronDown'} />
          </SeeMoreWrapper>
        )}
        <ActionsWrapper>
          {isMyMessage && canDelete && (
            <Popconfirm title={t('DELETE_MESSAGE_CONFIRMATION')} onConfirm={onDeleteMessage}>
              <Button type="link" danger shape="circle" size="small" icon={<Icon i="TrashCan" />} />
            </Popconfirm>
          )}
        </ActionsWrapper>
      </FooterWrapper>
      {canAnswer && (
        <>
          {isAnswerFormVisible ? (
            <CreateTicketForm
              form={form}
              isActive={isAnswerFormVisible}
              setIsActive={setIsAnswerFormVisible}
              alwaysExpanded
              onFormSubmit={onAnswerSubmit}
              onCancel={() => setIsAnswerFormVisible(false)}
            />
          ) : (
            <AnswerWrapper onClick={() => setIsAnswerFormVisible(true)}>
              <Icon i="AddComment" />
              <div>{t('ANSWER')}</div>
            </AnswerWrapper>
          )}
        </>
      )}
    </ContentWrapper>
  );
};

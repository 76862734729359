import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  width: 100%;
  gap: 16px;
  padding: 24px 32px;
`;

export const ColumnWrapper = styled.div<{ $fullWidth: boolean }>`
  display: flex;
  flex-direction: column;
  width: ${({ $fullWidth }) => ($fullWidth ? '100%' : '50%')};
  padding-right: 16px;
  ${({ $fullWidth }) =>
    $fullWidth
      ? css`
          border-right: none;
        `
      : css`
          border-right: 1px solid ${({ theme }) => theme.colors.black3};
        `}
`;

import { IUser } from '@sdk/contracts';
import { FormInstance } from 'antd';
import { create as createStore } from 'zustand';

interface IStore {
  isOpen: boolean;
  form: FormInstance;
  data?: IUser | null;
  setForm: (form: FormInstance) => void;
  setData: (user: IUser | null) => void;
  setIsOpen: (isOpen: boolean) => void;
}

export const useTeacherForm = createStore<IStore>(set => ({
  data: null,
  isOpen: false,
  form: {} as FormInstance,
  setForm: (form: FormInstance) => set({ form }),
  setData: (data: IUser | null) => set({ data }),
  setIsOpen: (isOpen: boolean) => set({ isOpen })
}));

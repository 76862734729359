import styled from 'styled-components';

export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  padding: 24px 0px;
  height: 100%;
`;

export const FooterWrapper = styled.div`
  display: flex;
  width: 60%;
  align-self: center;
  padding: 16px;
  justify-content: space-between;
  align-items: center;
  gap: 16px;
  border-top: 1px solid ${({ theme }) => theme.colors.black2};
`;

export const ButtonsWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
`;

export const ButtonWrapper = styled.div`
  border-radius: 100px;
  border: 1px solid transparent;
  background: linear-gradient(
        ${({ theme }) => theme.colors.white},
        ${({ theme }) => theme.colors.white}
      )
      padding-box,
    linear-gradient(
        to left,
        ${({ theme }) => theme.colors.green6},
        ${({ theme }) => theme.colors.blue4},
        ${({ theme }) => theme.colors.green6},
        ${({ theme }) => theme.colors.purple6}
      )
      border-box;
`;

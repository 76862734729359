import { create as createStore } from 'zustand';

interface IStore {
  isPromoter: boolean;
  setIsPromoter: (vote: number) => void;
}

export const useNpsIsPromoter = createStore<IStore>(set => ({
  isPromoter: false,
  //TODO: Add here the flag to see if is a negative nps vote
  setIsPromoter: (vote: number) => {
    if (!!vote && vote >= 9) {
      set({ isPromoter: true });
    } else {
      set({ isPromoter: false });
    }
  }
}));

import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 90vw;

  @media (min-width: ${({ theme }) => theme.mediaQueries.xs}) {
    width: 500px;
  }

  @media (min-width: ${({ theme }) => theme.mediaQueries.sm}) {
    width: 700px;
  }

  .ant-picker {
    width: 100%;
  }

  .ant-table-tbody > tr > td {
    padding: 0;
  }
`;

export const HeaderWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  align-self: stretch;
  width: 100%;
`;

export const SubscriptionsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
  width: 100%;
  border-radius: 4px;
  border: 1px solid ${({ theme }) => theme.colors.black4};
  .ant-checkbox-checked .ant-checkbox-inner {
    background-color: ${({ theme }) => theme.colors.green6};
    border-color: ${({ theme }) => theme.colors.green6};
  }
  height: 300px;
  overflow-y: auto;
`;

export const ItemWrapper = styled.div`
  padding: 8px 16px;
  align-items: center;
  gap: 16px;
  align-self: stretch;
  border-bottom: 1px solid ${({ theme }) => theme.colors.black2};
`;

import styled from 'styled-components';

export const Wrapper = styled.div<{
  $isSelected: boolean;
  $isWrong: boolean;
  $isReviewing: boolean;
  $isCorrectAnswer: boolean;
}>`
  display: flex;
  padding: 16px;
  flex-direction: column;
  box-sizing: border-box;
  align-items: flex-start;
  gap: 4px;
  align-self: stretch;
  width: 100%;

  border-radius: 4px;
  border: 2px solid transparent;
  outline: 1px solid ${({ theme }) => theme.colors.black5};
  cursor: pointer;
  transition: all 0.2s linear;

  ${({ $isReviewing, theme, $isWrong, $isCorrectAnswer }) =>
    $isReviewing &&
    $isWrong &&
    `
    background-color: ${
      $isCorrectAnswer ? theme.colors.green1 : $isWrong ? theme.colors.red2 : undefined
    };
    border: 3px solid ${
      $isCorrectAnswer ? theme.colors.green6 : $isWrong ? theme.colors.red6 : undefined
    };
    outline: 1px solid transparent;
  `}

  ${({ $isReviewing, theme, $isCorrectAnswer }) =>
    $isReviewing &&
    $isCorrectAnswer &&
    `
    background-color: ${theme.colors.green1};
    border: 3px solid ${theme.colors.green6};
    outline: 1px solid transparent;
  `}

  ${({ $isSelected, theme }) =>
    $isSelected &&
    `
    background-color: ${theme.colors.purple3};
    border: 3px solid ${theme.colors.purple6};
    outline: 1px solid transparent;
  `}

  ${({ $isReviewing, theme, $isSelected }) =>
    !$isReviewing &&
    `
    :hover {
      background-color: ${theme.colors.purple2};
      border: ${
        $isSelected ? `3px solid ${theme.colors.purple6}` : `2px solid ${theme.colors.purple6}`
      };
      outline: 1px solid transparent;
    }
      `}
`;

export const IndexWrapper = styled.div`
  font-size: 12px;
  font-weight: 600;
  line-height: 20px;
  color: ${({ theme }) => theme.colors.black5};
`;

export const ImageWrapper = styled.div`
  height: 120px;
  width: auto;
`;

export const StyledImage = styled.img`
  height: 120px;
  width: auto;
`;

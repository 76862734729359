import {
  ETaskStatus,
  EActivityType,
  EDripKind,
  ETranscriptionStatus,
  EQuizStatus
} from '@constants';

import { ECourseContentStep, ECourseStep } from '@/types';

export const CourseTranslations = {
  COURSE: {
    es: 'Curso',
    en: 'Course',
    cat: 'Curs',
    eus: 'Ikastaroa',
    pt: 'Curso'
  },
  NEW_SECTION: {
    es: 'Nueva sección',
    en: 'New section',
    cat: 'Nova secció',
    eus: 'Sekzio berria',
    pt: 'Nova seção'
  },
  SELECT_TAG: {
    es: 'Selecciona una formación',
    en: 'Select a tag',
    cat: 'Selecciona una formació',
    eus: 'Formazio bat hautatu',
    pt: 'Selecione uma formação'
  },
  ADD_SECTION: {
    es: 'Añadir sección',
    en: 'Add section',
    cat: 'Afegir secció',
    eus: 'Sekzioa gehitu',
    pt: 'Adicionar seção'
  },
  ADD_ACTIVITY: {
    es: 'Añadir actividad',
    en: 'Add activity',
    cat: 'Afegir activitat',
    eus: 'Jarduera gehitu',
    pt: 'Adicionar atividade'
  },
  ACTIVITY: {
    es: 'Actividad',
    en: 'Activity',
    cat: 'Activitat',
    eus: 'Jarduera',
    pt: 'Atividade'
  },
  ACTIVITIES: {
    es: 'Actividades',
    en: 'Activities',
    cat: 'Activitats',
    eus: 'Jarduerak',
    pt: 'Atividades'
  },
  ACTIVITIES_COUNT: {
    es: '{count} actividades',
    en: '{count} activities',
    cat: '{count} activitats',
    eus: '{count} jarduerak',
    pt: '{count} atividades'
  },
  ACADEMY: {
    es: 'Academia',
    en: 'Academy',
    cat: 'Acadèmia',
    eus: 'Akademia',
    pt: 'Academia'
  },
  EDIT_SECTION: {
    es: 'Editar sección',
    en: 'Edit section',
    cat: 'Editar secció',
    eus: 'Sekzioa editatu',
    pt: 'Editar seção'
  },
  DELETE_SECTION: {
    es: 'Eliminar sección',
    en: 'Delete section',
    cat: 'Eliminar secció',
    eus: 'Sekzioa ezabatu',
    pt: 'Eliminar seção'
  },
  SECTION_CREATED: {
    es: 'Sección creada con éxito',
    en: 'Section successfully created',
    cat: 'Secció creada amb èxit',
    eus: 'Sekzioa arrakasta piztu da',
    pt: 'Seção criada com sucesso'
  },
  SECTION_UPDATED: {
    es: 'Sección actualizada con éxito',
    en: 'Section successfully updated',
    cat: 'Secció actualitzada amb èxit',
    eus: 'Sekzioa arrakasta eguneratu da',
    pt: 'Seção atualizada com sucesso'
  },
  SECTION_NAME_PLACEHOLDER: {
    es: 'Nombre de la sección',
    en: 'Section name',
    cat: 'Nom de la secció',
    eus: 'Sekzio izena',
    pt: 'Nome da seção'
  },
  SECTION_DESCRIPTION_PLACEHOLDER: {
    es: 'Descripción de la sección (Opcional)',
    en: 'Section description (Optional)',
    cat: 'Descripció de la secció (Opcional)',
    eus: 'Sekzio deskribapena (Derrigorrezkoa ez da)',
    pt: 'Descrição da seção (Opcional)'
  },
  NO_ACTIVITIES_IN_SECTION: {
    es: 'No hay actividades en esta sección',
    en: 'There are no activities in this section',
    cat: 'No hi ha activitats en aquesta secció',
    eus: 'Ez dago jarduerarik sekzio honetan',
    pt: 'Não há atividades nesta seção'
  },
  COURSE_WITH_NO_SECTIONS: {
    es: 'Este curso no tiene secciones',
    en: 'This course has no sections',
    cat: 'Aquest curs no té seccions',
    eus: 'Kursu honen sekziorik ez dago',
    pt: 'Este curso não tem seções'
  },
  COURSE_TITLE: {
    es: 'Título del curso',
    en: 'Course title',
    cat: 'Títol del curs',
    eus: 'Kursuaren izenburua',
    pt: 'Título do curso'
  },
  COURSE_DESCRIPTION: {
    es: 'Descripción del curso',
    en: 'Course description',
    cat: 'Descripció del curs',
    eus: 'Kursuaren deskribapena',
    pt: 'Descrição do curso'
  },
  CREATE_COURSE_SUCCESS: {
    es: 'Curso creado con éxito',
    en: 'Course created successfully',
    cat: 'Curs creat amb èxit',
    eus: 'Kursua arrakasta sortu da',
    pt: 'Curso criado com sucesso'
  },
  UPDATE_COURSE_SUCCESS: {
    es: 'Curso actualizado con éxito',
    en: 'Course updated successfully',
    cat: 'Curs actualitzat amb èxit',
    eus: 'Kursua arrakasta eguneratu da',
    pt: 'Curso atualizado com sucesso'
  },
  DELETE_COURSE_SUCCESS: {
    es: 'Curso eliminado con éxito',
    en: 'Course deleted successfully',
    cat: 'Curs eliminat amb èxit',
    eus: 'Kursua arrakasta ezabatu da',
    pt: 'Curso eliminado com sucesso'
  },
  SELECT_COURSE_TEACHERS: {
    es: 'Selecciona los instructores del curso',
    en: 'Select the course teachers',
    cat: 'Selecciona els instructors del curs',
    eus: 'Hautatu kursuaren irakasleak',
    pt: 'Selecione os professores do curso'
  },
  NEW_ACTIVITY: {
    es: 'Nueva actividad',
    en: 'New activity',
    cat: 'Nova activitat',
    eus: 'Jarduera berria',
    pt: 'Nova atividade'
  },
  CREATE_ACTIVITY_SUCCESS: {
    es: 'Actividad creada con éxito',
    en: 'Activity created successfully',
    cat: 'Activitat creada amb èxit',
    eus: 'Jarduera arrakasta sortu da',
    pt: 'Atividade criada com sucesso'
  },
  EDIT_ACTIVITY: {
    es: 'Editar actividad',
    en: 'Edit activity',
    cat: 'Editar activitat',
    eus: 'Jarduera editatu',
    pt: 'Editar atividade'
  },
  EDIT_NAME: {
    es: 'Editar nombre',
    en: 'Edit name',
    cat: 'Editar nom',
    eus: 'Izena editatu',
    pt: 'Editar nome'
  },
  UPDATE_ACTIVITY_SUCCESS: {
    es: 'Actividad actualizada con éxito',
    en: 'Activity updated successfully',
    cat: 'Activitat actualitzada amb èxit',
    eus: 'Jarduera arrakasta eguneratu da',
    pt: 'Atividade atualizada com sucesso'
  },
  DELETE_ACTIVITY: {
    es: 'Eliminar actividad',
    en: 'Delete activity',
    cat: 'Eliminar activitat',
    eus: 'Jarduera ezabatu',
    pt: 'Eliminar atividade'
  },
  ACTIVITY_NAME: {
    es: 'Nombre de la actividad',
    en: 'Activity name',
    cat: 'Nom de la activitat',
    eus: 'Jarduera izena',
    pt: 'Nome da atividade'
  },
  ACTIVE: {
    es: 'Activo',
    en: 'Active',
    cat: 'Actiu',
    eus: 'Aktibo',
    pt: 'Ativo'
  },
  ACTIVITY_DESCRIPTION: {
    es: 'Descripción de la actividad',
    en: 'Activity description',
    cat: 'Descripció de la activitat',
    eus: 'Jarduera deskribapena',
    pt: 'Descrição da atividade'
  },
  LOCK_ACTIVITY: {
    es: 'Bloquear actividad',
    en: 'Lock activity',
    cat: 'Bloquejar activitat',
    eus: 'Jarduera blokeatu',
    pt: 'Bloquear atividade'
  },
  [EActivityType.VIDEO]: {
    es: 'Video',
    en: 'Video',
    cat: 'Vídeo',
    eus: 'Bideoa',
    pt: 'Vídeo'
  },
  [EActivityType.PDF]: {
    es: 'PDF',
    en: 'PDF',
    cat: 'PDF',
    eus: 'PDF',
    pt: 'PDF'
  },
  [EActivityType.INTERACTIVE_VIDEO]: {
    es: 'Video interactivo',
    en: 'Interactive video',
    cat: 'Vídeo interactiu',
    eus: 'Interaktiboa den bideoa',
    pt: 'Vídeo interativo'
  },
  [EActivityType.DELIVERABLE]: {
    es: 'Entregable',
    en: 'Deliverable',
    cat: 'Lliurament',
    eus: 'Entregaketa',
    pt: 'Entregável'
  },
  SELECT_AN_ACTIVITY_TYPE_TO_CONTINUE: {
    es: 'Selecciona un tipo de actividad para continuar',
    en: 'Select an activity type to continue',
    cat: "Selecciona un tipus d'activitat per continuar",
    eus: 'Jarraitzeko jarduera mota bat hautatu',
    pt: 'Selecione um tipo de atividade para continuar'
  },
  ACTIVITY_PDF_TYPE_DESCRIPTION: {
    es: 'Sube un archivo PDF',
    en: 'Upload a PDF file',
    cat: 'Puja un arxiu PDF',
    eus: 'PDF fitxategi bat igo',
    pt: 'Enviar um arquivo PDF'
  },
  ACTIVITY_VIDEO_TYPE_DESCRIPTION: {
    es: 'Sube un archivo de video',
    en: 'Upload a video file',
    cat: 'Puja un arxiu de vídeo',
    eus: 'Bideo fitxategi bat igo',
    pt: 'Enviar um arquivo de vídeo'
  },
  ACTIVITY_RECAP_ACTIVE: {
    es: 'Con checkpoint',
    en: 'With checkpoint',
    cat: 'Amb checkpoint',
    eus: 'Checkpointarekin',
    pt: 'Com checkpoint'
  },
  ACTIVITY_RECAP_QUESTION_TITLE: {
    es: 'Preguntas de repaso',
    en: 'Recap questions',
    cat: 'Preguntes de repàs',
    eus: 'Berrikuspen galderak',
    pt: 'Perguntas de recapitulação'
  },
  ACTIVITY_ADD_RECAP: {
    es: 'Añadir',
    en: 'Add',
    cat: 'Afegir',
    eus: 'Gehitu',
    pt: 'Adicionar'
  },
  ACTIVITY_GENERATE_VIDEO_CHECKPOINT: {
    es: 'Generar',
    en: 'Generate',
    cat: 'Generar',
    eus: 'Sortu',
    pt: 'Gerar'
  },
  ACTIVITY_GENERATE_VIDEO_CHECKPOINT_TOOLTIP: {
    es: 'Es necesaria la transcripción del vídeo para poder generar preguntas automáticas',
    en: 'Video transcription is required to generate automatic questions',
    cat: 'És necessària la transcripció del vídeo per poder generar preguntes automàtiques',
    eus: 'Bideoaren transkripzioa beharrezkoa da galdera automatikoak sortzeko',
    pt: 'A transcrição do vídeo é necessária para gerar perguntas automáticas'
  },
  ACTIVITY_VIDEO_CHECKPOINT_DESCRIPTION: {
    es: 'Escribe el enunciado, las cuatro respuestas y selecciona la respuesta correcta antes de guardar cambios.',
    en: 'Write the statement, the four answers and select the correct answer before saving changes',
    cat: "Escriu l'enunciat, les quatre respostes i selecciona la resposta correcta abans de desar els canvis",
    eus: 'Aldaketak gordetzen baino lehen idatzi adierazpena, lau erantzunak eta erantzun zuzena hautatu',
    pt: 'Escreva o enunciado, as quatro respostas e selecione a resposta correta antes de guardar as alterações'
  },
  ACTIVITY_ADD_QUESTION: {
    es: 'Añadir pregunta',
    en: 'Add question',
    cat: 'Afegir pregunta',
    eus: 'Galdera gehitu',
    pt: 'Adicionar pergunta'
  },
  ACTIVITY_INTERACTIVE_VIDEO_TYPE_DESCRIPTION: {
    es: 'Sube un archivo de video y luego podrás agregar interacciones al video',
    en: 'Upload a video file and then you can add interactions to the video',
    cat: 'Puja un arxiu de vídeo i després podràs afegir interaccions al vídeo',
    eus: 'Bideo fitxategi bat igo eta ondoren interakzioak gehitzeko aukera izango duzu',
    pt: 'Envie um arquivo de vídeo e, em seguida, poderá adicionar interações ao vídeo'
  },
  ACTIVITY_DELIVERABLE_TYPE_DESCRIPTION: {
    es: 'Sube un archivo PDF',
    en: 'Upload a PDF file',
    cat: 'Puja un arxiu PDF',
    eus: 'PDF fitxategi bat igo',
    pt: 'Enviar um arquivo PDF'
  },
  ACTIVITY_DELIVERABLE_TYPE_ASSIGN: {
    es: 'Asignar profesor',
    en: 'Assign teacher',
    cat: 'Asignar professor',
    eus: 'Irakaslearen asignazioa',
    pt: 'Atribuir professor'
  },
  ACTIVITY_DELIVERABLE_TYPE_ASSIGN_INFO: {
    es: 'Si no asignas un profesor, se asignará al tutor por defecto',
    en: 'If you do not assign a teacher, the default tutor will be assigned',
    cat: 'Si no assignes un professor, el professor per defecte es assignarà',
    eus: 'Ez baduzu ikasle bat asginatu, ikasle per defecto asmatuko da',
    pt: 'Se não atribuir um professor, o professor por defeito será atribuído'
  },
  PREVIEW_ACTIVITY_TITLE: {
    es: 'Vista previa de la actividad',
    en: 'Activity preview',
    cat: "Vista prèvia de l'activitat",
    eus: 'Jarduera aurreikuspena',
    pt: 'Pré-visualização da atividade'
  },
  ATTACH_ADITIONAL_MATERIAL: {
    es: 'Adjuntar material adicional',
    en: 'Attach other files or links',
    cat: 'Adjunta material addicional',
    eus: 'Gehigarriak fitxategiak edo loturak',
    pt: 'Anexar outros arquivos ou links'
  },
  UPLOAD_FILE: {
    es: 'Subir archivo',
    en: 'Upload file',
    cat: 'Puja un arxiu',
    eus: 'Fitxategi bat igo',
    pt: 'Enviar arquivo'
  },
  [ECourseStep.INFO]: {
    es: 'Información',
    en: 'Information',
    cat: 'Informació',
    eus: 'Informazioa',
    pt: 'Informação'
  },
  [ECourseContentStep.SECTIONS]: {
    es: 'Secciones',
    en: 'Sections',
    cat: 'Seccions',
    eus: 'Sekzioak',
    pt: 'Seções'
  },
  [ECourseContentStep.MATERIAL]: {
    es: 'Material descargable',
    en: 'Downloadable material',
    cat: 'Material descarregable',
    eus: 'Jaits daitekeen materiala',
    pt: 'Material descarregável'
  },
  [ECourseContentStep.COMPLEMENTARY_MATERIAL]: {
    es: 'Material complementario',
    en: 'Complementary material',
    cat: 'Material complementari',
    eus: 'Material osagarria',
    pt: 'Material complementar'
  },
  [ECourseStep.TASKS_PANEL]: {
    es: 'Panel de tareas',
    en: 'Tasks panel',
    cat: 'Panell de tasques',
    eus: 'Lanen panela',
    pt: 'Painel de tarefas'
  },
  [ECourseStep.QUIZZES]: {
    es: 'Tests',
    en: 'Tests',
    cat: 'Tests',
    eus: 'Galdetegiak',
    pt: 'Testes'
  },
  [ECourseStep.STUDENTS]: {
    es: 'Alumnos',
    en: 'Students',
    cat: 'Alumnes',
    eus: 'Ikasleak',
    pt: 'Alunos'
  },
  ATTACH_LINKS_PLACEHOLDER: {
    es: 'Pegar enlace de un recurso externo',
    en: 'Paste external resource link',
    cat: 'Enganxa enllaç de recurs extern',
    eus: 'Erantzun eskubideko lotura bat erantsi',
    pt: 'Colar link de recurso externo'
  },
  PLEASE_ENTER_VALID_URL: {
    es: 'Por favor ingresa una URL válida',
    en: 'Please enter a valid URL',
    cat: 'Si us plau, introdueix una URL vàlida',
    eus: 'Mesedez, sartu URL baliogarri bat',
    pt: 'Por favor, insira uma URL válida'
  },
  EXTERNAL_RESOURCE_LINK: {
    es: 'Enlace a recurso externo - {{count}}',
    en: 'External resource link - {{count}}',
    cat: 'Enllaç a recurs extern - {{count}}',
    eus: 'Kanpo erresurtsuaren esteka - {{count}}',
    pt: 'Link de recurso externo - {{count}}'
  },
  UNSAVED_CHANGES: {
    es: 'Tienes cambios sin guardar',
    en: 'You have unsaved changes',
    cat: 'Tens canvis sense desar',
    eus: 'Gorde gabeko aldaketak dituzu',
    pt: 'Tem alterações não salvas'
  },
  DRIP_CONTENT_TITLE: {
    es: 'Liberación de contenido',
    en: 'Drip content',
    cat: 'Alliberament de contingut',
    eus: 'Emandako edukiak',
    pt: 'Conteúdo de liberação'
  },
  DRIP_INFO: {
    es: 'El Drip se realiza por secciones. Las actividades individuales no pueden ser liberadas vía Drip. Si desea agregar actividades individuales, debe crear cada actividad dentro de su propia sección. Por defecto: el contenido se liberará de forma inmediata.',
    en: 'Drip is released by sections. Individual activities cannot be released by Drip. If you would like to drip individual activities, you should create each activity within its own section. By default the content will be released immediately.',
    cat: 'El Drip es realitza per seccions. Les activitats individuals no poden ser alliberades via Drip. Si desitja afegir activitats individuals, ha de crear cada activitat dins de la seva pròpia secció. Per defecte: el contingut es liberarà de forma immediata.',
    eus: 'Drip sekzioak erabiliz gauzatzen da. Jarduera indibidualak ezin dira Drip bidez ordenatuta. Jarduera indibidualak dripeatu nahi badituzu, jarduera bakoitzaren barruan jarduera bakoitz bat sortu beharko duzu. Lehenetsi bezala, edukiak berehala ordenatuko dira.',
    pt: 'O Drip é realizado por seções. As atividades individuais não podem ser liberadas via Drip. Se deseja liberar atividades individuais, deve criar cada atividade dentro da sua própria seção. Por padrão, o conteúdo será liberado imediatamente.'
  },
  DELETE_SECTION_CONFIRM: {
    es: '¿Estás seguro de que quieres eliminar esta sección?',
    en: 'Are you sure you want to delete this section?',
    cat: '¿Segur que vols eliminar aquesta secció?',
    eus: 'Ziur zaude sekzio hau ezabatu nahi duzula?',
    pt: 'Tem certeza que deseja eliminar esta seção?'
  },
  SECTION_DELETED_SUCCESSFULLY: {
    es: 'Sección eliminada con éxito',
    en: 'Section deleted successfully',
    cat: 'Secció eliminada amb èxit',
    eus: 'Sekzioa ondo ezabatu da',
    pt: 'Seção eliminada com sucesso'
  },
  COURSE_DESCRIPTION_IS_REQUIRED: {
    es: 'La descripción del curso es requerida',
    en: 'Course description is required',
    cat: 'La descripció del curs és obligatòria',
    eus: 'Kursuaren deskribapena beharrezkoa da',
    pt: 'A descrição do curso é obrigatória'
  },
  ALL_SECTIONS_MUST_HAVE_ACTIVITIES: {
    es: 'Todas las secciones deben tener al menos una actividad',
    en: 'All sections must have at least one activity',
    cat: 'Totes les seccions han de tenir com a mínim una activitat',
    eus: 'Sekzio guztiek gutxienez jarduera bat izan behar dute',
    pt: 'Todas as seções devem ter pelo menos uma atividade'
  },
  [EDripKind.BY_DATE]: {
    es: 'En la fecha',
    en: 'By date',
    cat: 'Per data',
    eus: 'Dataren arabera',
    pt: 'Por data'
  },
  [EDripKind.BY_DAYS_AFTER]: {
    es: 'Días después de la inscripción',
    en: 'Days after subscription',
    cat: 'Dies després de la inscripció',
    eus: 'Izena ematearen ostean egun batzuk',
    pt: 'Dias após a inscrição'
  },
  [EActivityType.QUIZ]: {
    es: 'Quiz',
    en: 'Quiz',
    cat: 'Quiz',
    eus: 'Galdetegia',
    pt: 'Quiz'
  },
  [EActivityType.TEXT]: {
    es: 'Texto',
    en: 'Text',
    cat: 'Text',
    eus: 'Testua',
    pt: 'Texto'
  },
  [`${EActivityType.QUIZ}_SELECTOR_LABEL`]: {
    es: 'Selecciona un test',
    en: 'Select quiz',
    cat: 'Selecciona un quiz',
    eus: 'Hautatu galdetegia',
    pt: 'Selecione o quiz'
  },
  DAYS: {
    es: 'Días',
    en: 'Days',
    cat: 'Dies',
    eus: 'Egunak',
    pt: 'Dias'
  },
  SELECT_A_TAG: {
    es: 'Selecciona una categoría',
    en: 'Select a tag',
    cat: 'Selecciona una etiqueta',
    eus: 'Aukeratu etiketa bat',
    pt: 'Selecione uma etiqueta'
  },
  CANT_LOAD_ACTIVITY_RESOURCE: {
    es: 'No se pudo cargar el recurso de la actividad',
    en: 'Could not load activity resource',
    cat: "No s'ha pogut carregar el recurs de l'activitat",
    eus: 'Ezin izan da jarduera baten baliabideak kargatu',
    pt: 'Não foi possível carregar os recursos da atividade'
  },
  [`${EActivityType.VIDEO}_SELECTOR_LABEL`]: {
    es: ' - Video',
    en: ' - Video',
    cat: ' - Vídeo',
    eus: ' - Bideo',
    pt: ' - Vídeo'
  },
  [`${EActivityType.INTERACTIVE_VIDEO}_SELECTOR_LABEL`]: {
    es: ' - Video interactivo ',
    en: ' - Interactive video',
    cat: ' - Vídeo interactiu',
    eus: ' - Bideo interaktiboa',
    pt: ' - Vídeo interativo'
  },
  [`${EActivityType.PDF}_SELECTOR_LABEL`]: {
    es: ' - Documento',
    en: ' - Document',
    cat: ' - Document',
    eus: ' - Dokumentua',
    pt: ' - Documento'
  },
  [`${EActivityType.DELIVERABLE}_SELECTOR_LABEL`]: {
    es: ' - Trabajo',
    en: ' - Work',
    cat: ' - Treball',
    eus: ' - Lanbidea',
    pt: ' - Trabalho'
  },
  [`${EActivityType.TEXT}_SELECTOR_LABEL`]: {
    es: ' - Texto',
    en: ' - Text',
    cat: ' - Text',
    eus: ' - Testua',
    pt: ' - Texto'
  },
  DELETE_ACTIVITY_CONFIRM: {
    es: '¿Estás seguro de que quieres eliminar esta actividad?',
    en: 'Are you sure you want to delete this activity?',
    cat: 'Segur que vols eliminar aquesta activitat?',
    eus: 'Ziur zaude jarduera hau ezabatu nahi duzula?',
    pt: 'Tem certeza que deseja eliminar esta atividade?'
  },
  ACTIVITY_DELETED_SUCCESSFULLY: {
    es: 'Actividad eliminada con éxito',
    en: 'Activity deleted successfully',
    cat: 'Activitat eliminada amb èxit',
    eus: 'Jarduera ondo ezabatu da',
    pt: 'Atividade eliminada com sucesso'
  },
  ACTIVITY_TASK_DELETED_SUCCESSFULLY: {
    es: 'Tarea eliminada con éxito',
    en: 'Task deleted successfully',
    cat: 'Tasca eliminada amb èxit',
    eus: 'Lana ondo ezabatu da',
    pt: 'Tarefa eliminada com sucesso'
  },
  ACTIVITY_TASK_VALIDATED_SUCCESSFULLY: {
    es: 'Tarea validada con éxito',
    en: 'Task validated successfully',
    cat: 'Tasca validada amb èxit',
    eus: 'Lana ondo balioztatu da',
    pt: 'Tarefa validada com sucesso'
  },
  CREATE_SECTION: {
    es: 'Crear sección',
    en: 'Create section',
    cat: 'Crear secció',
    eus: 'Sortu sekzioa',
    pt: 'Criar seção'
  },
  UPDATE_SECTION: {
    es: 'Actualizar sección',
    en: 'Update section',
    cat: 'Actualitzar secció',
    eus: 'Eguneratu sekzioa',
    pt: 'Atualizar seção'
  },
  CREATE: {
    es: 'Crear',
    en: 'Create',
    cat: 'Crear',
    eus: 'Sortu',
    pt: 'Criar'
  },
  SAVE: {
    es: 'Guardar cambios',
    en: 'Save changes',
    cat: 'Guardar canvis',
    eus: 'Gorde aldaketak',
    pt: 'Salvar alterações'
  },
  SECTION_DELETED: {
    es: 'Sección eliminada',
    en: 'Section deleted',
    cat: 'Secció eliminada',
    eus: 'Sekzioa ezabatu da',
    pt: 'Seção eliminada'
  },
  ARE_YOU_SURE_TO_REMOVE_SELECTED: {
    es: '¿Estás seguro que deseas eliminar todos los elementos seleccionados?',
    en: 'Are you sure you want to delete all selected items?',
    cat: 'Segur que vols eliminar tots els elements seleccionats?',
    eus: 'Ziur zaude hautatutako elementu guztiak ezabatu nahi dituzula?',
    pt: 'Tem certeza que deseja eliminar todos os itens selecionados?'
  },
  GENERATE_TRANSCRIPTION: {
    es: 'Generar transcripción',
    en: 'Generate transcription',
    cat: 'Generar transcripció',
    eus: 'Transkripzioa sortu',
    pt: 'Gerar transcrição'
  },
  PREVIEW_TRANSCRIPTION: {
    es: 'Previsualizar transcripción',
    en: 'Preview transcription',
    cat: 'Previsualitzar transcripció',
    eus: 'Transkripzioa aurreikusi',
    pt: 'Pré-visualizar transcrição'
  },
  [ETranscriptionStatus.READY]: {
    es: 'Transcripción lista',
    en: 'Transcription ready',
    cat: 'Transcripció llesta',
    eus: 'Transkripzioa prest',
    pt: 'Transcrição pronta'
  },
  [ETranscriptionStatus.PROCESSING]: {
    es: 'Transcripción en curso...',
    en: 'Transcription in process...',
    cat: 'Transcripció en curs...',
    eus: 'Transkripzioa prozesuan...',
    pt: 'Transcrição em processo...'
  },
  [ETranscriptionStatus.NOT_READY]: {
    es: 'Transcripción no lista',
    en: 'Transcription not ready',
    cat: 'Transcripció no llesta',
    eus: 'Transkripzioa ez dago prest',
    pt: 'Transcrição não pronta'
  },
  CONTENT: {
    es: 'Contenido',
    en: 'Content',
    cat: 'Contingut',
    eus: 'Edukiak',
    pt: 'Conteúdo'
  },
  SLUG: {
    es: 'Slug',
    en: 'Slug',
    cat: 'Slug',
    eus: 'Slug',
    pt: 'Slug'
  },
  TITLE: {
    es: 'Título',
    en: 'Title',
    cat: 'Títol',
    eus: 'Izenburua',
    pt: 'Título'
  },
  BRIEF: {
    es: 'Descripción',
    en: 'Brief',
    cat: 'Descripció',
    eus: 'Laburpena',
    pt: 'Resumo'
  },
  TRANSCRIPTION_PREVIEW: {
    es: 'Previsualización de transcripción',
    en: 'Transcription preview',
    cat: 'Previsualització de la transcripció',
    eus: 'Transkripzioaren aurreikusketa',
    pt: 'Pré-visualização da transcrição'
  },
  EDIT: {
    es: 'Editar',
    en: 'Edit',
    cat: 'Editar',
    eus: 'Editatu',
    pt: 'Editar'
  },
  RE_GENERATE_TRANSCRIPTION: {
    es: 'Re-generar transcripción',
    en: 'Re-generate transcription',
    cat: 'Regenerar la transcripció',
    eus: 'Transkripzioa berriz sortu',
    pt: 'Re-gerar transcrição'
  },
  CERTIFICATION_QUIZ: {
    es: 'Test de certificación (opcional)',
    en: 'Certification quiz (optional)',
    cat: 'Quiz de certificació (opcional)',
    eus: 'Egiaztapen galdetegia (aukerakoa)',
    pt: 'Quiz de certificação (opcional)'
  },
  CERTIFICATION_QUIZ_PLACEHOLDER: {
    es: 'Selecciona un test que habilite la certificación',
    en: 'Select a quiz that enables certification',
    cat: 'Selecciona un quiz que habiliti la certificació',
    eus: 'Aukeratu egiaztapena ahalbidetzen duen galdetegia',
    pt: 'Selecione um quiz que habilite a certificação'
  },
  SEND_TO_INDEX: {
    es: 'Enviar a indexar',
    en: 'Send to index',
    cat: 'Envia a indexar',
    eus: 'Bidali indexatzera',
    pt: 'Enviar para indexar'
  },
  PUBLISHED: {
    es: 'Publicada',
    en: 'Published',
    cat: 'Publicat',
    eus: 'Argitaratuta',
    pt: 'Publicado'
  },
  NOT_PUBLISHED: {
    es: 'No publicada',
    en: 'Not published',
    cat: 'No publicat',
    eus: 'Ez da argitaratu',
    pt: 'Não publicado'
  },
  [ETaskStatus.APPROVED]: {
    es: 'Aprobada',
    en: 'Approved',
    cat: 'Aprovada',
    eus: 'Onartua',
    pt: 'Aprovado'
  },
  [ETaskStatus.IN_REVIEW]: {
    es: 'En revisión',
    en: 'In review',
    cat: 'En revisió',
    eus: 'Berrikusten',
    pt: 'Em revisão'
  },
  [ETaskStatus.PENDING]: {
    es: 'Pendiente de entregar',
    en: 'Pending delivery',
    cat: "Pendent d'entregar",
    eus: 'Entregatzeko zain',
    pt: 'Pendente de entrega'
  },
  [ETaskStatus.REJECTED]: {
    es: 'Rechazada',
    en: 'Rejected',
    cat: 'Rebutjada',
    eus: 'Ukatua',
    pt: 'Rejeitado'
  },
  MATERIAL_UPDATED: {
    es: 'Material actualizado',
    en: 'Material updated',
    cat: 'Material actualitzat',
    eus: 'Materiala eguneratua',
    pt: 'Material atualizado'
  },
  MOVE_TO_OTHER_FOLDER: {
    es: 'Mover a otra carpeta',
    en: 'Move to another folder',
    cat: 'Mou a una altra carpeta',
    eus: 'Mugitu karpeta batera',
    pt: 'Mover para outra pasta'
  },
  MOVE_TO_OTHER_SECTION: {
    es: 'Mover a otra sección',
    en: 'Move to another section',
    cat: 'Mou a una altra secció',
    eus: 'Mugitu sekzio batera',
    pt: 'Mover para outra seção'
  },
  MOVE: {
    es: 'Mover',
    en: 'Move',
    cat: 'Mou',
    eus: 'Mugitu',
    pt: 'Mover'
  },
  ELEMENTS_MOVED_SUCCESS: {
    es: 'Elementos movidos con éxito',
    en: 'Elements successfully moved',
    cat: 'Elements moguts amb èxit',
    eus: 'Elementuak ondo mugitu dira',
    pt: 'Elementos movidos com sucesso'
  },
  RELATED_CONTENT_PLACEHOLDER: {
    es: 'Selecciona uno o varios temas',
    en: 'Select one or more sections',
    cat: 'Selecciona un o més temes',
    eus: 'Aukeratu gaia edo gaiei buruzkoak',
    pt: 'Selecione um ou mais temas'
  },
  COURSE_ASSIGNED_SUCCESSFULLY: {
    es: 'Curso asignado correctamente',
    en: 'Course assigned successfully',
    cat: 'Curs assignat amb èxit',
    eus: 'Kursua ondo asignatu da',
    pt: 'Curso atribuído com sucesso'
  },
  COURSE_REMOVED_SUCCESSFULLY: {
    es: 'Curso eliminado correctamente',
    en: 'Course removed successfully',
    cat: 'Curs eliminat amb èxit',
    eus: 'Kursua ondo ezabatu da',
    pt: 'Curso eliminado com sucesso'
  },
  COURSE_DUPLICATED_SUCCESSFULLY: {
    es: 'Curso duplicado correctamente',
    en: 'Course duplicated successfully',
    cat: 'Curs duplicat amb èxit',
    eus: 'Kursua ondo bikoiztu da',
    pt: 'Curso duplicado com sucesso'
  },
  QUESTION: {
    es: 'Pregunta',
    en: 'Question',
    cat: 'Pregunta',
    eus: 'Galdera',
    pt: 'Pergunta'
  },
  ACTIVITY_TASK_TITLE: {
    es: 'Título',
    en: 'Title',
    cat: 'Títol',
    eus: 'Izenburua',
    pt: 'Título'
  },
  ACTIVITY_TASK_DESCRIPTION: {
    es: 'Descripción',
    en: 'Description',
    cat: 'Descripció',
    eus: 'Deskribapena',
    pt: 'Descrição'
  },
  ACTIVITY_TASK_SIMILAR_TO_ACTIVITY: {
    es: 'Coincidir con el de la actividad',
    en: 'Match with the activity',
    cat: "Coincidir amb el de l'activitat",
    eus: 'Jarduera batekin bat egiten du',
    pt: 'Coincidir com a atividade'
  },
  ACTIVITY_CREATE_TASK: {
    es: 'Crear tarea asociada',
    en: 'Create associated task',
    cat: 'Crear tasca associada',
    eus: 'Sortu lotutako lan',
    pt: 'Criar tarefa associada'
  },
  ACTIVITY_TASK: {
    es: 'Tarea asociada',
    en: 'Associated task',
    cat: 'Tasca associada',
    eus: 'Lotutako lan',
    pt: 'Tarefa associada'
  },
  ACTIVITY_TASK_STATUS_INFO: {
    es: 'El estado solo se puede modificar desde el panel de tareas.',
    en: 'The status can only be modified from the tasks panel.',
    cat: "L'estat només es pot modificar des del panell de tasques.",
    eus: 'Egoera soilik lanen panelatik aldatu daiteke.',
    pt: 'O estado só pode ser modificado a partir do painel de tarefas.'
  },
  ACTIVITY_REMOVE_TASK: {
    es: 'Eliminar tarea',
    en: 'Remove task',
    cat: 'Eliminar tasca',
    eus: 'Kendu lan',
    pt: 'Remover tarefa'
  },
  ARE_YOU_SURE_TO_DELETE_TASK: {
    es: '¿Estás seguro de que quieres eliminar esta tarea?',
    en: 'Are you sure you want to delete this task?',
    cat: 'Segur que vols eliminar aquesta tasca?',
    eus: 'Ziur zaude lan hau ezabatu nahi duzula?',
    pt: 'Tem certeza que deseja eliminar esta tarefa?'
  },
  COMPLEMENTARY_MATERIAL_TOOLTIP: {
    es: 'Esta sección es para clases en directo, exámenes... de la formación. Si no tiene contenido no le aparece al alumno',
    en: 'This section is for live classes, exams... of the training. If it does not have content it does not appear to the student',
    cat: "Aquesta secció és per a classes en directe, exàmens... de la formació. Si no té contingut no li apareix a l'alumne",
    eus: 'Sekzio hau formazioko klase birtualak, azterketak... daude. Edukiarik ez badu, ikasleak ez du ikusten',
    pt: 'Esta seção é para aulas ao vivo, provas... da formação. Se não tiver conteúdo, não aparece para o aluno'
  },
  COMPLEMENTARY_MATERIAL_TITLE: {
    es: '*Nombre de esta pestaña para este curso',
    en: '*Name of this tab for this course',
    cat: '*Nom d’aquesta pestanya per a aquest curs',
    eus: '*Izenburua hauetarako fitxan',
    pt: '*Nome desta aba para este curso'
  },
  ADD_MATERIAL: {
    es: 'Añadir archivo',
    en: 'Add file',
    cat: 'Afegir arxiu',
    eus: 'Gehitu fitxategia',
    pt: 'Adicionar arquivo'
  },
  CANCEL: {
    es: 'Cancelar',
    en: 'Cancel',
    cat: 'Cancel·lar',
    eus: 'Utzi',
    pt: 'Cancelar'
  },
  ACTIVITY_TASK_CREATED_BY: {
    es: 'Creada el: {{date}} por {{name}}',
    en: 'Created on: {{date}} by {{name}}',
    cat: 'Creada el: {{date}} per {{name}}',
    eus: 'Sortua: {{date}}-n {{name}}-en bidez',
    pt: 'Criada em: {{date}} por {{name}}'
  },
  ACTIVITY_TASK_MANDATORY_INFO: {
    es: '*Es obligatorio seleccionar el tipo de actividad',
    en: '*It is mandatory to select the activity type',
    cat: "*És obligatori seleccionar el tipus d'activitat",
    eus: '*Jarduera mota bat hautatzea beharrezkoa da',
    pt: '*É obrigatório selecionar o tipo de atividade'
  },
  ACTIVITY_RESOURCE_REQUIRED: {
    es: 'Es necesario subir un archivo',
    en: 'It is necessary to upload a file',
    cat: 'És necessari pujar un arxiu',
    eus: 'Fitxategi bat igo behar da',
    pt: 'É necessário enviar um arquivo'
  },
  DELETE_FILE: {
    es: 'Eliminar archivo',
    en: 'Delete file',
    cat: 'Eliminar arxiu',
    eus: 'Ezabatu fitxategia',
    pt: 'Eliminar arquivo'
  },
  ACCEPTED_FILE_FORMATS: {
    es: 'Tipos de archivos admitidos: .MOV, .MP4 y .PDF',
    en: 'Accepted file formats: .MOV, .MP4 and .PDF',
    cat: "Formats d'arxiu admesos: .MOV, .MP4 i .PDF",
    eus: 'Onartutako fitxategi-formatuak: .MOV, .MP4 eta .PDF',
    pt: 'Formatos de arquivo aceitos: .MOV, .MP4 e .PDF'
  },
  DEADLINE_DATE: {
    es: 'Fecha máxima de entrega:',
    en: 'Deadline date:',
    cat: 'Data màxima d’entrega:',
    eus: 'Entregako epea:',
    pt: 'Data máxima de entrega:'
  },
  ASSIGNED_TO: {
    es: 'Asignada a:',
    en: 'Assigned to:',
    cat: 'Assignada a:',
    eus: 'Asignatua:',
    pt: 'Atribuída a:'
  },
  TASK_HISTORY: {
    es: 'Historial de la tarea',
    en: 'Task history',
    cat: 'Historial de la tasca',
    eus: 'Lanaren historia',
    pt: 'Histórico da tarefa'
  },
  EDIT_CONTENT: {
    es: 'Editar contenido',
    en: 'Edit content',
    cat: 'Editar contingut',
    eus: 'Editatu edukia',
    pt: 'Editar conteúdo'
  },
  STATUS_HISTORY: {
    es: 'Historial de estados',
    en: 'Status history',
    cat: 'Historial d’estats',
    eus: 'Egoeren historia',
    pt: 'Histórico de estados'
  },
  STATUS: {
    es: 'Estado',
    en: 'Status',
    cat: 'Estat',
    eus: 'Egoera',
    pt: 'Estado'
  },
  DATE: {
    es: 'Fecha',
    en: 'Date',
    cat: 'Data',
    eus: 'Data',
    pt: 'Data'
  },
  CHANGED_BY: {
    es: 'Cambio realizado por:',
    en: 'Change made by:',
    cat: 'Canvi realitzat per:',
    eus: 'Aldaketa egina:',
    pt: 'Alteração feita por:'
  },
  CHECK_CORRECT: {
    es: 'Marca los checks que estén correctos.',
    en: 'Check the checkboxes that are correct.',
    cat: 'Marca els checks que estiguin correctes.',
    eus: 'Markatu zuzenak diren checkak.',
    pt: 'Marque os checks que estão corretos.'
  },
  SELECT_ALL: {
    es: 'Seleccionar todo',
    en: 'Select all',
    cat: 'Seleccionar tot',
    eus: 'Hautatu dena',
    pt: 'Selecionar tudo'
  },
  TECHNICAL_REQUIREMENTS: {
    es: 'Requisitos técnicos',
    en: 'Technical requirements',
    cat: 'Requisits tècnics',
    eus: 'Baldintza teknikoak',
    pt: 'Requisitos técnicos'
  },
  IMAGE_SOUND_QUALITY: {
    es: 'Calidad de imagen y sonido',
    en: 'Image and sound quality',
    cat: "Qualitat d'imatge i so",
    eus: 'Irudi eta soinuaren kalitatea',
    pt: 'Qualidade de imagem e som'
  },
  SCENE_TRANSITION: {
    es: 'Buena transición en escenas',
    en: 'Good transition in scenes',
    cat: 'Bona transició en escenes',
    eus: 'Trantsizio ona eszenetan',
    pt: 'Transição de cena'
  },
  UCADEMY_INTRO: {
    es: 'Entradilla de Ucademy',
    en: 'Ucademy intro',
    cat: 'Entradeta de Ucademy',
    eus: 'Ucademy sarrera',
    pt: 'Introdução da Ucademy'
  },
  SHORT_AND_CLEAR: {
    es: 'Corto y claro',
    en: 'Short and clear',
    cat: 'Brevet i clar',
    eus: 'Labur eta argia',
    pt: 'Curto e claro'
  },
  CLASS_STRUCTURE: {
    es: 'Estructura de la clase',
    en: 'Class structure',
    cat: 'Estructura de la classe',
    eus: 'Klasearen egitura',
    pt: 'Estrutura da aula'
  },
  GOOD_PRESENTATION: {
    es: 'Buena presentación',
    en: 'Good presentation',
    cat: 'Bona presentació',
    eus: 'Aurkezpen ona',
    pt: 'Apresentação de classe'
  },
  PRACTICAL_EXAMPLES: {
    es: 'Ejemplos prácticos',
    en: 'Practical examples',
    cat: 'Exemples pràctics',
    eus: 'Adibide praktikoak',
    pt: 'Exemplos práticos'
  },
  VISUAL_RESOURCES: {
    es: 'Recursos visuales',
    en: 'Visual resources',
    cat: 'Recursos visuals',
    eus: 'Baliabide ikusgarriak',
    pt: 'Recursos visuais'
  },
  TEACHER_PERFORMANCE: {
    es: 'Desempeño del profesor',
    en: 'Teacher performance',
    cat: 'Rendiment del professor',
    eus: 'Irakaslearen jarduna',
    pt: 'Desempenho do professor'
  },
  INTRODUCTION: {
    es: 'Introducción',
    en: 'Introduction',
    cat: 'Introducció',
    eus: 'Sarrera',
    pt: 'Introdução'
  },
  VOICE_TONE: {
    es: 'Tono de voz',
    en: 'Voice tone',
    cat: 'Tò de veu',
    eus: 'Ahots tonua',
    pt: 'Tom de voz'
  },
  DOES_NOT_READ: {
    es: 'No lee',
    en: 'Does not read',
    cat: 'No llegeix',
    eus: 'Ez irakurri',
    pt: 'Não lê'
  },
  USES_TABLET: {
    es: 'Utiliza tablet',
    en: 'Uses tablet',
    cat: 'Utilitza tauleta',
    eus: 'Tableta erabiltzen du',
    pt: 'Utiliza tablet'
  },
  GOOD_POSTURE: {
    es: 'Buena postura frente a la cámara',
    en: 'Good posture in front of the camera',
    cat: 'Bona postura davant la càmera',
    eus: 'Postura ona kameraren aurrean',
    pt: 'Postura de voz'
  },
  RHETORICAL_QUESTIONS: {
    es: 'Preguntas retóricas',
    en: 'Rhetorical questions',
    cat: 'Preguntes retòriques',
    eus: 'Galdera retorikoak',
    pt: 'Perguntas retóricas'
  },
  ADD_COMMENTS: {
    es: 'Añadir comentarios',
    en: 'Add comments',
    cat: 'Afegir comentaris',
    eus: 'Gehitu iruzkinak',
    pt: 'Adicionar comentários'
  },
  CHANGE_STATUS: {
    es: 'Cambiar estado',
    en: 'Change status',
    cat: 'Canviar estat',
    eus: 'Aldatu egoera',
    pt: 'Alterar estado'
  },
  APPROVE: {
    es: 'Aprobar',
    en: 'Approve',
    cat: 'Aprovar',
    eus: 'Onartu',
    pt: 'Aprovar'
  },
  REJECT: {
    es: 'Rechazar',
    en: 'Reject',
    cat: 'Rebutjar',
    eus: 'Ukatu',
    pt: 'Rejeitar'
  },
  REJECT_TOOLTIP: {
    es: 'Se le enviará un correo al profesor con tu feedback',
    en: 'A mail will be sent to the teacher with your feedback',
    cat: 'Se li enviarà un correu al professor amb el teu feedback',
    eus: 'Mezu bat bidaliko zaio irakasleari zure feedbackarekin',
    pt: 'Um e-mail será enviado para o professor com o seu feedback'
  },
  ARE_YOU_SURE_TO_REJECT_TASK: {
    es: '¿Estás seguro de que quieres rechazar esta tarea?',
    en: 'Are you sure you want to reject this task?',
    cat: 'Segur que vols rebutjar aquesta tasca?',
    eus: 'Ziur zaude lan hau ukatu nahi duzula?',
    pt: 'Tem certeza que deseja rejeitar esta tarefa?'
  },
  ARE_YOU_SURE_TO_APPROVE_TASK: {
    es: '¿Estás seguro de que quieres aprobar esta tarea?',
    en: 'Are you sure you want to approve this task?',
    cat: 'Segur que vols aprovar aquesta tasca?',
    eus: 'Ziur zaude lan hau onartu nahi duzula?',
    pt: 'Tem certeza que deseja aprovar esta tarefa?'
  },
  SELECT_EMOJI: {
    es: 'Selecciona un emoji',
    en: 'Select an emoji',
    cat: 'Selecciona un emoji',
    eus: 'Aukeratu emoji bat',
    pt: 'Selecionar um emoji'
  },
  SECTIONS_REVIEW: {
    es: 'Revisión de secciones',
    en: 'Sections review',
    cat: 'Revisió de seccions',
    eus: 'Sekzioen berrikusketa',
    pt: 'Revisão de seções'
  },
  DISABLED: {
    es: 'Desactivada',
    en: 'Disabled',
    cat: 'Desactivada',
    eus: 'Desgaitua',
    pt: 'Desativada'
  },
  OPTIONAL: {
    es: 'Opcional',
    en: 'Optional',
    cat: 'Opcional',
    eus: 'Aukerakoa',
    pt: 'Opcional'
  },
  MANDATORY: {
    es: 'Obligatoria',
    en: 'Mandatory',
    cat: 'Obligatòria',
    eus: 'Beharrezkoa',
    pt: 'Obrigatória'
  },
  VIEW_DAY: {
    es: 'Día',
    en: 'Day',
    cat: 'Dia',
    eus: 'Eguna',
    pt: 'Dia'
  },
  VIEW_WEEK: {
    es: 'Semana',
    en: 'Week',
    cat: 'Setmana',
    eus: 'Astea',
    pt: 'Semana'
  },
  VIEW_MONTH: {
    es: 'Mes',
    en: 'Month',
    cat: 'Mes',
    eus: 'Hilabetea',
    pt: 'Mês'
  },
  VIEW_YEAR: {
    es: 'Año',
    en: 'Year',
    cat: 'Any',
    eus: 'Urtea',
    pt: 'Ano'
  },
  GANTT_TABLE_NAME: {
    es: 'Nombre',
    en: 'Name',
    cat: 'Nom',
    eus: 'Izena',
    pt: 'Nome'
  },
  GANTT_TABLE_FROM: {
    es: 'Desde',
    en: 'From',
    cat: 'Des de',
    eus: 'Eguneratuta',
    pt: 'De'
  },
  GANTT_TABLE_TO: {
    es: 'Hasta',
    en: 'To',
    cat: 'Fins a',
    eus: 'Eguneratuta',
    pt: 'Até'
  },
  GANTT_TABLE_ASSIGNED_TO: {
    es: 'Asignado a',
    en: 'Assigned to',
    cat: 'Assignat a',
    eus: 'Asignatua',
    pt: 'Atribuído a'
  },
  GANTT_TABLE_PROGRESS: {
    es: 'Progreso',
    en: 'Progress',
    cat: 'Progrés',
    eus: 'Aurrerapena',
    pt: 'Progresso'
  },
  [`${EQuizStatus.IN_REVIEW}_BADGE`]: {
    es: 'En revisión',
    en: 'In review',
    cat: 'En revisió',
    eus: 'Berrikusten',
    pt: 'Em revisão'
  },
  [`${EQuizStatus.REVIEWED}_BADGE`]: {
    es: 'Revisado',
    en: 'Reviewed',
    cat: 'Revisat',
    eus: 'Berrikusita',
    pt: 'Revisado'
  },
  SECTION: {
    es: 'Sección',
    en: 'Section',
    cat: 'Secció',
    eus: 'Sekzioa',
    pt: 'Seção'
  },
  PLANIFICABLE: {
    es: 'Curso planificable',
    en: 'Planable course',
    cat: 'Curso planificable',
    eus: 'Planagarria den ikastaroa',
    pt: 'Curso planificável'
  },
  PLANIFICABLE_TOOLTIP: {
    es: 'Activar si el curso forma parte del núcleo de la formación del alumno. De esta forma se tendrá en cuenta para su planificación del estudio.',
    en: "Activate if the course is part of the student's core training. This way it will be taken into account for the student's study planning.",
    cat: "Activar si el curs forma part del nucli de la formació de l'alumnat. D'aquesta manera es tindrà en compte per a la planificació del seu estudi.",
    eus: 'Ikastaroa ikastarren nukleo-formazio-partziala da. Hori dela eta, ikastaroa ikastarren planifikazioan kontuan hartuko da.',
    pt: 'Ativar se o curso faz parte do núcleo de formação do aluno. Desta forma, será considerado para a sua planificação de estudos.'
  },
  PLANIFICABLE_ACTIVATED: {
    es: 'Activado',
    en: 'Activated',
    cat: 'Activat',
    eus: 'Aktibatua',
    pt: 'Ativado'
  },
  PLANIFICABLE_INACTIVATED: {
    es: 'Inactivo',
    en: 'Inactivated',
    cat: 'Inactivat',
    eus: 'Desaktibatua',
    pt: 'Desativado'
  },
  GENERAL_INFO: {
    es: 'Información general',
    en: 'General information',
    cat: 'Informació general',
    eus: 'Orokorra informazioa',
    pt: 'Informação geral'
  },
  TEACHERS: {
    es: 'Profesores',
    en: 'Teachers',
    cat: 'Professors',
    eus: 'Irakasleak',
    pt: 'Professores'
  },
  TAGS: {
    es: 'Formaciones (tags)',
    en: 'Trainings (tags)',
    cat: 'Formacions (tags)',
    eus: 'Ikastarok (tagak)',
    pt: 'Formações (tags)'
  },
  COURSE_EMOJI: {
    es: 'Emoji del curso',
    en: 'Course emoji',
    cat: 'Emoji del curs',
    eus: 'Ikastaroko emojia',
    pt: 'Emoji do curso'
  },
  TASK_PROGRESS: {
    es: 'Progreso en tareas de profesores',
    en: 'Task progress of teachers',
    cat: 'Progrés en tasques de professors',
    eus: 'Ikaslearen lana',
    pt: 'Progresso em tarefas de professores'
  },
  COURSE_STUDENTS: {
    es: 'Alumnos en el curso',
    en: 'Students in the course',
    cat: 'Alumnes en el curs',
    eus: 'Ikastaroko ikastarok',
    pt: 'Alunos no curso'
  },
  CSAT: {
    es: 'CSAT',
    en: 'CSAT',
    cat: 'CSAT',
    eus: 'CSAT',
    pt: 'CSAT'
  },
  COURSE_QUIZ_AVG_SCORE: {
    es: 'Nota media en tests del curso',
    en: 'Average score in course quizzes',
    cat: 'Nota mitjana en tests del curs',
    eus: 'Kursu testen batez besteko nota',
    pt: 'Média de testes no curso'
  },
  TOTAL_TICKETS: {
    es: 'Dudas totales en el curso',
    en: 'Total tickets in the course',
    cat: 'Dudas totals en el curs',
    eus: 'Kursu erroreak guztira',
    pt: 'Dúvidas totais no curso'
  },
  ACTIONS: {
    es: 'Acciones',
    en: 'Actions',
    cat: 'Accions',
    eus: 'Ekintzak',
    pt: 'Ações'
  },
  EDIT_INFORMATION: {
    es: 'Editar información',
    en: 'Edit information',
    cat: 'Editar informació',
    eus: 'Aldatu informazioa',
    pt: 'Editar informação'
  },
  DELETE_COURSE: {
    es: 'Borrar curso',
    en: 'Delete course',
    cat: 'Borrar curs',
    eus: 'Ezabatu ikastaroa',
    pt: 'Apagar curso'
  },
  DELETE_COURSE_CONFIRMATION: {
    es: '¿Estás seguro de que quieres borrar este curso?',
    en: 'Are you sure you want to delete this course?',
    cat: 'Segur que vols esborrar aquest curs?',
    eus: 'Ziur zaude ikastaroa ezabatu nahi duzula?',
    pt: 'Tem certeza que deseja apagar este curso?'
  },
  DUPLICATE_COURSE: {
    es: 'Duplicar curso',
    en: 'Duplicate course',
    cat: 'Duplicar curs',
    eus: 'Kopia ikastaroa',
    pt: 'Cópia do curso'
  },
  ARCHIVE_COURSE: {
    es: 'Archivar curso',
    en: 'Archive course',
    cat: 'Arxivar curs',
    eus: 'Ikastaroa gordetu',
    pt: 'Arquivar curso'
  },
  ACTIVITY_STATS: {
    es: 'Estadísticas de actividades',
    en: 'Activity stats',
    cat: "Estadístiques d'activitat",
    eus: 'Ikastaroko jardun',
    pt: 'Estatísticas de atividades'
  },
  UNARCHIVE_COURSE: {
    es: 'Desarchivar curso',
    en: 'Unarchive course',
    cat: 'Desarxivar curs',
    eus: 'Ikastaroa desgordetu',
    pt: 'Desarquivar curso'
  },
  MANAGE_DRIP_CONTENT: {
    es: 'Configurar drip content',
    en: 'Manage drip content',
    cat: 'Configurar drip content',
    eus: 'Drip content kudeatu',
    pt: 'Configurar drip content'
  },
  COMING_SOON: {
    es: 'Próximamente',
    en: 'Coming soon',
    cat: 'Próximament',
    eus: 'Jarraitu',
    pt: 'Em breve'
  },
  QUIZZES_TOTAL_COUNT: {
    es: '{{total}} tests',
    en: '{{total}} tests',
    cat: '{{total}} tests',
    eus: '{{total}} test',
    pt: '{{total}} testes'
  },
  MODE: {
    es: 'Tipo',
    en: 'Type',
    cat: 'Tipus',
    eus: 'Mota',
    pt: 'Tipo'
  },
  NUMBER_OF_QUESTIONS: {
    es: 'Nº de preguntas',
    en: 'No. of questions',
    cat: 'Nº de preguntes',
    eus: 'Galdera kopurua',
    pt: 'Número de questões'
  },
  CREATOR: {
    es: 'Creador',
    en: 'Creator',
    cat: 'Creador',
    eus: 'Sortzailea',
    pt: 'Criador'
  },
  COMPLETED_QUIZZES: {
    es: 'Veces hecho',
    en: 'Times done',
    cat: 'Veces hecho',
    eus: 'Egin daitezen aldiz',
    pt: 'Vezes feito'
  },
  AVERAGE_SCORE: {
    es: 'Nota media',
    en: 'Average score',
    cat: 'Nota mitjana',
    eus: 'Batez bestekoa',
    pt: 'Média'
  },
  DIFFICULTY: {
    es: 'Dificultad',
    en: 'Difficulty',
    cat: 'Dificultat',
    eus: 'Zailtasuna',
    pt: 'Dificuldade'
  },
  VIEW_MORE: {
    es: 'ver más',
    en: 'view more',
    cat: 'veure més',
    eus: 'ikusi gehiago',
    pt: 'ver mais'
  },
  ADD_DESCRIPTION: {
    es: 'Añadir descripción',
    en: 'Add description',
    cat: 'Añadir descripció',
    eus: 'Ezabatu deskribapena',
    pt: 'Adicionar descrição'
  },
  VIEW_LESS: {
    es: 'ver menos',
    en: 'view less',
    cat: 'veure menys',
    eus: 'ikusi gutxiago',
    pt: 'ver menos'
  },
  PLANIFICABLE_ACTIVITY: {
    es: 'Planificable',
    en: 'Planificable',
    cat: 'Planificable',
    eus: 'Planagarria',
    pt: 'Planificável'
  },
  NOT_PLANIFICABLE_ACTIVITY: {
    es: 'No planificable',
    en: 'Not planificable',
    cat: 'No planificable',
    eus: 'Ez planagarria',
    pt: 'Não planificável'
  },
  HIDDEN_SECTION_TOOLTIP: {
    es: 'Sección oculta',
    en: 'Hidden section',
    cat: 'Secció oculta',
    eus: 'Ezkutatutako sekzioa',
    pt: 'Seção oculta'
  },
  VISIBLE_SECTION_TOOLTIP: {
    es: 'Sección visible',
    en: 'Visible section',
    cat: 'Secció visible',
    eus: 'Ikusten den sekzioa',
    pt: 'Seção visível'
  },
  CREATED_BY: {
    es: 'Creador',
    en: 'Creator',
    cat: 'Creador',
    eus: 'Sortzailea',
    pt: 'Criador'
  }
};
